import {Namespace} from 'i18next';
import {useTranslation} from 'react-i18next';

import {TFunction} from './t-function';

interface UseOurTranslationProps<T extends Namespace> {
    t: TFunction<T>;
    tCommon: TFunction<'common'>;
}

export function useOurTranslation<T extends Namespace>(namespace: T): UseOurTranslationProps<T> {
    const [t] = useTranslation(namespace);
    const [tCommon] = useTranslation('common');

    return {t, tCommon};
}
