import React, {FC, memo, ReactNode, useCallback} from 'react';

import {useOurTranslation} from 'app/translations';
import {Button, Visuals} from 'common/buttons';
import {Modal} from 'common/layout';

import {ExportUserMeasurementsType} from '../../types/export-user-measurements-type';

const renderButton = (onClick: () => void, label: string, importace: Visuals, testId: string): ReactNode => (
    <Button
        onClick={onClick}
        visuals={importace}
        testId={testId}
    >
        {label}
    </Button>
);

interface Props {
    exportMeasurements(exportType: ExportUserMeasurementsType): void;
    closeModal(): void;
}

const MeasuredAnimalsModalBase: FC<Props> = props => {
    const {exportMeasurements, closeModal} = props;

    const {t} = useOurTranslation('planning/MeasuredAnimalsModal');

    const exportMeasurementsNormal = useCallback(() => {
        exportMeasurements(ExportUserMeasurementsType.MEASURED);
        closeModal();
    }, [closeModal, exportMeasurements]);

    const exportMeasurementsRaw = useCallback(() => {
        exportMeasurements(ExportUserMeasurementsType.RAW);
        closeModal();
    }, [closeModal, exportMeasurements]);

    return (
        <Modal
            modalId="measuredAnimals"
            title={t('chooseDataType')}
            footer={null}
            onCancel={closeModal}
            testId="measuredAnimalsModal"
        >
            <div className="row">
                <div className="col d-flex justify-content-center group">
                    {renderButton(exportMeasurementsNormal, t('processedData'), 'primary', 'processedData')}
                    {renderButton(exportMeasurementsRaw, t('rawData'), 'default', 'rawData')}
                </div>
            </div>
        </Modal>
    );
};

export const MeasuredAnimalsModal = memo(MeasuredAnimalsModalBase);
