import {showSuccess, showBeError, putAll, takeEveryF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction, simpleLayoutSelector} from 'common/layout';

import {importKkutPath} from '../../routes';
import {importKkutAction, ImportKkutFileAction} from '../action';

function* execute({payload: {file}}: ImportKkutFileAction): SagaIterator {
    const title = t('importKkut/messages')('title');

    yield* put(layoutAction.setItemLoading('importKkut', true));

    const response = yield* call(Api.importKkut, file);

    if (!response.isSuccess) {
        yield putAll(showBeError(response, title, {includeFieldErrors: true}));
    } else {
        yield* put(importKkutAction.getImports());
        yield* put(showSuccess(title, t('importKkut/messages')('successMessage'), false, 'import-kkut'));

        const importPageDisplayed = (yield* select(simpleLayoutSelector.location))?.pathname === importKkutPath;

        if (importPageDisplayed) {
            yield* put(importKkutAction.importKkutFileSuccess(response.data));
            yield* put(layoutAction.setModalVisibility('importKkutResult', true));
        }
    }
    yield* put(layoutAction.setItemLoading('importKkut', false));
}

export function* importKkutFileSaga(): SagaIterator {
    yield takeEveryF('importKkut/IMPORT_KKUT_FILE', execute);
}
