import {tCommon} from 'app/translations';
import {RatingValidator} from 'app/utils/rating-validator';
import {Errors} from 'utils/validator';

import {SelectBreedingFormValues} from '../../types/select-breeding-form-values';

export const validate = (values: SelectBreedingFormValues): Errors<SelectBreedingFormValues> => {
    const validator = new RatingValidator(values);

    validator.nonEmpty('stableId', tCommon('stable'));
    validator.nonEmpty('breedingId', tCommon('breeding'));

    return validator.generateErrorsObject();
};
