import {
    putAll,
    showBeError,
    showError,
    showSuccess,
    extractFormErrorsFromResponse,
    takeLatestF,
} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout/model';
import {formHelpers} from 'utils/forms';

import {SendPlanStableEmailAction} from '../action';
import {simplePlanningSelector} from '../selector';

const te = t('planning/messages');

function* execute({payload: {data}}: SendPlanStableEmailAction): SagaIterator {
    const {content, emailsTo, emailsToSendCopyTo} = data;
    const stableId = opt(yield* select(simplePlanningSelector.planEmailModalStableId))
        .orCrash('missing stable id');

    yield* put(layoutAction.setItemLoading('sendPlanStableEmail', true));

    const title = te('stablePlanSendTitle');
    const response = yield* call(Api.sendPlanEmailForStable, {content, emailsTo, emailsToSendCopyTo}, {stableId});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit('planEmail', extractFormErrorsFromResponse(response)));
        yield* put(showError(title, te('stablePlanSendError')));
    } else {
        yield* put(showSuccess(title, te('stablePlanSendSuccess')));
        yield* put(layoutAction.setModalVisibility('planStableEmail', false));
        yield* put(formHelpers.reset('planEmail'));
    }

    yield* put(layoutAction.setItemLoading('sendPlanStableEmail', false));
}

export function* sendPlanStableEmailSaga(): SagaIterator {
    yield takeLatestF('planning/SEND_PLAN_STABLE_EMAIL', execute);
}
