const formatDifference = (difference: number): string => {
    if (difference < 0) {
        return String(difference);
    } else if (difference === 0) {
        return '=';
    } else {
        return `+${difference}`;
    }
};

export const formatResult = (adjustedValue: number | null, calculatedValue: number | null): string => {
    if (adjustedValue === null) {
        return '';
    }

    const difference = calculatedValue ? adjustedValue - calculatedValue : 0;

    return `${adjustedValue} (${formatDifference(difference)})`;
};
