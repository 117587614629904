import {Nullable, toggleItem, usePrevious} from '@fl/cmsch-fe-library';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Plan} from 'api/gen/Plan';
import {breederAction} from 'app/breeder';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {planningAction, simplePlanningSelector} from '../model';
import {PlanEmailFormValues, planEmailFormName} from '../types/plan-email-form-values';
import {getStablesIds} from '../utils';

interface UseGetDataForPlan {
    visibleStables: Array<number>;
    plan: Nullable<Plan>;
    selectedAnimals: Array<number>;
    deletePlan(): void;
    toggleSelectAnimal(animalId: number, breederId: number, animalsInCompany: Array<number>): void;
    closePlanStableEmailModal(): void;
    closePlanBreederEmailModal(): void;
    sendPlanStableEmail(data: PlanEmailFormValues): void;
    sendPlanBreederEmail(data: PlanEmailFormValues): void;
    printPlan(): void;
    removeBreederFromPlan(animalIds: Array<number>): void;
    printBreederPlan(breederId: number): void;
    printBreederStablePlan(stableId: number): void;
    getStablesAlternativeEmails(stableIds: Array<number>): void;
    setPlanEmailFormValues(values: PlanEmailFormValues): void;
    onOpenPlanStableEmailModal(stableId: number): void;
    onOpenPlanBreederEmailModal(breederId: number): void;
    removeSelectedAnimalsFromPlan(): void;
    setSelectedAnimalsAsDead(): void;
    onCollapseAll(): void;
    onUncollapseAll(): void;
    onToggleCollapse(id: number): void;
}

// eslint-disable-next-line max-lines-per-function
export const useGetDataForPlan = (): UseGetDataForPlan => {
    const plan = useSelector(simplePlanningSelector.plan);
    const selectedAnimals = useSelector(simplePlanningSelector.selectedAnimals);

    const dispatch = useDispatch();

    const [visibleStables, setVisibleStables] = useState<Array<number>>([]);

    const deletePlan = useCallback(() => {
        dispatch(planningAction.deletePlan());
    }, [dispatch]);

    const toggleSelectAnimal = useCallback((animalId: number, breederId: number, animalsInCompany: Array<number>) => {
        dispatch(planningAction.toggleSelectAnimal(animalId, breederId, animalsInCompany));
    }, [dispatch]);

    const closePlanStableEmailModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('planStableEmail', false));
        dispatch(formHelpers.reset('planEmail'));
    }, [dispatch]);

    const closePlanBreederEmailModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('planBreederEmail', false));
        dispatch(formHelpers.reset('planEmail'));
    }, [dispatch]);

    const sendPlanStableEmail = useCallback((data: PlanEmailFormValues) => {
        dispatch(planningAction.sendPlanStableEmail(data));
    }, [dispatch]);

    const sendPlanBreederEmail = useCallback((data: PlanEmailFormValues) => {
        dispatch(planningAction.sendPlanBreederEmail(data));
    }, [dispatch]);

    const printPlan = useCallback(() => {
        dispatch(planningAction.printPlan());
    }, [dispatch]);

    const removeBreederFromPlan = useCallback((animalIds: Array<number>) => {
        dispatch(planningAction.removeBreederFromPlan(animalIds));
        dispatch(planningAction.resetSelectedAnimalsAndCompanies());
    }, [dispatch]);

    const printBreederPlan = useCallback((breederId: number) => {
        dispatch(planningAction.printBreederPlan(breederId));
    }, [dispatch]);

    const printBreederStablePlan = useCallback((stableId: number) => {
        dispatch(planningAction.printBreederStablePlan(stableId));
    }, [dispatch]);

    const getStablesAlternativeEmails = useCallback((stableIds: Array<number>) => {
        dispatch(breederAction.getStablesAlternativeEmails(stableIds));
    }, [dispatch]);

    const setPlanEmailFormValues = useCallback((values: PlanEmailFormValues) => {
        dispatch(formHelpers.initialize(planEmailFormName, values));
    }, [dispatch]);

    const onOpenPlanStableEmailModal = useCallback((stableId: number) => {
        dispatch(planningAction.setPlanEmailModalStableId(stableId));
        dispatch(layoutAction.setModalVisibility('planStableEmail', true));
    }, [dispatch]);

    const onOpenPlanBreederEmailModal = useCallback((breederId: number) => {
        dispatch(planningAction.setPlanEmailModalBreederId(breederId));
        dispatch(layoutAction.setModalVisibility('planBreederEmail', true));
    }, [dispatch]);

    const removeSelectedAnimalsFromPlan = useCallback(() => {
        dispatch(planningAction.removeAnimalsFromPlan(selectedAnimals));
        dispatch(planningAction.resetSelectedAnimalsAndCompanies());
    }, [dispatch, selectedAnimals]);

    const setSelectedAnimalsAsDead = useCallback(() => {
        dispatch(planningAction.setSelectedAnimalsAsDead(selectedAnimals));
        dispatch(planningAction.resetSelectedAnimalsAndCompanies());
    }, [dispatch, selectedAnimals]);

    const onCollapseAll = useCallback(() => {
        setVisibleStables([]);
    }, []);

    const onUncollapseAll = useCallback(() => {
        setVisibleStables(getStablesIds(plan));
    }, [plan]);

    const onToggleCollapse = useCallback((id: number) => {
        setVisibleStables(toggleItem(id)(visibleStables));
    }, [visibleStables]);

    useEffect(() => {
        dispatch(planningAction.resetPlanScreen());
        dispatch(planningAction.getPlan());
        dispatch(planningAction.getEmailTemplate());
    }, [dispatch]);

    const prevPlan = usePrevious(plan);
    useEffect(() => {
        if (prevPlan !== plan) {
            setVisibleStables(getStablesIds(plan));
        }
    }, [plan, prevPlan, setVisibleStables]);

    return ({
        visibleStables,
        plan,
        selectedAnimals,
        closePlanStableEmailModal,
        closePlanBreederEmailModal,
        deletePlan,
        getStablesAlternativeEmails,
        onCollapseAll,
        onOpenPlanStableEmailModal,
        onOpenPlanBreederEmailModal,
        onToggleCollapse,
        onUncollapseAll,
        printBreederPlan,
        printBreederStablePlan,
        printPlan,
        removeBreederFromPlan,
        removeSelectedAnimalsFromPlan,
        setSelectedAnimalsAsDead,
        sendPlanStableEmail,
        sendPlanBreederEmail,
        setPlanEmailFormValues,
        toggleSelectAnimal,
    });
};
