import * as O from 'optics-ts';
import {opt} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialRepairState, RepairState} from './state';

const repairStateO = O.optic<RepairState>();

export const repairReducer = (state: RepairState = initialRepairState, action: AppAction): RepairState => {
    switch (action.type) {
        case 'repair/GET_ALL_APPROVED_MEASUREMENTS_SUCCESS': {
            const {measurementSearch} = action.payload;

            return O.set(repairStateO.prop('measurementSearch'))(measurementSearch)(state);
        }

        case 'repair/GET_APPROVED_MEASUREMENT_SUCCESS': {
            const {measurement} = action.payload;

            return O.set(repairStateO.prop('measurementDetail'))(measurement)(state);
        }

        case 'repair/FIND_ANIMALS_SUCCESS': {
            const {animals} = action.payload;

            return O.set(repairStateO.prop('foundAnimals'))(opt(animals))(state);
        }

        default:
            return state;
    }
};
