import {Opt} from 'ts-opt';

import {AppInfo} from 'api/gen/AppInfo';
import {MobileApps} from 'api/gen/MobileApps';
import {State} from 'app/setup';

export const simpleAdminSelector = {
    appInfo: ({admin}: State): Opt<AppInfo> => admin.appInfo,
    mobileApps: ({admin}: State): Opt<MobileApps> => admin.mobileApps,
};
