import {tCommon} from 'app/translations';
import {Errors, Validator} from 'utils/validator';

import {PlanEmailFormValues} from '../../types/plan-email-form-values';

export const validate = (values: PlanEmailFormValues): Errors<PlanEmailFormValues> => {
    const validator = new Validator(values);

    validator.nonEmpty('emailsTo', tCommon('email'));
    validator.emailArray('emailsTo');
    validator.emailArray('emailsToSendCopyTo');

    return validator.generateErrorsObject();
};
