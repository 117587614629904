import {Nullable} from '@fl/cmsch-fe-library';
import {replace} from 'lodash/fp';
import {opt} from 'ts-opt';

const defaultPlaceholder = '${note}'; // eslint-disable-line no-template-curly-in-string
const defaultReplaceValue = '<span> </span>';

export const replacePlaceholderInTemplate = (
    template: Nullable<string>,
    placeholder?: string,
    replaceValue?: string,
): Nullable<string> =>
    opt(template).map(replace(placeholder ?? defaultPlaceholder, replaceValue ?? defaultReplaceValue)).orNull();
