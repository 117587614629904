import {earTagWithoutCountryRegexGen} from 'api/gen/EarTagWithoutCountry';
import {FleckviehMeasurementProperties} from 'api/gen/FleckviehMeasurementProperties';
import {positiveIntegerMinimum} from 'api/gen/PositiveInteger';
import {udderPurityRegexGen} from 'api/gen/UdderPurity';
import {tCommon} from 'app/translations';
import {RatingValidator} from 'app/utils/rating-validator';
import {Errors} from 'utils/validator';

import {RepairFleckviehMeasurementFormValues} from './values';

// eslint-disable-next-line max-lines-per-function
const validateProperties = (values: FleckviehMeasurementProperties): Errors<FleckviehMeasurementProperties> => {
    const validator = new RatingValidator(values);

    validator.nonEmpty('adjustedExtremities', tCommon('adjustedExtremities'));
    validator.nonEmpty('adjustedMusculature', tCommon('adjustedMusculature'));
    validator.nonEmpty('adjustedTotal', tCommon('adjustedTotal'));
    validator.nonEmpty('adjustedUdder', tCommon('adjustedUdder'));
    validator.nonEmpty('ankleJoint', tCommon('ankleJoint'));
    validator.nonEmpty('backAngle', tCommon('backAngle'));
    validator.nonEmpty('backHeight', tCommon('backHeight'));
    validator.nonEmpty('backLength', tCommon('backLength'));
    validator.nonEmpty('backWidth', tCommon('backWidth'));
    validator.nonEmpty('bodyDepth', tCommon('bodyDepth'));
    validator.nonEmpty('bodyLength', tCommon('bodyLength'));
    validator.nonEmpty('calculatedExtremities', tCommon('calculatedExtremities'));
    validator.nonEmpty('calculatedFrame', tCommon('calculatedFrame'));
    validator.nonEmpty('calculatedMusculature', tCommon('calculatedMusculature'));
    validator.nonEmpty('calculatedTotal', tCommon('calculatedTotal'));
    validator.nonEmpty('calculatedUdder', tCommon('calculatedUdder'));
    validator.nonEmpty('chestCircumference', tCommon('chestCircumference'));
    validator.nonEmpty('colorType', tCommon('colorType'));
    validator.nonEmpty('edematicUdder', tCommon('edematicUdder'));
    validator.nonEmpty('fitness', tCommon('fitness'));
    validator.nonEmpty('frontExtremitiesOpenPosture', tCommon('frontExtremitiesOpenPosture'));
    validator.nonEmpty('frontTeatsPlacement', tCommon('frontTeatsPlacement'));
    validator.nonEmpty('frontTeatsSideways', tCommon('frontTeatsSideways'));
    validator.nonEmpty('frontUdderAttachmentAngle', tCommon('frontUdderAttachmentAngle'));
    validator.nonEmpty('frontUdderLength', tCommon('frontUdderLength'));
    validator.nonEmpty('funnelTeats', tCommon('funnelTeats'));
    validator.nonEmpty('gradualUdder', tCommon('gradualUdder'));
    validator.nonEmpty('headColor', tCommon('headColor'));
    validator.nonEmpty('hoovesBackHeight', tCommon('hoovesBackHeight'));
    validator.nonEmpty('hoovesTorsion', tCommon('hoovesTorsion'));
    validator.nonEmpty('lastMilkingAt', tCommon('lastMilkingAt'));
    validator.minNumber('lastMilkingAt', tCommon('lastMilkingAt'), positiveIntegerMinimum);
    validator.nonEmpty('looseLoins', tCommon('looseLoins'));
    validator.nonEmpty('looseShoulderBlade', tCommon('looseShoulderBlade'));
    validator.nonEmpty('mottleType', tCommon('mottleType'));
    validator.nonEmpty('musculature', tCommon('musculature'));
    validator.nonEmpty('nervousness', tCommon('nervousness'));
    validator.nonEmpty('openedHooves', tCommon('openedHooves'));
    validator.nonEmpty('pastern', tCommon('pastern'));
    validator.nonEmpty('posture', tCommon('posture'));
    validator.nonEmpty('rearPosture', tCommon('rearPostureFleckvieh'));
    validator.nonEmpty('rearTeatsPlacement', tCommon('rearTeatsPlacement'));
    validator.nonEmpty('rearTeatsPosition', tCommon('rearTeatsPosition'));
    validator.nonEmpty('rearUdderAttachment', tCommon('rearUdderAttachment'));
    validator.nonEmpty('rearUdderLength', tCommon('rearUdderLength'));
    validator.nonEmpty('roofedBack', tCommon('roofedBack'));
    validator.nonEmpty('shortRearTeats', tCommon('shortRearTeats'));
    validator.nonEmpty('suspensoryLigament', tCommon('suspensoryLigament'));
    validator.nonEmpty('teatsForward', tCommon('teatsForward'));
    validator.nonEmpty('teatsLength', tCommon('teatsLength'));
    validator.nonEmpty('teatsWidth', tCommon('teatsWidth'));
    validator.nonEmpty('udderDepth', tCommon('udderDepth'));
    validator.nonEmpty('udderPurity', tCommon('udderPurity'));
    validator.pattern('udderPurity', udderPurityRegexGen(), tCommon('udderPurity'));
    validator.nonEmpty('weakBack', tCommon('weakBack'));

    return validator.generateErrorsObject();
};

export const validate = (
    values: RepairFleckviehMeasurementFormValues,
): Errors<RepairFleckviehMeasurementFormValues> => {
    const validator = new RatingValidator(values);

    validator.nonEmpty('earTag', tCommon('earTag'));
    validator.pattern('earTag', earTagWithoutCountryRegexGen(), tCommon('earTag'));
    validator.nonEmpty('lactation', tCommon('lactation'));
    validator.minNumber('lactation', tCommon('lactation'), positiveIntegerMinimum);
    validator.nonEmpty('ratingDate', tCommon('ratingDate'));

    const errors = validator.generateErrorsObject();

    if (values.properties) {
        errors.properties = validateProperties(values.properties);
    }

    return errors;
};
