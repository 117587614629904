import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {EmailState, initialEmailState} from './state';

const emailStateO = O.optic<EmailState>();

export const emailReducer = (
    state: EmailState = initialEmailState,
    action: AppAction,
): EmailState => {
    switch (action.type) {
        case 'email/GET_SENT_EMAILS_SUCCESS': {
            const {sentEmailsPage} = action.payload;

            return O.set(emailStateO.prop('sentEmailsPage'))(sentEmailsPage)(state);
        }

        default:
            return state;
    }
};
