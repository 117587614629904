import {none, Opt} from 'ts-opt';

import {AnimalWithBreedingSearch} from 'api/gen/AnimalWithBreedingSearch';
import {MeasurementDetail} from 'api/gen/MeasurementDetail';
import {MeasurementSearch} from 'api/gen/MeasurementSearch';

export interface RepairState {
    measurementSearch: Opt<Array<MeasurementSearch>>;
    measurementDetail: Opt<MeasurementDetail>;
    foundAnimals: Opt<Array<AnimalWithBreedingSearch>>;
}

export const initialRepairState: RepairState = {
    measurementSearch: none,
    measurementDetail: none,
    foundAnimals: none,
};
