import * as O from 'optics-ts';
import {none, opt} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialUnknownAnimalState, UnknownAnimalState} from './state';

const unknownAnimalStateO = O.optic<UnknownAnimalState>();

export const unknownAnimalReducer = (
    state: UnknownAnimalState = initialUnknownAnimalState,
    action: AppAction,
): UnknownAnimalState => {
    switch (action.type) {
        case 'unknownAnimals/GET_UNASSIGNED_ANIMALS_RESULT': {
            const {payload: {animals}} = action;

            return O.set(unknownAnimalStateO.prop('animals'))(animals)(state);
        }

        case 'unknownAnimals/CLEAR_UNASSIGNED_ANIMALS': {
            return O.set(unknownAnimalStateO.prop('animals'))(null)(state);
        }

        case 'unknownAnimals/GET_STABLES_WITH_BREEDINGS_RESULT': {
            const {payload: {stables}} = action;

            return O.set(unknownAnimalStateO.prop('stables'))(stables)(state);
        }

        case 'unknownAnimals/SELECT_ANIMAL_TO_ASSIGN': {
            const {payload: {animal}} = action;

            return O.set(unknownAnimalStateO.prop('animalInEdit'))(opt(animal))(state);
        }

        case 'unknownAnimals/RESET_ANIMAL_TO_ASSIGN': {
            return O.set(unknownAnimalStateO.prop('animalInEdit'))(none)(state);
        }

        default:
            return state;
    }
};
