import {none, Opt} from 'ts-opt';

import {BasicBreeding} from 'api/gen/BasicBreeding';
import {BreedingDetails} from 'api/gen/BreedingDetails';
import {PagePlanningAnimal} from 'api/gen/PagePlanningAnimal';
import {PagePlanningBreeding} from 'api/gen/PagePlanningBreeding';

export interface BreedingState {
    breedingPage: Opt<PagePlanningBreeding>;
    breeding: BreedingDetails | null;
    animalsInBreedingPage: Opt<PagePlanningAnimal>;
    breedingNumbers: Array<BasicBreeding> | null;
    selectedAnimalsInBreeding: Array<number>;
    fetchingIds: Array<number>;
}

export const initialBreedingState: BreedingState = {
    breedingPage: none,
    breeding: null,
    animalsInBreedingPage: none,
    breedingNumbers: null,
    selectedAnimalsInBreeding: [],
    fetchingIds: [],
};
