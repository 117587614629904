import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {planningAction, GetAdminPlansAction} from '../action';

function* execute(_: GetAdminPlansAction): SagaIterator {
    const response = yield* call(Api.getPlanStatistics);

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('planning/messages')('adminPlansError')));
    } else {
        yield* put(planningAction.getAdminPlansSuccess(response.data));
    }
}

export function* getAdminPlansSaga(): SagaIterator {
    yield takeLatestF('planning/GET_ADMIN_PLANS', execute);
}
