import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {initialImportKkutState, ImportKkutState} from './state';

const importKkutStateO = O.optic<ImportKkutState>();

export const importKkutReducer = (
    state: ImportKkutState = initialImportKkutState,
    action: AppAction,
): ImportKkutState => {
    switch (action.type) {
        case 'importKkut/IMPORT_KKUT_FILE_SUCCESS': {
            const {payload: {importResult}} = action;

            return O.set(importKkutStateO.prop('importResult'))(importResult)(state);
        }

        case 'importKkut/GET_IMPORTS_SUCCESS': {
            const {payload: {imports}} = action;

            return O.set(importKkutStateO.prop('imports'))(imports)(state);
        }

        case 'importKkut/RESET_IMPORTS': {
            return O.set(importKkutStateO.prop('imports'))(null)(state);
        }

        default:
            return state;
    }
};
