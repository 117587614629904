// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FirstName, FirstNameSchema } from 'api/gen/FirstName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LastName, LastNameSchema } from 'api/gen/LastName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserCode, UserCodeSchema } from 'api/gen/UserCode';

const requiredPart = t.interface({
  approved: t.boolean,
  dead: t.boolean,
  firstName: t.union([FirstNameSchema, t.null]),
  id: PositiveLongSchema,
  lactation: PositiveIntegerSchema,
  lastName: t.union([LastNameSchema, t.null]),
  ratingDate: DateSchema,
  tag: t.string,
  userCode: UserCodeSchema,
});

export const AbstractMeasurementAnimalSchema = excess(requiredPart);

export interface AbstractMeasurementAnimal extends t.TypeOf<typeof AbstractMeasurementAnimalSchema> {}
