import {putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {planningAction, SetSelectedAnimalsAsDeadAction} from '../action';

function* execute({payload: {ids}}: SetSelectedAnimalsAsDeadAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('setAnimalsAsDead', true));

    const response = yield* call(Api.setAnimalsAsDead, {ids});

    if (response.isSuccess) {
        yield* put(showSuccess(t('planning/messages')('updatePlanTitle'),
            t('planning/messages')('setAnimalsAsDeadPlanSuccess')));
        yield* put(planningAction.getPlan());
    } else {
        yield putAll(showBeError(response, t('planning/messages')('updatePlanTitle')));
    }

    yield* put(layoutAction.setItemLoading('setAnimalsAsDead', false));
}

export function* setAnimalsAsDeadSaga(): SagaIterator {
    yield takeLatestF('planning/SET_SELECTED_ANIMALS_AS_DEAD', execute);
}
