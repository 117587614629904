import {ActionSettings, Table, TableRowBackground} from '@fl/cmsch-fe-library';
import {isUndefined} from 'lodash/fp';
import React, {FC, useCallback, useMemo} from 'react';

import {RatingWithUnknownBreeding} from 'api/gen/RatingWithUnknownBreeding';
import {Tenant} from 'api/gen/Tenant';
import {useOurTranslation} from 'app/translations';
import {isDuplicateAnimal} from 'utils/is-duplicate-animal';

import {getColumns} from './columns';

interface Props {
    animals: Array<RatingWithUnknownBreeding>;
    editedRating?: number;
    tenant?: Tenant;
    onAssignAnimalToBreeding(animal: RatingWithUnknownBreeding): void;
}

export const UnassignedAnimalsTable: FC<Props> = props => {
    const {animals, editedRating, tenant, onAssignAnimalToBreeding} = props;
    const {t, tCommon} = useOurTranslation('unknownAnimals');

    const calcRowBackgroundDesign = useCallback(
        (row: RatingWithUnknownBreeding, _index: number): TableRowBackground | undefined =>
            isDuplicateAnimal(row, animals) ? 'dangerous' : undefined
        , [animals]);

    const onEdit = useCallback((_id: number | string, row: RatingWithUnknownBreeding) => {
        onAssignAnimalToBreeding(row);
    }, [onAssignAnimalToBreeding]);

    const actionSettings: ActionSettings<RatingWithUnknownBreeding> = useMemo(() => ({
        extraActions: [
            {
                id: 'assignAnimalToBreeding',
                name: t('assignAnimalToBreeding'),
                icon: 'editOutlined',
                callback: onEdit,
                testId: 'edit-button',
            },
        ],
    }), [onEdit, t]);

    const columns = useMemo(() => getColumns(tCommon), [tCommon]);

    if (isUndefined(tenant)) return null;

    return (
        <Table
            tableId="unknownAnimals"
            columns={columns}
            rows={animals}
            calcRowBackgroundDesign={calcRowBackgroundDesign}
            highlightedRowId={editedRating}
            actionSettings={actionSettings}
            scroll={{x: true}}
            hideOnEmptyData
            rowActionsOnRight
        />
    );
};
