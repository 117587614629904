import {putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {ApproveMeasurementsAction, measurementAction} from '../action';

function* execute(_: ApproveMeasurementsAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('approveMeasurements', true));

    const title = t('measurement/messages')('approveMeasurementsTitle');
    const response = yield* call(Api.approveRatings);

    if (response.isSuccess) {
        yield* put(measurementAction.getMeasurements(opt({action: 'refresh'})));
        yield* put(showSuccess(title, t('measurement/messages')('approveMeasurementsSuccess')));
    } else {
        yield putAll(showBeError(response, title));
    }

    yield* put(layoutAction.setItemLoading('approveMeasurements', false));
}

export function* approveMeasurementsSaga(): SagaIterator {
    yield takeLatestF('measurement/APPROVE_MEASUREMENTS', execute);
}
