import {Nullable} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {RatingWithUnknownBreeding} from 'api/gen/RatingWithUnknownBreeding';
import {StableWithBreedings} from 'api/gen/StableWithBreedings';
import {State} from 'app/setup';
import {formHelpers} from 'utils/forms';

import {SelectBreedingFormValues} from '../types';

export const simpleUnknownAnimalSelector = {
    animalInEdit: ({unknownAnimal}: State): Opt<RatingWithUnknownBreeding> => unknownAnimal.animalInEdit,
    animals: ({unknownAnimal}: State): Nullable<Array<RatingWithUnknownBreeding>> => unknownAnimal.animals,
    stables: ({unknownAnimal}: State): Nullable<Array<StableWithBreedings>> => unknownAnimal.stables,
    selectBreedingValues: (state: State): Nullable<SelectBreedingFormValues> =>
        formHelpers.formValues('selectBreeding')(state).orNull(),
};
