import * as O from 'optics-ts';
import {opt} from 'ts-opt';

import {AdminAction} from './action';
import {AdminState, initialAdminState} from './state';

const adminStateO = O.optic<AdminState>();

export const adminReducer = (state: AdminState = initialAdminState, action: AdminAction): AdminState => {
    switch (action.type) {
        case 'admin/GET_APP_INFO_SUCCESS': {
            const {appInfo} = action.payload;

            return O.set(adminStateO.prop('appInfo'))(opt(appInfo))(state);
        }

        case 'admin/GET_MOBILE_APPS_SUCCESS': {
            const {apps} = action.payload;

            return O.set(adminStateO.prop('mobileApps'))(opt(apps))(state);
        }

        default:
            return state;
    }
};
