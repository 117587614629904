import React, {FC, memo} from 'react';
import {Opt} from 'ts-opt';

import {useOurTranslation} from 'app/translations';
import {PageHeader} from 'common/layout';
import {LoadingItemId} from 'common/layout/types/loading-item-id';

import {MeasurementsActionPanel} from '../measurements-action-panel';
import {MeasurementsTable} from '../measurements-table';

interface Props {
    loadingItems: Array<LoadingItemId>;
    ratingCount: Opt<number>;
    animalsinRatingsCount: Opt<number>;
    deadAnimalsCount: Opt<number>;
    approveRatings(): void;
    deleteRating(ratingId: number): void;
    mortifyAnimal(ratingId: number): void;
    editRating(ratingId: number): void;
}

const MeasurementsSectionBase: FC<Props> = props => {
    const {
        loadingItems,
        ratingCount,
        animalsinRatingsCount,
        deadAnimalsCount,
        approveRatings,
        deleteRating,
        mortifyAnimal,
        editRating,
    } = props;

    const {t} = useOurTranslation('measurement/list');

    return (
        <div data-test-id="measurements-section">
            <PageHeader title={t('header.dataCheck')} />

            <MeasurementsActionPanel
                measurementsCount={ratingCount.orElse(0)}
                approveInProgress={loadingItems.includes('approveMeasurements')}
                approveMeasurements={approveRatings}
                measurementsDeaths={deadAnimalsCount.orElse(0)}
                measurementsAnimalsCount={animalsinRatingsCount.orElse(0)}

            />

            <MeasurementsTable
                mortifyInProgress={loadingItems.includes('mortifyMeasurement')}
                deleteRating={deleteRating}
                mortifyAnimal={mortifyAnimal}
                editRating={editRating}
            />

        </div>
    );
};

export const MeasurementsSection = memo(MeasurementsSectionBase);
