import {formatDatetime} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {MobileAppName} from 'api/gen/MobileAppName';
import {Config} from 'app/config';
import {TFunction, useOurTranslation} from 'app/translations';
import {Ant, AntEmpty} from 'common/ant';
import {Content, LoaderWrapper, PageHeader, simpleLayoutSelector} from 'common/layout';

import {AppInfoRow} from '../components';
import {adminAction, simpleAdminSelector} from '../model';

interface MobileAppItemProps {
    t: TFunction<'admin/AppInfo'>;
    name: MobileAppName;
    onClick(name: MobileAppName): void;
}

const MobileAppItem: React.FC<MobileAppItemProps> = ({t, onClick, name}) => {
    const handleClick = useCallback(() => onClick(name), [onClick, name]);

    return (
        <AppInfoRow
            key={name}
            rowName={name}
            rowValue={t('download')}
            onClick={handleClick}
        />
    );
};

const AppInfoBase: FC = () => {
    const appInfo = useSelector(simpleAdminSelector.appInfo);
    const mobileApps = useSelector(simpleAdminSelector.mobileApps);
    const loading = useSelector(simpleLayoutSelector.isItemLoading('appInfo'));
    const {t} = useOurTranslation('admin/AppInfo');

    const dispatch = useDispatch();

    const getAppInfo = useCallback(() => {
        dispatch(adminAction.getAppInfo());
    }, [dispatch]);

    const getMobileApps = useCallback(() => {
        dispatch(adminAction.getMobileApps());
    }, [dispatch]);

    const handleDownloadMobileApp = useCallback((name: MobileAppName) => {
        dispatch(adminAction.downloadMobileApp(name));
    }, [dispatch]);

    useEffect(() => {
        getAppInfo();
        getMobileApps();
    }, [getAppInfo, getMobileApps]);

    const mobileAppsItems = useMemo(() =>
        mobileApps.prop('names').orElse([]).map(name => (
            <MobileAppItem
                key={name}
                name={name}
                t={t}
                onClick={handleDownloadMobileApp}
            />
        )),
    [handleDownloadMobileApp, mobileApps, t]);

    return appInfo.map(applicationInfo => (
        <Content key="app-info">
            <PageHeader title={t('title')} />

            <LoaderWrapper loading={loading}>
                <Ant.Divider orientation="left">
                    {t('frontend')}
                </Ant.Divider>
                <AppInfoRow
                    rowName={t('version')}
                    rowValue={Config.version}
                />
                <AppInfoRow
                    rowName={`${t('library')} cmsch-fe-library`}
                    rowValue={Config.feLibraryVersion}
                />
                <Ant.Divider orientation="left">
                    {t('backend')}
                </Ant.Divider>
                <AppInfoRow
                    rowName={t('version')}
                    rowValue={applicationInfo.appVersion}
                />
                <AppInfoRow
                    rowName={t('dbVersion')}
                    rowValue={applicationInfo.dbVersion}
                />
                {applicationInfo.libraries?.map(library => (
                    <AppInfoRow
                        key={library.name}
                        rowName={`${t('library')} ${library.name}`}
                        rowValue={library.version}
                    />
                ))}
                <Ant.Divider orientation="left">
                    {t('deployment')}
                </Ant.Divider>
                <AppInfoRow
                    rowName={t('deployDate')}
                    rowValue={formatDatetime('datetime')(applicationInfo.buildTs).orElse('-')}
                />
                <Ant.Divider orientation="left">
                    {t('mobileApplications')}
                </Ant.Divider>
                { mobileAppsItems }
            </LoaderWrapper>

        </Content>
    )).orElse(loading ? <LoaderWrapper loading /> : <AntEmpty description={t('noData')} />);
};

export const AppInfo = memo(AppInfoBase);
