// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A codex (part of an ear tag) of the animal.
// Example: "931"

export const codexRegexGen = (): RegExp => new RegExp("^\\d{3}$");

export const CodexSchema = StringPatternSchema<Codex>(codexRegexGen());

export type Codex = string;
