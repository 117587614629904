import * as Sentry from '@sentry/react';
import React, {FC, ReactNode, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {Button} from 'common/buttons';
import {Card} from 'common/layout';

import {simpleUserSelector} from './user';

import styles from './styles.sass';

const ErrorScreen: FC = () => {
    const handleRestartClick = useCallback(() => window.location.reload(), []);

    return (
        <div className={styles.errorFallbackScreen}>
            <div className={styles.errorFallbackScreenCard}>
                <Card testId="error">
                    <h2>Neočekávaná chyba</h2>
                    <p>V aplikaci došlo k neočekávané chybě.<br />Problém byl zaznamenán do našeho systému<br />
                        a budeme se ho snažit co nejdříve vyřešit.<br />Omlouváme se za způsobené potíže.</p>
                    <Button
                        visuals="primary"
                        onClick={handleRestartClick}
                    >
                        Restartovat aplikaci
                    </Button>
                </Card>
            </div>
        </div>
    );
};

interface Props {
    children: ReactNode;
}

const ErrorBoundary: FC<Props> = ({children}) => {
    const isServerError = useSelector(simpleUserSelector.isServerError);

    return (
        <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
            {isServerError ? <ErrorScreen /> : children}
        </Sentry.ErrorBoundary>
    );
};

export default ErrorBoundary;
