import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {BreederState, initialBreederState} from './state';

const breederStateO = O.optic<BreederState>();

export const breederReducer = (
    state: BreederState = initialBreederState,
    action: AppAction,
): BreederState => {
    switch (action.type) {
        case 'breeder/GET_BREEDERS_SUCCESS': {
            const {payload: {breederPage}} = action;

            return O.set(breederStateO.prop('breederPage'))(breederPage)(state);
        }
        case 'breeder/GET_BREEDER_SUCCESS': {
            const {payload: {breeder}} = action;

            return O.set(breederStateO.prop('breeder'))(breeder)(state);
        }
        case 'breeder/CLEAR_BREEDER': {
            return O.set(breederStateO.prop('breeder'))(null)(state);
        }

        case 'breeder/CLEAR_SHOWN_BREEDINGS': {
            return O.set(breederStateO.prop('breedings'))(null)(state);
        }

        case 'breeder/CLEAR_STABLE': {
            return O.set(breederStateO.prop('stable'))(null)(state);
        }

        case 'breeder/CLEAR_STABLES': {
            return O.set(breederStateO.prop('stables'))(null)(state);
        }

        case 'breeder/GET_STABLES_SUCCESS': {
            const {payload: {stables}} = action;

            return O.set(breederStateO.prop('stables'))(stables)(state);
        }

        case 'breeder/GET_STABLE_SUCCESS': {
            const {payload: {stable}} = action;

            return O.set(breederStateO.prop('stable'))(stable)(state);
        }

        case 'breeder/GET_STABLE_BREEDINGS_SUCCESS': {
            const {payload: {breedings}} = action;

            return O.set(breederStateO.prop('breedings'))(breedings)(state);
        }

        case 'breeder/GET_STABLES_ALTERNATIVE_EMAILS_SUCCESS': {
            const {payload: {emails}} = action;

            return O.set(breederStateO.prop('alternativeEmails'))(emails)(state);
        }

        case 'breeder/SET_EDITED_STABLE_ID': {
            const {payload: {stableId}} = action;

            return O.set(breederStateO.prop('editedStableId'))(stableId)(state);
        }

        default:
            return state;
    }
};
