// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractMeasurementAnimal, AbstractMeasurementAnimalSchema } from 'api/gen/AbstractMeasurementAnimal';

const requiredPart = t.interface({
  tag: t.literal('HolsteinMeasurementAnimal'),
  adjustedBodyStructure: t.union([t.Integer, t.null]),
  adjustedExtremities: t.union([t.Integer, t.null]),
  adjustedLactationPerformance: t.union([t.Integer, t.null]),
  adjustedOverallCondition: t.union([t.Integer, t.null]),
  adjustedUdder: t.union([t.Integer, t.null]),
  backAngle: t.union([t.Integer, t.null]),
  backWidth: t.union([t.Integer, t.null]),
  bodyDepth: t.union([t.Integer, t.null]),
  bonesQuality: t.union([t.Integer, t.null]),
  calculatedBodyStructure: t.union([t.Integer, t.null]),
  calculatedExtremities: t.union([t.Integer, t.null]),
  calculatedLactationPerformance: t.union([t.Integer, t.null]),
  calculatedOverallCondition: t.union([t.Integer, t.null]),
  calculatedUdder: t.union([t.Integer, t.null]),
  chestWidth: t.union([t.Integer, t.null]),
  edginess: t.union([t.Integer, t.null]),
  extremitiesDefect: t.union([t.boolean, t.null]),
  fitness: t.union([t.Integer, t.null]),
  foreUdderAttachment: t.union([t.Integer, t.null]),
  frame: t.union([t.Integer, t.null]),
  frontTeatsPlacement: t.union([t.Integer, t.null]),
  height: t.union([t.Integer, t.null]),
  hooves: t.union([t.Integer, t.null]),
  pasternDefect: t.union([t.boolean, t.null]),
  rearPosture: t.union([t.Integer, t.null]),
  rearTeatsPlacement: t.union([t.Integer, t.null]),
  rearUdderHeight: t.union([t.Integer, t.null]),
  shortSacrum: t.union([t.boolean, t.null]),
  shouldersDefect: t.union([t.boolean, t.null]),
  sidePosture: t.union([t.Integer, t.null]),
  supernumeraryTeats: t.union([t.boolean, t.null]),
  suspensoryLigament: t.union([t.Integer, t.null]),
  teatsDefect: t.union([t.boolean, t.null]),
  teatsLength: t.union([t.Integer, t.null]),
  udderDefect: t.union([t.boolean, t.null]),
  udderDepth: t.union([t.Integer, t.null]),
  udderWidth: t.union([t.Integer, t.null]),
  upperLineDefect: t.union([t.boolean, t.null]),
  walkQuality: t.union([t.Integer, t.null]),
});

export const HolsteinMeasurementAnimalSchema = excess(t.intersection([AbstractMeasurementAnimalSchema.type, requiredPart]));

export interface HolsteinMeasurementAnimal extends t.TypeOf<typeof HolsteinMeasurementAnimalSchema> {}
