import {tCommon} from 'app/translations';
import {RatingValidator} from 'app/utils/rating-validator';
import {Errors} from 'utils/validator';

import {EditRatingFormValues} from '../../types/edit-rating-values';

export const validate = (values: EditRatingFormValues): Errors<EditRatingFormValues> => {
    const validator = new RatingValidator(values);

    validator.nonEmpty('lactation', tCommon('lactation'));
    validator.lactation('lactation', tCommon('lactation'));
    validator.nonEmpty('ratingDate', tCommon('ratingDate'));

    return validator.generateErrorsObject();
};
