export const bullTranslations = {
    CS: {
        'bull/bullForm': {
            marked: 'Testace',
            note: 'Poznámka',
            editBull: 'Úprava býka',
            bulls: 'Býci',
        },
        'bull/bullTable': {
            marked: 'Příznak',
            numOfDaughtersToMeasure: 'Dcer k hodnocení',
            numOfMeasuredDaughters: 'Hodnocených dcer',
            numOfMeasuredDaughtersAbbr: 'Hodn. dcer',
            numOfMeasuredBreedings: 'Hodnocených chovů',
            numOfMeasuredBreedingsAbbr: 'Hodn. chovů',
            numOfMeasuredBreedingsTooltip: 'Počet chovů, ze kterých hodnocené dcery pochází',
            note: 'Poznámka',
            noBullsAvailable: 'Nejsou k dispozici žádní býci',
            edit: 'Upravit',
            exportDaughters: 'Stáhnout seznam hodnocených dcer',
            daughtersOverview: 'Přehled dcer k hodnocení',
            bullList: 'Seznam býků',
            invoicedAtAbbr: 'Dat. ukončení T',
            invoicedAt: 'Datum ukončení T',
            isInvoiced: 'Fakturováno',
            subjectName: 'Registroval',
            exportBullDaughtersIsDisabled: 'Býk nemá hodnocené žádné dcery',
        },
        'bull/daughtersTable': {
            annotation: 'Zvíře patří do chovu v němž byl hodnocen jiný potomek daného býka',
            noDaughters: 'Tento býk nemá žádné dcery',
            daughtersOverview: 'Přehled dcer k hodnocení',
            bulls: 'Býci',
        },
        'bull/invoicesTable': {
            invoicesOverview: 'Přehled k fakturaci',
            invoicedAt: 'Datum ukončení T',
            invoicedAtAbbr: 'Dat. ukončení T',
            isInvoiced: 'Fakturováno',
            registeredAt: 'Registrováno',
            categoryId: 'Kategorie býka',
            registrarName: 'Registroval',
            numOfDaughtersToMeasure: 'Dcer k hodnocení',
            numOfMeasuredDaughters: 'Hodnocených dcer',
            numOfMeasuredDaughtersAbbr: 'Hodn. dcer',
            numOfMeasuredBreedingsAbbr: 'Hodn. chovů',
            numOfMeasuredBreedings: 'Počet chovů, ze kterých hodnocené dcery pochází',
            noInvoicesAvailable: 'Nejsou k dispozici žádné faktury',
            annotation: 'Býk má aspoň 50 ohodnocených dcer, ale nebyl vyfakturován.',
            createInvoicingDocuments: 'Vytvořit podklady k fakturaci ({{count}})',
            selectAtLeastOneBull: 'Vyberte alespoň jednoho býka',
            exportInvoicingDocuments: 'Export podkladů k fakturaci',
            exportDaughters: 'Export dcer',
            invoicingDocumentsExists: 'Stáhnout podklady k fakturaci',
            exportBullDaughtersIsDisabled: 'Býk nemá hodnocené žádné dcery.',
            bullWasAlreadyBilled: 'Pro býka byly vytvořeny podklady k fakturaci',
            daughtersOverview: 'Přehled dcer k hodnocení',
            bullHasNoInvoicingDocuments: 'Pro býka nebyly vytvořeny podklady k fakturaci',
        },
        'bull/sagas': {
            createInvoicingDocuments: 'Vytvoření podkladů k fakturaci',
            editBull: 'Úprava býka',
            bullWasEdited: 'Býk byl upraven',
            exportBullDaughters: 'Export dcer býka',
            exportBullMarkedDaughters: 'Export dcer býka s příznakem T',
            getBullAnimals: 'Načtení dcer býka',
            getBull: 'Načtení býka',
            getBulls: 'Načtení seznamu býků',
            exportInvoicingDocuments: 'Export podkladů k fakturaci',
        },
    },
};
