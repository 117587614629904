import {cinRegexGen} from 'api/gen/Cin';
import {emailRegexGen} from 'api/gen/Email';
import {phoneNumberMaxLength} from 'api/gen/PhoneNumber';
import {t, tCommon} from 'app/translations';
import {Errors, Validator} from 'utils/validator';

import {UpdateBreederFormValues} from '../../types/update-breeder-form-values';

export const validate = (values: UpdateBreederFormValues): Errors<UpdateBreederFormValues> => {
    const validator = new Validator(values);

    validator.pattern('email', emailRegexGen(), tCommon('email'));
    validator.pattern('ico', cinRegexGen(), t('breeder')('ico'));
    validator.maxStringLength('phone', phoneNumberMaxLength, t('breeder')('phone'));

    return validator.generateErrorsObject();
};
