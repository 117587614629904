import {NullableKeys} from '@fl/cmsch-fe-library';

import {BreederSuiteEmail} from 'api/gen/BreederSuiteEmail';

export const sendToBreedersFormName = 'sendToBreeders';

export interface SendToBreedersFormValues extends NullableKeys<BreederSuiteEmail> {
    mergeStables: boolean;
}

export const sendToBreedersInitialValues: SendToBreedersFormValues = {
    content: null,
    emails: null,
    emailsToSendCopyTo: null,
    mergeStables: true,
};
