// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A unique code identification of a user.
// Example: "203"

export const userCodeRegexGen = (): RegExp => new RegExp("^\\d{3}$");

export const UserCodeSchema = StringPatternSchema<UserCode>(userCodeRegexGen());

export type UserCode = string;
