import React, {FC, memo, useMemo} from 'react';

import {District} from 'api/gen/District';
import {List} from 'common/layout';
import {ListItem} from 'common/layout/components/list';

interface Props {
    districts: Array<District> | null;
    selectedIds: Array<number>;
    toggleSelectDistrict(districtId: number): void;
}

const DistrictListBase: FC<Props> = props => {
    const {districts, selectedIds, toggleSelectDistrict} = props;

    const items = useMemo((): Array<ListItem> =>
        (districts || []).map(district => ({
            id: district.id,
            node: district.name,
            selected: selectedIds.includes(district.id),
            action: (): void => toggleSelectDistrict(district.id),
        }))
    , [districts, selectedIds, toggleSelectDistrict]);

    return (
        <List
            items={items}
            testId="districts"
        />
    );
};

export const DistrictList = memo(DistrictListBase);
