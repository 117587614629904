export const breederSuiteTranslations = {
    CS: {
        'breederSuite/list': {
            breederSuites: 'Sestavy chovatelům',
        },
        'breederSuite/BreederSuitesTable': {
            sumOfAnimals: 'Počet krav',
            downloadExport: 'Stáhnout export ({{count}})',
            sendToBreeders: 'Odeslat chovatelům ({{count}})',
            emailSentDate: 'Dat. odeslání e-mailu',
        },
        'breederSuites/SendToBreedersForm': {
            emails: 'E-mailové adresy',
            content: 'Text e-mailu',
            title: 'Odeslat chovatelům',
            mergeStables: 'Sloučit stáje',
        },
        'breederSuites/messages': {
            exportBreederSuitesTitle: 'Stažení exportu',
            getEmailTemplate: 'Načtení šablony e-mailu',
            sendEmailSuccess: 'E-maily byly úspěšně odeslány',
        },
    },
};
