import {none, Opt} from 'ts-opt';

import {PageBullView} from 'api/gen/PageBullView';
import {PageInvoicingDocuments} from 'api/gen/PageInvoicingDocuments';
import {PagePlanningDaughter} from 'api/gen/PagePlanningDaughter';
import {PagePlanningDaughterWithMark} from 'api/gen/PagePlanningDaughterWithMark';

export interface BullState {
    bullsPage: Opt<PageBullView>;
    daughters: Opt<PagePlanningDaughterWithMark>;
    invoicesDaughters: Opt<PagePlanningDaughter>;
    selectedBullsIds: Array<number>;
    bullInEdit: Opt<number>;
    invoicesPage: Opt<PageInvoicingDocuments>;
}

export const initialBullState: BullState = {
    bullsPage: none,
    daughters: none,
    invoicesDaughters: none,
    selectedBullsIds: [],
    bullInEdit: none,
    invoicesPage: none,
};
