import React from 'react';
import {Route} from 'react-router-dom';

import {List} from './screens';

export const breederSuiteRoutes = [
    (
        <Route
            exact
            path="/breeder-suites"
            component={List}
            key="breederSuites"
        />
    ),
];

