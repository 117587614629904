import {NullableKeys} from '@fl/cmsch-fe-library';

import {HolsteinMeasurementUpdate} from 'api/gen/HolsteinMeasurementUpdate';

export const repairHolsteinMeasurementForm = 'repairHolsteinMeasurement';

export type RepairHolsteinMeasurementFormValues = NullableKeys<HolsteinMeasurementUpdate>;

export const repairHolsteinMeasurementInitialValues: RepairHolsteinMeasurementFormValues = {
    earTag: null,
    lactation: null,
    properties: null,
    ratingDate: null,
    tag: 'HolsteinMeasurementUpdate',
};
