import {Options, formatDatetimeIso} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';
import {Opt} from 'ts-opt';

import {RatingToApprove} from 'api/gen/RatingToApprove';
import {RatingUpdate} from 'api/gen/RatingUpdate';
import {useOurTranslation} from 'app/translations';
import {AntTabs} from 'common/ant';
import {Modal} from 'common/layout';

import {EditRatingFormValues} from '../../types/edit-rating-values';
import {EditRatingForm} from '../edit-rating-form';
import {SelectAnimalForm} from '../select-animal-form';

interface Props {
    foundEarTags: Options<string>;
    rating: Opt<RatingToApprove>;

    assignAnimalToRating(): void;
    closeModal(): void;
    findEarTags(value: string): void;
    updateRating(rating: RatingUpdate): void;
    setRatingFormValues(rating: EditRatingFormValues): void;
}

const addTestId = (el: ReactNode, testId: string): ReactNode => <div data-test-id={testId}>{el}</div>;

const EditRatingModalBase: FC<Props> = props => {
    const {
        foundEarTags,
        rating,
        assignAnimalToRating,
        closeModal,
        findEarTags,
        updateRating,
        setRatingFormValues,
    } = props;

    const {t} = useOurTranslation('measurement/table');

    return rating.map(x => {
        const initialValues: EditRatingFormValues = {
            lactation: x.lactation,
            ratingDate: formatDatetimeIso('date')(x.ratingTs).orNull(),
        };

        return (
            <Modal
                modalId="editRating"
                title={t('editRating')}
                footer={null}
                onCancel={closeModal}
                key={x.id}
            >
                <AntTabs>
                    <AntTabs.TabPane
                        tab={addTestId(t('assignAnimalToRating'), 'assign-animal-to-rating')}
                        key="assignAnimalToRating"
                    >
                        <SelectAnimalForm
                            onSubmit={assignAnimalToRating}
                            onCancel={closeModal}
                            foundEarTags={foundEarTags}
                            findEarTags={findEarTags}
                        />
                    </AntTabs.TabPane>
                    <AntTabs.TabPane
                        tab={addTestId(t('editData'), 'edit-data')}
                        key="editData"
                    >
                        <EditRatingForm
                            initialValues={initialValues}
                            onSubmit={updateRating}
                            onCancel={closeModal}
                            setRatingFormValues={setRatingFormValues}
                        />
                    </AntTabs.TabPane>
                </AntTabs>
            </Modal>
        );
    }).orNull();
};

export const EditRatingModal = memo(EditRatingModalBase);
