// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The whole name of a user.
// Example: "John Doe"

export const wholeNameMinLength = 1;
export const wholeNameMaxLength = 255;

export const WholeNameSchema = t.refinement(t.string, n => n.length >= wholeNameMinLength && n.length <= wholeNameMaxLength, 'WholeName');

export type WholeName = string;
