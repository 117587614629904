// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractMeasurementUpdate, AbstractMeasurementUpdateSchema } from 'api/gen/AbstractMeasurementUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FleckviehMeasurementProperties, FleckviehMeasurementPropertiesSchema } from 'api/gen/FleckviehMeasurementProperties';

const requiredPart = t.interface({
  tag: t.literal('FleckviehMeasurementUpdate'),
  properties: FleckviehMeasurementPropertiesSchema,
});

export const FleckviehMeasurementUpdateSchema = excess(t.intersection([AbstractMeasurementUpdateSchema.type, requiredPart]));

export interface FleckviehMeasurementUpdate extends t.TypeOf<typeof FleckviehMeasurementUpdateSchema> {}
