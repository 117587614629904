import {isNull} from 'lodash/fp';
import React, {FC, Fragment, memo, PropsWithChildren, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import * as routerActions from 'redux-first-history';
import {opt} from 'ts-opt';

import {AppLayout} from 'app/app-layout';
import {homePagePath, loginPagePath, logoutPagePath} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {LocationState} from 'app/user/types/location-state';
import {Ant} from 'common/ant';
import {Button} from 'common/buttons/components/button';
import {AppLogo} from 'common/layout/components/app-logo';
import {Loader} from 'common/layout/components/loader';
import {getPathRestriction} from 'common/layout/components/main-menu/menu-items';
import {SmallMenu} from 'common/layout/components/main-menu/small-menu';
import {simpleLayoutSelector} from 'common/layout/model/selector';

import {userAction} from '../../model/action';
import {simpleUserSelector} from '../../model/selector';

const buildNextLocationState = (
    pathname: string,
    search: string,
    previousRedirectTo?: string,
): LocationState => ({
    redirectTo: previousRedirectTo ?? `${pathname}${search}`,
});

const UserPermissionGuardBase: FC<PropsWithChildren> = props => {
    const {children} = props;
    const dispatch = useDispatch();
    const {pathname, search} = useLocation();
    const {t, tCommon} = useOurTranslation('user/messages');

    const currentUser = useSelector(simpleUserSelector.currentUser);
    const isUserInitialized = useSelector(simpleUserSelector.isUserInitialized);
    const isUserLoading = useSelector(simpleUserSelector.isUserLoading);
    const locationState = useSelector(simpleLayoutSelector.locationState);

    const currentPathRestriction = useMemo(() =>
        getPathRestriction(opt(currentUser), pathname),
    [currentUser, pathname]);

    const needsLogin = useMemo(() =>
        currentPathRestriction.onlyForLoggedIn || currentPathRestriction.userLacksPermissions,
    [currentPathRestriction.onlyForLoggedIn, currentPathRestriction.userLacksPermissions]);

    useEffect(() => {
        if (!isUserInitialized && !isUserLoading) dispatch(userAction.initialize({needsLogin}));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const goToLogIn = useCallback(() => {
        dispatch(routerActions.push(
            loginPagePath,
            buildNextLocationState(pathname, search, locationState?.redirectTo),
        ));
    }, [dispatch, pathname, search, locationState?.redirectTo]);

    const goToLogOut = useCallback(() => {
        dispatch(routerActions.push(
            logoutPagePath,
            buildNextLocationState(pathname, search, locationState?.redirectTo),
        ));
    }, [dispatch, pathname, search, locationState?.redirectTo]);

    const goHome = useCallback(() => {
        dispatch(routerActions.push(homePagePath));
    }, [dispatch]);

    const userHasAccess = useMemo(() => !getPathRestriction(opt(currentUser), pathname).userLacksPermissions
        , [currentUser, pathname]);

    const shouldBeLoggedIn = useMemo(() => isNull(currentUser) && needsLogin, [currentUser, needsLogin]);

    useEffect(() => {
        if (shouldBeLoggedIn) goToLogIn();
    }, [goToLogIn, shouldBeLoggedIn]);

    if (!isUserInitialized || isUserLoading) {
        return (
            <AppLayout header={(
                <SmallMenu
                    actionIcons={[]}
                    appLogo={<AppLogo environment="development" />}
                    link="#"
                />
            )}
            >
                <Loader
                    show
                    noBackground
                    text={tCommon('userLoading')}
                    size="large"
                    centerPosition
                />
            </AppLayout>
        );
    }

    if (shouldBeLoggedIn) return null;

    if (!userHasAccess) {
        return (
            <Ant.Result
                status="warning"
                title={t('noPermissionMessage')}
                extra={(
                    <Fragment>
                        <Button
                            visuals="primary"
                            icon="logOutOutlined"
                            onClick={goToLogOut}
                        >
                            {t('loginAsAnother')}
                        </Button>
                        <Button
                            visuals="primary"
                            icon="rollbackOutlined"
                            onClick={goHome}
                        >
                            {t('goBack')}
                        </Button>
                    </Fragment>
                )}
            />
        );
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    );
};

export const UserPermissionGuard = memo(UserPermissionGuardBase);
