import {getBreederSaga} from './get-breeder-saga';
import {getBreedersSaga} from './get-breeders-saga';
import {getStableBreedingsSaga} from './get-stable-breedings-saga';
import {getStableSaga} from './get-stable-saga';
import {getStablesAlternativeEmailsSaga} from './get-stables-alternative-emails-saga';
import {getStablesSaga} from './get-stables-saga';
import {updateBreederBreedingArealRatingSaga} from './update-breeder-breeding-areal-rating-saga';
import {updateBreederSaga} from './update-breeder-saga';
import {updateStableSaga} from './update-stable-saga';

export const breederSagas = [
    getBreedersSaga,
    getBreederSaga,
    updateBreederSaga,
    updateBreederBreedingArealRatingSaga,
    getStableSaga,
    getStablesSaga,
    updateStableSaga,
    getStableBreedingsSaga,
    getStablesAlternativeEmailsSaga,
];
