// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FleckviehMeasurementView, FleckviehMeasurementViewSchema } from 'api/gen/FleckviehMeasurementView';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinMeasurementView, HolsteinMeasurementViewSchema } from 'api/gen/HolsteinMeasurementView';

export const MeasurementViewSchema = t.union([FleckviehMeasurementViewSchema, HolsteinMeasurementViewSchema]);

export type MeasurementView = t.TypeOf<typeof MeasurementViewSchema>;
