// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// An e-mail address.
// Example: "john.doe@example.org"

export const emailRegexGen = (): RegExp => new RegExp("^[\\w.%+-]+@[\\w.-]+\\.[a-zA-Z]{2,}$");

export const EmailSchema = StringPatternSchema<Email>(emailRegexGen());

export type Email = string;
