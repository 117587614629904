// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: 0

export const positiveOrZeroIntegerMinimum = 0.0;

export const PositiveOrZeroIntegerSchema = t.refinement(t.Integer, x => x >= positiveOrZeroIntegerMinimum, 'PositiveOrZeroInteger');

export type PositiveOrZeroInteger = t.TypeOf<typeof PositiveOrZeroIntegerSchema>;
