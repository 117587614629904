import {BasicImport} from 'api/gen/BasicImport';
import {Import} from 'api/gen/Import';

export interface ImportKkutState {
    importResult: BasicImport | null;
    imports: Array<Import> | null;
}

export const initialImportKkutState: ImportKkutState = {
    importResult: null,
    imports: null,
};
