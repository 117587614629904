import {TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairHolsteinMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairHolsteinMeasurementFormValues, 'properties'>;

const OtherFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="fitness"
                label={tCommon('fitness')}
                isRequired
            />
            <Fields.NumberInput
                name="height"
                label={tCommon('height')}
                isRequired
            />
        </Fragment>
    );
};

export const OtherFormSection = memo(OtherFormSectionBase);
