// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  arealRating: t.boolean,
  breederName: BreederNameSchema,
  breedingNumber: BreedingNumberSchema,
  id: PositiveLongSchema,
  numOfMeasurableAnimals: PositiveOrZeroIntegerSchema,
  stableName: StableNameSchema,
});

export const BreedingDetailsSchema = excess(requiredPart);

export interface BreedingDetails extends t.TypeOf<typeof BreedingDetailsSchema> {}
