import * as O from 'optics-ts';
import {opt, pipe} from 'ts-opt';

import {AppAction} from 'app/setup';

import {BreederSuiteState, initialBreederSuiteState} from './state';

const breederSuiteStateO = O.optic<BreederSuiteState>();

export const breederSuiteReducer = (
    state: BreederSuiteState = initialBreederSuiteState,
    action: AppAction,
): BreederSuiteState => {
    switch (action.type) {
        case 'breederSuite/GET_BREEDER_SUITES_SUCCESS': {
            const {breederSuitePage} = action.payload;

            return O.set(breederSuiteStateO.prop('breederSuitePage'))(breederSuitePage)(state);
        }

        case 'breederSuite/TOGGLE_SELECT': {
            const {ids, rows} = action.payload;

            return pipe(
                state,
                O.set(breederSuiteStateO.prop('selectedBreederSuitesIds'))(ids),
                O.set(breederSuiteStateO.prop('selectedBreederSuites'))(rows),
            );
        }

        case 'breederSuite/GET_EMAIL_TEMPLATE_SUCCESS': {
            const {emailTemplate} = action.payload;

            return O.set(breederSuiteStateO.prop('emailTemplate'))(opt(emailTemplate))(state);
        }

        default:
            return state;
    }
};
