import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import * as routerActions from 'redux-first-history';
import {none, parseInt} from 'ts-opt';

import {FleckviehMeasurementUpdateSchema} from 'api/gen/FleckviehMeasurementUpdate';
import {HolsteinMeasurementUpdateSchema} from 'api/gen/HolsteinMeasurementUpdate';
import {earTagWithoutCountryMaxLength} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {NotFound} from 'app/user/components';
import {Content, LoaderWrapper, PageHeader, Panel, simpleLayoutSelector} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {RepairFleckviehMeasurementForm, RepairHolsteinMeasurementForm} from '../components';
import {RepairFleckviehMeasurementFormValues} from '../components/repair-fleckvieh-measurement-form/values';
import {RepairHolsteinMeasurementFormValues} from '../components/repair-holstein-measurement-form/values';
import {repairAction, simpleRepairSelector} from '../model';

const DetailBase: FC = _ => {
    const {params: {measurementId}} = useRouteMatch<{measurementId: string}>();
    const parsedMeasurementId = parseInt(measurementId).orCrash('Invalid measurement id');
    const {t} = useOurTranslation('repair/Detail');
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(repairAction.getApprovedMeasurement({measurementId: parsedMeasurementId}));
    }, [dispatch, parsedMeasurementId]);
    const goBackToList = useCallback(() => {
        dispatch(routerActions.push('/repairs'));
    }, [dispatch]);
    const submitFleckviehMeasurement = useCallback((data: RepairFleckviehMeasurementFormValues) => {
        if (FleckviehMeasurementUpdateSchema.is(data)) {
            dispatch(repairAction.updateApprovedMeasurement(data, parsedMeasurementId));
        }
    }, [dispatch, parsedMeasurementId]);
    const submitHolsteinMeasurement = useCallback((data: RepairHolsteinMeasurementFormValues) => {
        if (HolsteinMeasurementUpdateSchema.is(data)) {
            dispatch(repairAction.updateApprovedMeasurement(data, parsedMeasurementId));
        }
    }, [dispatch, parsedMeasurementId]);
    const findAnimals = useCallback((earTag: string) => {
        if (earTag.length <= earTagWithoutCountryMaxLength) dispatch(repairAction.findAnimals(earTag));
    }, [dispatch]);
    const resetApprovedMeasurement = useCallback(() => {
        dispatch(repairAction.getApprovedMeasurementSuccess(none));
    }, [dispatch]);
    useEffect(() => (): void => {
        resetApprovedMeasurement();
    }, [resetApprovedMeasurement]);
    const measurementDetail = useSelector(simpleRepairSelector.measurementDetail);
    const foundAnimalsOptions = useSelector(simpleRepairSelector.foundAnimals);
    const earTag = useSelector(formHelpers.formValues('repairFleckviehMeasurement')).prop('earTag');
    const foundAnimal = useSelector(simpleRepairSelector.foundAnimal(earTag.orElse('')));
    const loading = useSelector(simpleLayoutSelector.isItemLoading('repair'));
    const findAnimalsInProgress = useSelector(simpleLayoutSelector.isItemLoading('findAnimals'));

    if (measurementDetail.isEmpty && !loading) {
        return (
            <NotFound
                text={t('notFound')}
                buttonText={t('backToList')}
                link="/repairs"
            />
        );
    }

    return measurementDetail.map(measurement => (
        <Content key={measurement.id}>
            <PageHeader
                title={t('title', {earTag: measurement.earTag})}
                onBack={goBackToList}
                smallerTitleOnPhone
            />
            <Panel>
                {measurement.tag === 'FleckviehMeasurementDetail' ? (
                    <RepairFleckviehMeasurementForm
                        measurementDetail={measurement}
                        foundAnimalsOptions={foundAnimalsOptions}
                        foundAnimal={foundAnimal}
                        findAnimalsInProgress={findAnimalsInProgress}
                        onSubmit={submitFleckviehMeasurement}
                        findAnimals={findAnimals}
                    />
                ) : (
                    <RepairHolsteinMeasurementForm
                        measurementDetail={measurement}
                        foundAnimalsOptions={foundAnimalsOptions}
                        foundAnimal={foundAnimal}
                        findAnimalsInProgress={findAnimalsInProgress}
                        onSubmit={submitHolsteinMeasurement}
                        findAnimals={findAnimals}
                    />
                )}
            </Panel>
        </Content>
    )).orElse(
        <Content>
            <PageHeader
                title={t('title')}
                onBack={goBackToList}
                smallerTitleOnPhone
                loading
            />

            <LoaderWrapper loading={loading} />

        </Content>,
    );
};

export const Detail = memo(DetailBase);
