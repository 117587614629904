// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Cin, CinSchema } from 'api/gen/Cin';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Email, EmailSchema } from 'api/gen/Email';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PhoneNumber, PhoneNumberSchema } from 'api/gen/PhoneNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { VatNumber, VatNumberSchema } from 'api/gen/VatNumber';

const requiredPart = t.interface({
  cin: t.union([CinSchema, t.null]),
  email: t.union([EmailSchema, t.null]),
  id: PositiveLongSchema,
  name: BreederNameSchema,
  phone: t.union([PhoneNumberSchema, t.null]),
  vatNumber: t.union([VatNumberSchema, t.null]),
});

export const BreederSchema = excess(requiredPart);

export interface Breeder extends t.TypeOf<typeof BreederSchema> {}
