// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BasicAnimal, BasicAnimalSchema } from 'api/gen/BasicAnimal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Email, EmailSchema } from 'api/gen/Email';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  animals: t.array(BasicAnimalSchema),
  email: t.union([EmailSchema, t.null]),
  id: PositiveLongSchema,
  name: StableNameSchema,
});

export const StableInPlanSchema = excess(requiredPart);

export interface StableInPlan extends t.TypeOf<typeof StableInPlanSchema> {}
