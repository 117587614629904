import React, {Fragment, FC, memo, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {alternativeEmailsSelector} from 'app/breeder/model/selector';
import {useOurTranslation} from 'app/translations';
import {Content, LoaderWrapper, PageHeader, simpleLayoutSelector} from 'common/layout';

import {PlanActionPanel, PlanBreederEmailModal, PlanBreederSections, PlanStableEmailModal} from '../components';
import {useGetDataForPlan} from '../hooks';
import {simplePlanningSelector} from '../model';
import {getAnimalsCount, getPlanTitle, getStablesIds} from '../utils';

const PlanBase: FC = _ => {
    const plan = useSelector(simplePlanningSelector.plan);
    const selectedAnimals = useSelector(simplePlanningSelector.selectedAnimals);
    const selectedCompanies = useSelector(simplePlanningSelector.selectedCompanies);
    const planEmailModalStableId = useSelector(simplePlanningSelector.planEmailModalStableId);
    const planEmailModalBreederId = useSelector(simplePlanningSelector.planEmailModalBreederId);
    const loadingItems = useSelector(simpleLayoutSelector.loadingItems);
    const emailTemplate = useSelector(simplePlanningSelector.emailTemplate);
    const alternativeEmails = useSelector(alternativeEmailsSelector);

    const {t} = useOurTranslation('planning/plan');
    const {
        visibleStables,
        closePlanStableEmailModal,
        closePlanBreederEmailModal,
        deletePlan,
        getStablesAlternativeEmails,
        onCollapseAll,
        onOpenPlanStableEmailModal,
        onOpenPlanBreederEmailModal,
        onToggleCollapse,
        onUncollapseAll,
        printBreederPlan,
        printBreederStablePlan,
        printPlan,
        removeBreederFromPlan,
        removeSelectedAnimalsFromPlan,
        setSelectedAnimalsAsDead,
        sendPlanStableEmail,
        sendPlanBreederEmail,
        setPlanEmailFormValues,
        toggleSelectAnimal,
    } = useGetDataForPlan();

    const animalsCount = useMemo(() => plan && getAnimalsCount(plan), [plan]);
    const breedingCount = useMemo(() => plan?.breeders?.length ?? 0, [plan?.breeders?.length]);
    const stablesCount = useMemo(() => getStablesIds(plan).length, [plan]);

    return (
        <Fragment>
            <Content>
                <PageHeader
                    title={getPlanTitle(t, animalsCount || 0, breedingCount, stablesCount)}
                    smallerTitleOnPhone
                    loading={loadingItems.includes('plan')}
                />

                <LoaderWrapper loading={loadingItems.includes('plan')}>
                    <PlanActionPanel
                        selectedAnimalsCount={selectedAnimals.length}
                        selectedCompaniesCount={selectedCompanies.length}
                        plan={plan}
                        loadingItems={loadingItems}
                        visibleStables={visibleStables}
                        removeSelectedAnimalsFromPlan={removeSelectedAnimalsFromPlan}
                        setSelectedAnimalsAsDead={setSelectedAnimalsAsDead}
                        printPlan={printPlan}
                        deletePlan={deletePlan}
                        onCollapseAll={onCollapseAll}
                        onUncollapseAll={onUncollapseAll}
                    />

                    <PlanBreederSections
                        plan={plan}
                        selectedAnimals={selectedAnimals}
                        visibleStables={visibleStables}
                        loadingItems={loadingItems}
                        onToggleSelectAnimal={toggleSelectAnimal}
                        onOpenPlanStableEmailModal={onOpenPlanStableEmailModal}
                        onOpenPlanBreederEmailModal={onOpenPlanBreederEmailModal}
                        onRemoveBreeder={removeBreederFromPlan}
                        onPrintBreederPlan={printBreederPlan}
                        onPrintBreederStablePlan={printBreederStablePlan}
                        onToggleCollapse={onToggleCollapse}
                    />
                </LoaderWrapper>
            </Content>
            <PlanStableEmailModal
                plan={plan}
                planEmailModalStableId={planEmailModalStableId}
                loadingItems={loadingItems}
                emailTemplate={emailTemplate}
                alternativeEmails={alternativeEmails}
                sendPlanEmail={sendPlanStableEmail}
                closePlanEmailModal={closePlanStableEmailModal}
                getStablesAlternativeEmails={getStablesAlternativeEmails}
                setPlanEmailFormValues={setPlanEmailFormValues}
            />
            <PlanBreederEmailModal
                plan={plan}
                planEmailModalBreederId={planEmailModalBreederId}
                loadingItems={loadingItems}
                emailTemplate={emailTemplate}
                alternativeEmails={alternativeEmails}
                sendPlanEmail={sendPlanBreederEmail}
                closePlanEmailModal={closePlanBreederEmailModal}
                getStablesAlternativeEmails={getStablesAlternativeEmails}
                setPlanEmailFormValues={setPlanEmailFormValues}
            />
        </Fragment>
    );
};

export const Plan = memo(PlanBase);
