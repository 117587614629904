// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: BONITER

export const RoleSchema = t.keyof({
  BONITER: null,
  ADMIN: null,
});

export type Role = t.TypeOf<typeof RoleSchema>;
