import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const repairRoutes = [
    (
        <Route
            exact
            path="/repairs"
            component={Screens.List}
            key="repairs"
        />
    ),
    (
        <Route
            exact
            path="/repairs/:measurementId"
            component={Screens.Detail}
            key="repairs-detail"
        />
    ),
];
