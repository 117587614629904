import {yearMonthRegexGen} from 'api/gen/YearMonth';
import {t} from 'app/translations';
import {Errors, Validator} from 'utils/validator';

import {ExportUserMeasurementsFormValues} from '../../types/export-user-measurements-form-values';

export const validate = (values: ExportUserMeasurementsFormValues): Errors<ExportUserMeasurementsFormValues> => {
    const validator = new Validator(values);

    validator.nonEmpty('userItem', t('planning/exportMeasurements')('errors.user'));
    validator.nonEmpty('monthYear', t('planning/exportMeasurements')('errors.month'));
    validator.pattern('monthYear', yearMonthRegexGen(), t('planning/exportMeasurements')('errors.month'));

    return validator.generateErrorsObject();
};
