import {Options} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {useOurTranslation} from 'app/translations';
import {Modal} from 'common/layout';

import {SendToBreedersForm} from '../send-to-breeders-form';
import {SendToBreedersFormValues} from '../send-to-breeders-form/form-values';

interface Props {
    emailOptions: Options<string>;
    values: SendToBreedersFormValues;
    onSubmit(values: SendToBreedersFormValues): void;
    closeModal(): void;
    getStableAlternativeEmails(): void;
    setSendToBreedersFormValues(values: SendToBreedersFormValues): void;
}

const SendToBreedersModalBase: FC<Props> = props => {
    const {
        emailOptions,
        values,
        closeModal,
        onSubmit,
        setSendToBreedersFormValues,
        getStableAlternativeEmails,
    } = props;

    const {t} = useOurTranslation('breederSuites/SendToBreedersForm');

    return (
        <Modal
            modalId="sendToBreeders"
            title={t('title')}
            footer={null}
            width={620}
            onCancel={closeModal}
        >
            <SendToBreedersForm
                emailOptions={emailOptions}
                values={values}
                onSubmit={onSubmit}
                closeModal={closeModal}
                setSendToBreedersFormValues={setSendToBreedersFormValues}
                getStableAlternativeEmails={getStableAlternativeEmails}
            />
        </Modal>
    );
};

export const SendToBreedersModal = memo(SendToBreedersModalBase);
