import {AppInfo} from 'api/gen/AppInfo';
import {MobileAppName} from 'api/gen/MobileAppName';
import {MobileApps} from 'api/gen/MobileApps';

export const adminAction = {
    getAppInfo: () => ({
        type: 'admin/GET_APP_INFO',
    }) as const,
    getAppInfoSuccess: (appInfo: AppInfo) => ({
        type: 'admin/GET_APP_INFO_SUCCESS',
        payload: {appInfo},
    }) as const,
    getMobileApps: () => ({
        type: 'admin/GET_MOBILE_APPS',
    }) as const,
    getMobileAppsSuccess: (apps: MobileApps) => ({
        type: 'admin/GET_MOBILE_APPS_SUCCESS',
        payload: {apps},
    }) as const,
    downloadMobileApp: (name: MobileAppName) => ({
        type: 'admin/DOWNLOAD_MOBILE_APP',
        payload: {name},
    }) as const,
};

export type GetAppInfoAction = ReturnType<typeof adminAction.getAppInfo>;
export type GetAppInfoSuccessAction = ReturnType<typeof adminAction.getAppInfoSuccess>;
export type GetMobileAppsAction = ReturnType<typeof adminAction.getMobileApps>;
export type GetMobileAppsSuccessAction = ReturnType<typeof adminAction.getMobileAppsSuccess>;
export type DownloadMobileAppAction = ReturnType<typeof adminAction.downloadMobileApp>;

export type AdminAction =
    | GetAppInfoAction
    | GetAppInfoSuccessAction
    | GetMobileAppsAction
    | GetMobileAppsSuccessAction
    | DownloadMobileAppAction
;
