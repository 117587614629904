// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  body: t.string,
  id: PositiveLongSchema,
});

export const EmailTemplateSchema = excess(requiredPart);

export interface EmailTemplate extends t.TypeOf<typeof EmailTemplateSchema> {}
