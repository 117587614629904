import * as React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const userRoutes = [
    (
        <Route
            exact
            path="/login"
            component={Screens.Login}
            key="login"
        />
    ),
    (
        <Route
            exact
            path="/logout"
            component={Screens.Logout}
            key="logout"
        />
    ),
];
