import {none, Opt} from 'ts-opt';

import {PageEmailAudit} from 'api/gen/PageEmailAudit';

export interface EmailState {
    sentEmailsPage: Opt<PageEmailAudit>;
}

export const initialEmailState: EmailState = {
    sentEmailsPage: none,
};
