import {FleckviehMeasurementAnimal} from 'api/gen/FleckviehMeasurementAnimal';
import {HolsteinMeasurementAnimal} from 'api/gen/HolsteinMeasurementAnimal';
import {formatResult} from 'utils/format-adjusted-result';

export const formatFleckviehResult = (
    key: keyof Pick<
        FleckviehMeasurementAnimal,
        'calculatedExtremities' | 'calculatedMusculature' | 'calculatedTotal' | 'calculatedUdder'
    >) => (value: number, row: FleckviehMeasurementAnimal): string => formatResult(value, row[key]);

export const formatHolsteinResult = (
    key: keyof Pick<
        HolsteinMeasurementAnimal,
        'calculatedBodyStructure' | 'calculatedExtremities' | 'calculatedLactationPerformance'
        | 'calculatedUdder' | 'calculatedOverallCondition'
    >) => (value: number, row: HolsteinMeasurementAnimal): string => formatResult(value, row[key]);
