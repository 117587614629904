import * as React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const districtRoutes = [
    (
        <Route
            exact
            path="/districts"
            component={Screens.List}
            key="districts"
        />
    ),
];
