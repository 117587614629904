export const selectBreedingFormName = 'selectBreeding';

export interface SelectBreedingFormValues {
    stableId: number | null;
    breedingId: number | null;
}

export const selectBreedingInitialValues: SelectBreedingFormValues = {
    stableId: null,
    breedingId: null,
};
