// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Codex, CodexSchema } from 'api/gen/Codex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CountryCode, CountryCodeSchema } from 'api/gen/CountryCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RatingType, RatingTypeSchema } from 'api/gen/RatingType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ShortEarTag, ShortEarTagSchema } from 'api/gen/ShortEarTag';

const requiredPart = t.interface({
  breedingId: BreedingNumberSchema,
  calveDate: DateSchema,
  codex: CodexSchema,
  countryCode: CountryCodeSchema,
  id: PositiveLongSchema,
  planId: PositiveLongSchema,
  ratingType: t.union([RatingTypeSchema, t.null]),
  sampleId: ShortEarTagSchema,
});

export const LegacyAnimalInPlanSchema = excess(requiredPart);

export interface LegacyAnimalInPlan extends t.TypeOf<typeof LegacyAnimalInPlanSchema> {}
