export const emailTranslations = {
    CS: {
        email: {
            sentEmails: 'Odeslaná pošta',
        },
        sentEmailsTable: {
            created: 'Čas odeslání',
            emailType: 'Typ e-mailu',
            emailList: 'E-mail',
            sendPlan: 'Plán',
            sendBreederSuite: 'Sestavy chovatelů',
            noSentEmails: 'Nebyly nalezeny žádné odeslané e-maily',
        },
    },
};
