import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as routerActions from 'redux-first-history';

import {districtAction, simpleDistrictSelector} from 'app/district';
import {useOurTranslation} from 'app/translations';
import {Content, PageHeader} from 'common/layout';

import {DaughtersTable} from '../components';

const DaughtersBase: FC = _ => {
    const {t} = useOurTranslation('bull/daughtersTable');
    const districts = useSelector(simpleDistrictSelector.districts);
    const dispatch = useDispatch();

    const goBack = useCallback(() => {
        dispatch(routerActions.goBack());
    }, [dispatch]);

    useEffect(() => {
        dispatch(districtAction.loadDistricts());
    }, [dispatch]);

    return (
        <Content>
            <PageHeader
                title={t('daughtersOverview')}
                onBackTooltip={t('bulls')}
                onBack={goBack}
            />
            <DaughtersTable districts={districts} />
        </Content>
    );
};

export const Daughters = memo(DaughtersBase);
