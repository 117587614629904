import {exportBreederSuitesSaga} from './export-breeder-suites';
import {getBreederSuitesSaga} from './get-breeder-suites-saga';
import {getEmailTemplateSaga} from './get-email-template';
import {sendMailToBreedersSaga} from './send-mail-to-breeders';

export const breederSuiteSagas = [
    getBreederSuitesSaga,
    exportBreederSuitesSaga,
    sendMailToBreedersSaga,
    getEmailTemplateSaga,
];
