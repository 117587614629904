/* eslint-disable import/no-unused-modules */
import {Opt} from 'ts-opt';

import {AnimalWithBreedingSearch} from 'api/gen/AnimalWithBreedingSearch';
import {FindAllApprovedByAnimalQueryParams, GetMeasurementByIdPathParams} from 'api/gen/Api';
import {MeasurementDetail} from 'api/gen/MeasurementDetail';
import {MeasurementSearch} from 'api/gen/MeasurementSearch';
import {MeasurementUpdate} from 'api/gen/MeasurementUpdate';

export const repairAction = {
    getAllApprovedMeasurements: (params: FindAllApprovedByAnimalQueryParams) => ({
        type: 'repair/GET_ALL_APPROVED_MEASUREMENTS',
        payload: {params},
    }) as const,
    getAllApprovedMeasurementsSuccess: (measurementSearch: Opt<Array<MeasurementSearch>>) => ({
        type: 'repair/GET_ALL_APPROVED_MEASUREMENTS_SUCCESS',
        payload: {measurementSearch},
    }) as const,
    getApprovedMeasurement: (params: GetMeasurementByIdPathParams) => ({
        type: 'repair/GET_APPROVED_MEASUREMENT',
        payload: {params},
    }) as const,
    getApprovedMeasurementSuccess: (measurement: Opt<MeasurementDetail>) => ({
        type: 'repair/GET_APPROVED_MEASUREMENT_SUCCESS',
        payload: {measurement},
    }) as const,
    updateApprovedMeasurement: (data: MeasurementUpdate, measurementId: number) => ({
        type: 'repair/UPDATE_APPROVED_MEASUREMENT',
        payload: {data, measurementId},
    }) as const,
    findAnimals: (earTag: string) => ({
        type: 'repair/FIND_ANIMALS',
        payload: {earTag},
    }) as const,
    findAnimalsSuccess: (animals: Array<AnimalWithBreedingSearch>) => ({
        type: 'repair/FIND_ANIMALS_SUCCESS',
        payload: {animals},
    }) as const,
};

export type GetAllApprovedMeasurementsAction = ReturnType<typeof repairAction.getAllApprovedMeasurements>;
export type GetAllApprovedMeasurementsSuccessAction = ReturnType<typeof repairAction.getAllApprovedMeasurementsSuccess>;
export type GetApprovedMeasurementAction = ReturnType<typeof repairAction.getApprovedMeasurement>;
export type GetApprovedMeasurementSuccessAction = ReturnType<typeof repairAction.getApprovedMeasurementSuccess>;
export type UpdateApprovedMeasurementAction =
    ReturnType<typeof repairAction.updateApprovedMeasurement>;
export type FindAnimalsAction = ReturnType<typeof repairAction.findAnimals>;
export type FindAnimalsSuccessAction = ReturnType<typeof repairAction.findAnimalsSuccess>;

export type RepairAction =
    | GetAllApprovedMeasurementsAction
    | GetAllApprovedMeasurementsSuccessAction
    | GetApprovedMeasurementAction
    | GetApprovedMeasurementSuccessAction
    | UpdateApprovedMeasurementAction
    | FindAnimalsAction
    | FindAnimalsSuccessAction
;
