import {isNull, memoize} from 'lodash/fp';
import React, {FC, memo} from 'react';
import {useSelector} from 'react-redux';

import {Breeder} from 'api/gen/Breeder';
import {useOurTranslation} from 'app/translations';
import {Content, LoaderWrapper, PageHeader, simpleLayoutSelector} from 'common/layout';

import {BreederForm} from '../components';
import {BreederStablesTable} from '../components/breeder-stable-table';
import {useGetDataForBreederDetail} from '../hooks';
import {simpleBreederSelector} from '../model';
import {UpdateBreederFormValues} from '../types/update-breeder-form-values';

const getInitialFormValues = memoize((breeder: Breeder): UpdateBreederFormValues => ({
    name: breeder.name,
    ico: breeder.cin,
    dic: breeder.vatNumber,
    email: breeder.email,
    phone: breeder.phone,
}));

const DetailBase: FC = _ => {
    const stables = useSelector(simpleBreederSelector.stables);
    const editedStableId = useSelector(simpleBreederSelector.editedStableId);
    const loadingBreeder = useSelector(simpleLayoutSelector.isItemLoading('breeder'));
    const loadingStables = useSelector(simpleLayoutSelector.isItemLoading('breederStables'));

    const {t} = useOurTranslation('breeder');
    const {
        breeder,
        goBack,
        goTo,
        saveBreeder,
        setBreederFormValues,
    } = useGetDataForBreederDetail();

    return (
        <div data-test-id="breeder-detail">
            <Content>
                <PageHeader
                    title={breeder?.name || ''}
                    onBackTooltip={t('companies')}
                    onBack={goBack}
                    smallerTitleOnPhone
                    loading={loadingBreeder}
                />

                <LoaderWrapper loading={loadingBreeder}>
                    <BreederForm
                        onSubmit={saveBreeder}
                        initialValues={breeder ? getInitialFormValues(breeder) : null}
                        setBreederFormValues={setBreederFormValues}
                    />
                </LoaderWrapper>
            </Content>

            <Content>
                <LoaderWrapper loading={loadingStables || isNull(breeder)}>

                    <BreederStablesTable
                        stables={stables}
                        editedStableId={editedStableId}
                        onGoTo={goTo}
                    />
                </LoaderWrapper>
            </Content>
        </div>
    );
};

export const Detail = memo(DetailBase);
