// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of an animal.
// Example: "TOMMY"

export const animalNameMinLength = 1;
export const animalNameMaxLength = 255;

export const AnimalNameSchema = t.refinement(t.string, n => n.length >= animalNameMinLength && n.length <= animalNameMaxLength, 'AnimalName');

export type AnimalName = string;
