import * as React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const bullRoutes = [
    (
        <Route
            exact
            path="/bulls"
            component={Screens.Bulls}
            key="bulls"
        />
    ),
    (
        <Route
            exact
            path="/bulls/daughters/:bullId"
            component={Screens.Daughters}
            key="daughters"
        />
    ),
    (
        <Route
            exact
            path="/invoicing-documents/daughters/:bullId"
            component={Screens.InvoicesDaughters}
            key="invoicesDaughters"
        />
    ),
    (
        <Route
            exact
            path="/invoicing-documents"
            component={Screens.Invoices}
            key="invoicesOverview"
        />
    ),
];
