// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederSuiteId, BreederSuiteIdSchema } from 'api/gen/BreederSuiteId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Email, EmailSchema } from 'api/gen/Email';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserCode, UserCodeSchema } from 'api/gen/UserCode';

const requiredPart = t.interface({
  breederName: BreederNameSchema,
  breedingNumber: BreedingNumberSchema,
  emailSentDate: t.union([DateSchema, t.null]),
  lactation: PositiveIntegerSchema,
  ratingDate: DateSchema,
  stableEmail: t.union([EmailSchema, t.null]),
  stableId: t.union([PositiveLongSchema, t.null]),
  stableName: StableNameSchema,
  suiteId: BreederSuiteIdSchema,
  sumOfAnimals: PositiveLongSchema,
  userCode: UserCodeSchema,
});

export const BreederSuiteSchema = excess(requiredPart);

export interface BreederSuite extends t.TypeOf<typeof BreederSuiteSchema> {}
