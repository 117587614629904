import {none, Opt} from 'ts-opt';

import {RatingWithUnknownBreeding} from 'api/gen/RatingWithUnknownBreeding';
import {StableWithBreedings} from 'api/gen/StableWithBreedings';

export interface UnknownAnimalState {
    animals: Array<RatingWithUnknownBreeding> | null;
    stables: Array<StableWithBreedings> | null;
    animalInEdit: Opt<RatingWithUnknownBreeding>;
}

export const initialUnknownAnimalState: UnknownAnimalState = {
    animals: null,
    stables: null,
    animalInEdit: none,
};
