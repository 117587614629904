// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Email, EmailSchema } from 'api/gen/Email';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PhoneNumber, PhoneNumberSchema } from 'api/gen/PhoneNumber';

const requiredPart = t.interface({
  email: t.union([EmailSchema, t.null]),
  note: t.union([t.string, t.null]),
  phone: t.union([PhoneNumberSchema, t.null]),
});

export const UpdateStableSchema = excess(requiredPart);

export interface UpdateStable extends t.TypeOf<typeof UpdateStableSchema> {}
