import {Opt} from 'ts-opt';

import {PageBullView} from 'api/gen/PageBullView';
import {PageInvoicingDocuments} from 'api/gen/PageInvoicingDocuments';
import {PagePlanningDaughter} from 'api/gen/PagePlanningDaughter';
import {PagePlanningDaughterWithMark} from 'api/gen/PagePlanningDaughterWithMark';
import {State} from 'app/setup';

export const simpleBullSelector = {
    bullInEdit: ({bull}: State): Opt<number> => bull.bullInEdit,
    bullsPage: ({bull}: State): Opt<PageBullView> => bull.bullsPage,
    invoicesPage: ({bull}: State): Opt<PageInvoicingDocuments> => bull.invoicesPage,
    selectedBullsIds: ({bull}: State): Array<number> => bull.selectedBullsIds,
    daughters: ({bull}: State): Opt<PagePlanningDaughterWithMark> => bull.daughters,
    invoicesDaughters: ({bull}: State): Opt<PagePlanningDaughter> => bull.invoicesDaughters,
};
