import React, {FC} from 'react';

import {BasicImport} from 'api/gen/BasicImport';
import {useOurTranslation} from 'app/translations';
import {Button} from 'common/buttons';
import {Modal} from 'common/layout';

import styles from './styles.sass';

interface Props {
    importResult: BasicImport | null;
    closeImportKkutResultModal(): void;
}

export const ImportKkutResultModal: FC<Props> = props => {
    const {importResult, closeImportKkutResultModal} = props;
    const {t, tCommon} = useOurTranslation('importKkut/modal');

    if (!importResult) {
        return null;
    }

    const footer = (
        <Button
            type="button"
            visuals="primary"
            onClick={closeImportKkutResultModal}
            testId="import-kkut-result-modal-close-button"
        >
            {tCommon('close')}
        </Button>
    );

    return (
        <Modal
            modalId="importKkutResult"
            title={t('result')}
            footer={footer}
            onCancel={closeImportKkutResultModal}
            testId="import-kkut-result"
        >
            <table
                className={styles.importResultTable}
                data-test-id="import-kkut-result-table"
            >
                <tbody>
                    <tr>
                        <td>{t('recordsInFile')}</td>
                        <td>{importResult.allRecords}</td>
                    </tr>
                    <tr>
                        <td>{t('importedRecords')}</td>
                        <td>{importResult.importedRecords}</td>
                    </tr>
                    <tr>
                        <td>{t('duplicateRecords')}</td>
                        <td>{importResult.duplicateRecords}</td>
                    </tr>
                </tbody>
            </table>
        </Modal>
    );
};
