export const measurementTranslations = {
    CS: {
        'measurement/detail': {
            animalMeasurements: 'Měření zvířete {{earTag}}',
        },
        'measurement/actionPanel': {
            approveMeasurements: 'Schválit hodnocení',
            ratedAnimals: 'Počet hodnocených zvířat',
            mortifiedAnimals: 'Počet umrtvených zvířat',
        },
        'measurement/list': {
            header: {
                dataCheck: 'Kontrola dat',
            },
            deadAnimal: 'Zvíře je mrtvé.',
            duplicateMeasurement: 'Kráva má více než jedno hodnocení',
            noMeasurements: 'Nebyla nalezena žádná měření ke schválení.',
        },
        'measurement/table': {
            type: 'Typ vytvoření',
            typeOptions: {
                isNewMobile: 'Nové (z PDA)',
                isNewWeb: 'Nové (z webu)',
                inPlan: 'V plánu',
                outsidePlan: 'Mimo plán',
            },
            noMeasurements: 'Nebyla nalezena žádná měření',
            info: 'Počet schválených hodnocení: {{sumOfApprovedMeasurements}}',
            noApprovedMeasurements: 'Zvíře nemá schválené žádné hodnocení',
            mortifyAnimal: 'Umrtvit zvíře',
            editRating: 'Úprava měření',
            assignAnimalToRating: 'Přiřadit měření zvířeti',
            editData: 'Upravit údaje',
            animalIsDead: 'Zvíře je mrtvé',
        },
        'measurement/messages': {
            approveMeasurementsTitle: 'Schválení měření',
            approveMeasurementsSuccess: 'Měření byla úspěšně schválena.',
            getMeasurementsSuccess: 'Načtení seznamu měření',
            findEarTags: 'Hledání ušních známek',
            deleteRatingTitle: 'Smazání měření',
            deleteRatingSuccess: 'Hodnocení bylo úspěšně odstraněno.',
            mortifyAnimalTitle: 'Umrtvení měření',
            assignRatingTitle: 'Přiřazení měření zvířeti',
            assignRatingSuccess: 'Měření úspěšně přiřazeno zvířeti s ušní známkou {{earTag}}',
            editMeasurementSuccess: 'Změny byly uloženy',
        },
        'meausurement/ratingOfAnimalTable': {
            bodyDepth: 'Střední hloubka těla',
        },
    },
};
