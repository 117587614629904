// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';

const requiredPart = t.interface({
  tag: t.literal('EQUALS_DECIMAL'),
  field: t.string,
  value: t.number,
});

export const EqualsDecimalFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface EqualsDecimalFilter extends t.TypeOf<typeof EqualsDecimalFilterSchema> {}
