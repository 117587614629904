// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FleckviehMeasurementDetail, FleckviehMeasurementDetailSchema } from 'api/gen/FleckviehMeasurementDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinMeasurementDetail, HolsteinMeasurementDetailSchema } from 'api/gen/HolsteinMeasurementDetail';

export const MeasurementDetailSchema = t.union([FleckviehMeasurementDetailSchema, HolsteinMeasurementDetailSchema]);

export type MeasurementDetail = t.TypeOf<typeof MeasurementDetailSchema>;
