import {
    putAll,
    showBeError,
    showError,
    showSuccess,
    extractFormErrorsFromResponse,
    takeLatestF,
} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout/model';
import {formHelpers} from 'utils/forms';

import {SendPlanBreederEmailAction} from '../action';
import {simplePlanningSelector} from '../selector';

const te = t('planning/messages');

function* execute({payload: {data}}: SendPlanBreederEmailAction): SagaIterator {
    const {content, emailsTo, emailsToSendCopyTo} = data;
    const breederId = opt(yield* select(simplePlanningSelector.planEmailModalBreederId))
        .orCrash('missing breeder id');

    yield* put(layoutAction.setItemLoading('sendPlanBreederEmail', true));

    const title = te('stablePlanSendTitle');

    const response = yield* call(
        Api.sendPlanEmailForBreeder,
        {content, emailsTo, emailsToSendCopyTo},
        {breederId},
    );

    if (!response.isSuccess) {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit('planEmail', extractFormErrorsFromResponse(response)));
        yield* put(showError(title, te('stablePlanSendError')));
    } else {
        yield* put(showSuccess(title, te('stablePlanSendSuccess')));
        yield* put(layoutAction.setModalVisibility('planBreederEmail', false));
        yield* put(formHelpers.reset('planEmail'));
    }

    yield* put(layoutAction.setItemLoading('sendPlanBreederEmail', false));
}

export function* sendPlanBreederEmailSaga(): SagaIterator {
    yield takeLatestF('planning/SEND_PLAN_BREEDER_EMAIL', execute);
}
