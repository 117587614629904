import {Gutter} from 'antd/lib/grid/row';

export const calveDaysWarningTreshold = 170;
export const earTagWithoutCountryMaxLength = 12;

export const homePagePath = '/';
export const loginPagePath = '/login';
export const logoutPagePath = '/logout';

export const gutter = 8;
const horizontalGutter = gutter + gutter;
const verticalGutter = gutter;
export const buttonsGutter: [Gutter, Gutter] = [horizontalGutter, verticalGutter];
