import {createInvoicingDocumentsSaga} from './create-invoicing-documents-saga';
import {editBullSaga} from './edit-bull-saga';
import {exportBullDaughtersSaga} from './export-bull-daughters-saga';
import {exportBullMarkedDaughtersSaga} from './export-bull-marked-daughters-saga';
import {getBullAnimalsSaga} from './get-bull-animals-saga';
import {getBullsSaga} from './get-bulls-saga';
import {getInvokesDaughtersSaga} from './get-invoces-doughter-saga';
import {getInvoicesSaga} from './get-invoices-saga';
import {getInvoicingDocumentsSaga} from './get-invoicing-document-saga';

export const bullSagas = [
    getBullsSaga,
    getBullAnimalsSaga,
    editBullSaga,
    exportBullDaughtersSaga,
    createInvoicingDocumentsSaga,
    getInvoicingDocumentsSaga,
    getInvoicesSaga,
    exportBullMarkedDaughtersSaga,
    getInvokesDaughtersSaga,
];
