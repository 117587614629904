import {forkRestartingOnError, showError} from '@fl/cmsch-fe-library';
import {each, flow, isFunction, map, tap} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {ForkEffect} from 'redux-saga/effects';
import {all, put} from 'typed-redux-saga';

import {adminSagas} from 'app/admin/model/sagas';
import {breederSagas} from 'app/breeder';
import {breederSuiteSagas} from 'app/breeder-suite';
import {breedingSagas} from 'app/breeding';
import {bullSagas} from 'app/bull';
import {Config} from 'app/config';
import {databaseOfMeasuredSagas} from 'app/database-of-measured/model';
import {districtSagas} from 'app/district';
import {emailSagas} from 'app/email';
import {importKkutSagas} from 'app/import-kkut';
import {measurementSagas} from 'app/measurement';
import {planningSagas} from 'app/planning';
import {repairSagas} from 'app/repair';
import {logger} from 'app/sentry-logger';
import {t} from 'app/translations';
import {unknownAnimalSagas} from 'app/unknown-animal';
import {userSagas} from 'app/user';
import {tablesSagas} from 'utils/tables';

type Saga = () => SagaIterator;

const tt = t('common/error');

function* handleSagaError(sagaName: string, error: Error): SagaIterator {
    logger.logError(error);

    if (!Config.isProduction && !Config.isTest) {
        yield* put(showError(tt('unexpectedError'), tt('unexpectedErrorOccurred')));
    } else {
        yield* put(showError(tt('internalSagaError'), tt('errorOccurredIn', {sagaName})));
    }
}

const validateSaga = (saga: unknown): void => {
    if (!isFunction(saga)) throw new Error(`The key ${JSON.stringify(saga)} is not a saga.`);
};

const process: (sagas: Array<Saga>) => Array<ForkEffect> = flow([
    tap(each(validateSaga)),
    map((saga: Saga) => forkRestartingOnError(saga, handleSagaError)),
]);

const sagas: Array<Saga> = [
    ...userSagas,
    ...districtSagas,
    ...importKkutSagas,
    ...breedingSagas,
    ...bullSagas,
    ...planningSagas,
    ...measurementSagas,
    ...breederSagas,
    ...unknownAnimalSagas,
    ...breederSuiteSagas,
    ...databaseOfMeasuredSagas,
    ...tablesSagas,
    ...repairSagas,
    ...adminSagas,
    ...emailSagas,
];

export default function*(): SagaIterator {
    yield* all(process(sagas));
}
