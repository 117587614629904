import React, {FC, memo} from 'react';

import {useOurTranslation} from 'app/translations';
import {FooterButtons} from 'common/buttons';
import {useForm} from 'utils/forms';

import {bullFormName, EditBullFormValues, initialEditBullValues} from '../../types/edit-bull-form-values';
import {validate} from './validate';

interface Props {
    onSubmit(bull: EditBullFormValues): void;
    closeModal(): void;
}

const BullFormBase: FC<Props> = props => {
    const {onSubmit, closeModal} = props;

    const {t, tCommon} = useOurTranslation('bull/bullForm');

    const {Fields, Form, submitting} = useForm({
        form: bullFormName,
        initialValues: initialEditBullValues,
        onSubmit,
        validate,
    });

    return (
        <Form>
            <Fields.Input
                name="name"
                type="text"
                label={tCommon('name')}
            />
            <Fields.Input
                name="note"
                label={t('note')}
                type="text"
            />
            <Fields.Checkbox
                name="marked"
                label={t('marked')}
            />
            <FooterButtons
                onCancel={closeModal}
                submitting={submitting}
            />
        </Form>
    );
};

export const BullForm = memo(BullFormBase);
