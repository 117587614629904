import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {GetUnknownAnimalsAction, unknownAnimalAction} from '../action';

function* execute(_: GetUnknownAnimalsAction): SagaIterator {
    const response = yield* call(Api.findRatingsWithUnknownBreedings);

    if (response.isSuccess) {
        yield* put(unknownAnimalAction.getUnknownAnimalsResult(response.data));
    } else {
        yield putAll(showBeError(response, t('unknownAnimals')('unassignedAnimals')));
    }
}

export function* getUnassignedRatings(): SagaIterator {
    yield takeLatestF('unknownAnimals/GET_UNASSIGNED_ANIMALS', execute);
}
