export const userTranslations = {
    CS: {
        'user/login': {
            username: 'Uživatelské jméno',
            password: 'Heslo',
            logIn: 'Přihlásit',
            logingOut: 'Odhlašování...',
            isUserLoading: 'Načítání uživatele...',
        },
        'user/messages': {
            loadingUsersTitle: 'Načtení všech uživatelů',
            loadingUserTitle: 'Načtení uživatele',
            logInTitle: 'Přihlášení',
            logOutTitle: 'Odhlášení',
            logOutMessage: 'Nečekáná chyba při odhlášení.',
            initializingUser: 'Inicializace uživatele',
            noPermissionMessage: 'Nemáte oprávnění pro zobrazení této stránky.',
            loginAsAnother: 'Přihlásit se za jiného uživatele',
            goBack: 'Zpět',
            notLoggedIn: 'Nejste přihlášen.',
            login: 'Přihlásit se',
            somethingWrong: 'Něco se pokazilo',
            notFound: 'Stránka, kterou hledáte, neexistuje.',
            backHome: 'Zpět na hlavní stránku',
        },
    },
};
