// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of a stable.
// Example: "FARMA KOJETIN"

export const stableNameMinLength = 1;
export const stableNameMaxLength = 255;

export const StableNameSchema = t.refinement(t.string, n => n.length >= stableNameMinLength && n.length <= stableNameMaxLength, 'StableName');

export type StableName = string;
