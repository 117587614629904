import React, {FC, memo, PropsWithChildren, ReactNode, useMemo} from 'react';

import {AntLayout} from 'common/ant';

import styles from './styles.sass';

interface Props {
    header?: ReactNode;
}

const AppLayoutBase: FC<PropsWithChildren<Props>> = ({children, header}) => {
    const memoizedHeader = useMemo(() => (
        <AntLayout.Header className="p-0">
            {header}
        </AntLayout.Header>
    ), [header]);

    return (
        <AntLayout
            className={styles.menuAppWrapperLayout}
            hasSider={false}
        >
            {header && memoizedHeader}
            {children}
        </AntLayout>
    );
};

export const AppLayout = memo(AppLayoutBase);
