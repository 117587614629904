import {ActionSettings} from '@fl/cmsch-fe-library';
import {useCallback, useMemo} from 'react';

import {InvoicingDocuments} from 'api/gen/InvoicingDocuments';
import {useOurTranslation} from 'app/translations';
import {LoadingItemId} from 'common/layout/types/loading-item-id';

interface UseGetActionSettings {
    selectedBullsIds: Array<number>;
    loadingItems: Array<LoadingItemId>;
    toggleSelectBulls(ids: Array<number>): void;
    exportBullDaughters(bullId: number): void;
    getInvoicingDocuments(bullId: number): void;
    goTo(path: string): void;
}

// eslint-disable-next-line max-lines-per-function
export const useGetActionSettings = ({
    selectedBullsIds,
    loadingItems,
    toggleSelectBulls,
    exportBullDaughters,
    getInvoicingDocuments,
    goTo,
}: UseGetActionSettings): ActionSettings<InvoicingDocuments> => {
    const {t, tCommon} = useOurTranslation('bull/invoicesTable');

    const getExportBillingBasisDisabled = useCallback((_rowId: string | number, record: InvoicingDocuments) =>
        !record.areInvoicingDocumentsCreated || loadingItems.includes('exportInvoicingDocuments')
    , [loadingItems]);

    const goToDaughters = useCallback((bullId: number) => {
        goTo(`/invoicing-documents/daughters/${bullId}`);
    }, [goTo]);

    const isExportBullDaughtersDisabled = useCallback((_rowId: number, record: InvoicingDocuments) =>
        loadingItems.includes('exportBullDaughters') || record.numOfMeasuredDaughters === 0
    , [loadingItems]);

    const getExportBullDaughtersDisabledTitle = useCallback((_rowId: number, record: InvoicingDocuments) =>
        record.numOfMeasuredDaughters === 0 ? t('exportBullDaughtersIsDisabled') : tCommon('actionPending')
    , [t, tCommon]);

    const isDaughtersOverviewDisabled = useCallback((_rowId: number, bull: InvoicingDocuments) =>
        bull.numOfDaughtersToMeasure === 0
    , []);

    // eslint-disable-next-line max-lines-per-function
    return useMemo((): ActionSettings<InvoicingDocuments> => ({
        selectedIds: selectedBullsIds,
        onSelectedIdsChange: toggleSelectBulls,
        isSelectDisabled: (_rowId: string | number, record: InvoicingDocuments): boolean =>
            Boolean(record.areInvoicingDocumentsCreated),
        selectTitle: (_rowId: string | number, record: InvoicingDocuments): string | undefined =>
            record.areInvoicingDocumentsCreated ? t('bullWasAlreadyBilled') : undefined,
        extraActions: [
            {
                id: 'exportBullDaughters',
                icon: 'downloadOutlined',
                title: t('exportDaughters'),
                callback: exportBullDaughters,
                disabled: isExportBullDaughtersDisabled,
                disabledTitle: getExportBullDaughtersDisabledTitle,
                testId: 'export-bull-daughters-button',
            },
            {
                id: 'exportInvoicingDocuments',
                icon: 'fileTextOutlined',
                title: t('invoicingDocumentsExists'),
                callback: getInvoicingDocuments,
                disabled: getExportBillingBasisDisabled,
                disabledTitle: t('bullHasNoInvoicingDocuments'),
                testId: 'export-invoices-button',
            },
            {
                id: 'daughtersOverview',
                icon: 'zoomInOutlined',
                title: t('daughtersOverview'),
                callback: goToDaughters,
                testId: 'daughters-overview-button',
                disabled: isDaughtersOverviewDisabled,
            },
        ],
    }), [
        selectedBullsIds,
        exportBullDaughters,
        getInvoicingDocuments,
        getExportBillingBasisDisabled,
        getExportBullDaughtersDisabledTitle,
        goToDaughters,
        isDaughtersOverviewDisabled,
        isExportBullDaughtersDisabled,
        t,
        toggleSelectBulls,
    ]);
};
