// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  tag: t.string,
});

export const AbstractTableFilterSchema = excess(requiredPart);

export interface AbstractTableFilter extends t.TypeOf<typeof AbstractTableFilterSchema> {}
