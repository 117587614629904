// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { MobileAppName, MobileAppNameSchema } from 'api/gen/MobileAppName';

const requiredPart = t.interface({
  names: t.array(MobileAppNameSchema),
});

export const MobileAppsSchema = excess(requiredPart);

export interface MobileApps extends t.TypeOf<typeof MobileAppsSchema> {}
