import {Nullable} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {EmailTemplate} from 'api/gen/EmailTemplate';
import {Plan} from 'api/gen/Plan';
import {PlanStatistics} from 'api/gen/PlanStatistics';
import {State} from 'app/setup';

export const simplePlanningSelector = {
    plan: ({planning}: State): Nullable<Plan> => planning.plan,
    adminPlans: ({planning}: State): Nullable<Array<PlanStatistics>> => planning.adminPlans,
    selectedAnimals: ({planning}: State): Array<number> => planning.selectedAnimals,
    selectedCompanies: ({planning}: State): Array<number> => planning.selectedCompanies,
    planEmailModalStableId: ({planning}: State): Nullable<number> => planning.planEmailModalStableId,
    planEmailModalBreederId: ({planning}: State): Nullable<number> => planning.planEmailModalBreederId,
    emailTemplate: ({planning}: State): Opt<EmailTemplate> => planning.emailTemplate,
};
