// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-app-user-screens-___styles__login___vv3Va{width:20em;margin-bottom:80px}@media(max-width: 767px){.src-app-user-screens-___styles__login___vv3Va{width:98%}}", "",{"version":3,"sources":["webpack://./src/app/user/screens/styles.sass"],"names":[],"mappings":"AAEA,+CACI,UAAA,CACA,kBAAA,CAEJ,yBACI,+CACI,SAAA,CAAA","sourcesContent":["@import '../../../styles/variables'\n\n.login\n    width: 20em\n    margin-bottom: 80px\n\n@media (max-width: $screen-md - 1)\n    .login\n        width: 98%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "src-app-user-screens-___styles__login___vv3Va"
};
export default ___CSS_LOADER_EXPORT___;
