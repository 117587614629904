import {TableDataRequest} from '@fl/cmsch-fe-library';
import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {Opt} from 'ts-opt';

import {InvoicingDocuments} from 'api/gen/InvoicingDocuments';
import {simpleLayoutSelector} from 'common/layout';
import {LoadingItemId} from 'common/layout/types/loading-item-id';

import {bullAction, simpleBullSelector} from '../model';

interface UseGetDataForInvoices {
    loadingItems: Array<LoadingItemId>;
    selectedBullsIds: Array<number>;
    goTo(path: string): void;
    exportBullMarkedDaughters(bullId: number): void;
    toggleSelectBulls(ids: Array<number>): void;
    getInvoicingDocuments(bullId: number): void;
    getInvoices(tableDataRequest: Opt<TableDataRequest<InvoicingDocuments>>): void;
    createInvoicingDocuments(): void;
}

// eslint-disable-next-line max-lines-per-function
export const useGetDataForInvoices = (): UseGetDataForInvoices => {
    const dispatch = useDispatch();

    const loadingItems = useSelector(simpleLayoutSelector.loadingItems);
    const selectedBullsIds = useSelector(simpleBullSelector.selectedBullsIds);

    const getInvoices = useCallback((tableDataRequest: Opt<TableDataRequest<InvoicingDocuments>>) => {
        dispatch(bullAction.getInvoices(tableDataRequest));
    }, [dispatch]);

    const exportBullMarkedDaughters = useCallback((bullId: number) => {
        dispatch(bullAction.exportBullMarkedDaughters(bullId));
    }, [dispatch]);

    const toggleSelectBulls = useCallback((bullIds: Array<number>) => {
        dispatch(bullAction.toggleSelectBulls(bullIds));
    }, [dispatch]);

    const getInvoicingDocuments = useCallback((bullId: number) => {
        dispatch(bullAction.getInvoicingDocuments(bullId));
    }, [dispatch]);

    const createInvoicingDocuments = useCallback(() => {
        dispatch(bullAction.createInvoicingDocuments(selectedBullsIds));
        toggleSelectBulls([]);
    }, [dispatch, selectedBullsIds, toggleSelectBulls]);

    const goTo = useCallback((path: string) => {
        dispatch(routerActions.push(path));
    }, [dispatch]);

    return {
        loadingItems,
        selectedBullsIds,
        exportBullMarkedDaughters,
        getInvoices,
        getInvoicingDocuments,
        createInvoicingDocuments,
        toggleSelectBulls,
        goTo,
    };
};
