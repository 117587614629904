// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedAlpha6, BreedAlpha6Schema } from 'api/gen/BreedAlpha6';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CalveDays, CalveDaysSchema } from 'api/gen/CalveDays';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CalveMonths, CalveMonthsSchema } from 'api/gen/CalveMonths';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Codex, CodexSchema } from 'api/gen/Codex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ProficiencyTest, ProficiencyTestSchema } from 'api/gen/ProficiencyTest';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ShortEarTag, ShortEarTagSchema } from 'api/gen/ShortEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  breed: t.union([BreedAlpha6Schema, t.null]),
  breederName: BreederNameSchema,
  breedingId: PositiveLongSchema,
  breedingNumber: BreedingNumberSchema,
  calveDate: t.union([DateSchema, t.null]),
  codex: CodexSchema,
  daysSinceCalveDate: t.union([PositiveOrZeroIntegerSchema, t.null]),
  fathersLinReg: t.union([LineRegistrySchema, t.null]),
  fathersName: t.union([AnimalNameSchema, t.null]),
  firstCalveDays: t.union([CalveDaysSchema, t.null]),
  firstCalveMonths: t.union([CalveMonthsSchema, t.null]),
  id: PositiveLongSchema,
  marked: t.boolean,
  proficiencyTest: t.union([ProficiencyTestSchema, t.null]),
  sampleIdWithoutCodex: ShortEarTagSchema,
  stableName: StableNameSchema,
});

export const BasicAnimalSchema = excess(requiredPart);

export interface BasicAnimal extends t.TypeOf<typeof BasicAnimalSchema> {}
