import {FleckviehMeasurementView} from 'api/gen/FleckviehMeasurementView';
import {HolsteinMeasurementView} from 'api/gen/HolsteinMeasurementView';
import {formatResult} from 'utils/format-adjusted-result';

type FleckviehMeasurementKey = keyof Pick<
    FleckviehMeasurementView,
    'calculatedExtremities' | 'calculatedMusculature' | 'calculatedTotal' | 'calculatedUdder'
>;

type HolsteinMeasurementKey = keyof Pick<
    HolsteinMeasurementView,
    'calculatedBodyStructure' | 'calculatedExtremities' | 'calculatedLactationPerformance'
    | 'calculatedUdder' | 'calculatedOverallCondition'
>;

export const formatFleckviehResult = (key: FleckviehMeasurementKey) =>
    (value: number, row: FleckviehMeasurementView): string => formatResult(value, row[key]);

export const formatHolsteinResult = (key: HolsteinMeasurementKey) =>
    (value: number, row: HolsteinMeasurementView): string => formatResult(value, row[key]);
