import {putAll, showBeError, downloadFileFromResponse, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {PrintPlanAction} from '../action';

function* execute(_: PrintPlanAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('printPlan', true));

    const response = yield* call(Api.exportPlanToPdf);

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('planning/messages')('printError')));
    } else {
        yield* call(downloadFileFromResponse, response, t('planning')('defaultPrintFilename'));
    }

    yield* put(layoutAction.setItemLoading('printPlan', false));
}

export function* printPlanSaga(): SagaIterator {
    yield takeLatestF('planning/PRINT_PLAN', execute);
}
