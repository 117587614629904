// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A name of a mobile app APK file.
// Example: "app-holstyn-2.3.0-test.apk"

export const mobileAppNameRegexGen = (): RegExp => new RegExp("^app-[a-z]+-[\\w.-]+.apk$");

export const MobileAppNameSchema = StringPatternSchema<MobileAppName>(mobileAppNameRegexGen());

export type MobileAppName = string;
