import React, {FC, memo} from 'react';
import {useSelector} from 'react-redux';

import {AssignToBreedingModal, UnassignedAnimalsSection} from 'app/unknown-animal/components';
import {simpleUnknownAnimalSelector} from 'app/unknown-animal/model/selector';
import {simpleUserSelector} from 'app/user';
import {Content, simpleLayoutSelector} from 'common/layout';

import {EditRatingModal, MeasurementsSection} from '../components';
import {useGetDataForMeasurementsList} from '../hooks';
import {foundEarTagsSelector, simpleMeasurementSelector} from '../model';

const ListBase: FC = _ => {
    const tenant = useSelector(simpleUserSelector.tenant);
    const animals = useSelector(simpleUnknownAnimalSelector.animals);
    const stables = useSelector(simpleUnknownAnimalSelector.stables);
    const selectBreedingValues = useSelector(simpleUnknownAnimalSelector.selectBreedingValues);
    const foundEarTags = useSelector(foundEarTagsSelector);
    const ratingInEdit = useSelector(simpleMeasurementSelector.ratingInEdit);
    const loadingItems = useSelector(simpleLayoutSelector.loadingItems);
    const editedRating = useSelector(simpleMeasurementSelector.editedRating);
    const ratingsCount = useSelector(simpleMeasurementSelector.ratingsCount);
    const ratingStats = useSelector(simpleMeasurementSelector.ratingStats);

    const {
        approveMeasurements,
        assignRatingToBreeding,
        assignAnimalToRating,
        closeAssignToBreedingModal,
        closeEditRatingModal,
        deleteMeasurement,
        findEarTags,
        mortifyAnimal,
        openAssignToBreedingModal,
        openEditRatingModal,
        setRatingFormValues,
        updateRating,
    } = useGetDataForMeasurementsList();

    return (
        <Content>
            <UnassignedAnimalsSection
                animals={animals}
                editedRating={editedRating}
                onAssignAnimalToBreeding={openAssignToBreedingModal}
                tenant={tenant}
            />
            <MeasurementsSection
                loadingItems={loadingItems}
                ratingCount={ratingsCount}
                animalsinRatingsCount={ratingStats.prop('numOfMeasurements')}
                deadAnimalsCount={ratingStats.prop('numOfMortifiedAnimals')}
                approveRatings={approveMeasurements}
                deleteRating={deleteMeasurement}
                mortifyAnimal={mortifyAnimal}
                editRating={openEditRatingModal}
            />
            <AssignToBreedingModal
                stables={stables}
                selectBreedingValues={selectBreedingValues}
                findEarTagsInProgress={loadingItems.includes('findEarTags')}
                assignRatingToBreeding={assignRatingToBreeding}
                closeModal={closeAssignToBreedingModal}
            />
            <EditRatingModal
                foundEarTags={foundEarTags}
                rating={ratingInEdit}
                assignAnimalToRating={assignAnimalToRating}
                closeModal={closeEditRatingModal}
                findEarTags={findEarTags}
                updateRating={updateRating}
                setRatingFormValues={setRatingFormValues}
            />
        </Content>
    );
};

export const List = memo(ListBase);
