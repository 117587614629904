// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BullNote, BullNoteSchema } from 'api/gen/BullNote';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectName, SubjectNameSchema } from 'api/gen/SubjectName';

const requiredPart = t.interface({
  areInvoicingDocumentsCreated: t.boolean,
  id: PositiveLongSchema,
  invoicingDate: t.union([DateSchema, t.null]),
  lineRegistry: LineRegistrySchema,
  marked: t.boolean,
  name: t.union([AnimalNameSchema, t.null]),
  note: t.union([BullNoteSchema, t.null]),
  numOfDaughtersToMeasure: PositiveOrZeroLongSchema,
  numOfMeasuredBreedings: PositiveOrZeroLongSchema,
  numOfMeasuredDaughters: PositiveOrZeroLongSchema,
  registrarName: t.union([SubjectNameSchema, t.null]),
});

export const BullViewSchema = excess(requiredPart);

export interface BullView extends t.TypeOf<typeof BullViewSchema> {}
