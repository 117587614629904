import {Nullable} from '@fl/cmsch-fe-library';

import {District} from 'api/gen/District';
import {State} from 'app/setup';

export const simpleDistrictSelector = {
    activeDistricts: ({district}: State): Nullable<Array<District>> => district.activeDistricts,
    districts: ({district}: State): Array<District> | null => district.districts,
    selectedDistricts: ({district}: State): Nullable<Array<District>> => district.selectedDistricts,
};
