import {downloadFileFromResponse, putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {bullAction, CreateInvoicingDocumentsAction} from '../action';

const defaultPrintFilename = 'podklady_k_fakturaci.csv';

function* execute(action: CreateInvoicingDocumentsAction): SagaIterator {
    const {bullIds} = action.payload;

    yield* put(layoutAction.setItemLoading('createInvoicingDocuments', true));

    const response = yield* call(Api.createInvoicingDocuments, bullIds);

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response, defaultPrintFilename);
        yield* put(bullAction.getInvoices(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, t('bull/sagas')('createInvoicingDocuments')));
    }

    yield* put(layoutAction.setItemLoading('createInvoicingDocuments', false));
}

export function* createInvoicingDocumentsSaga(): SagaIterator {
    yield takeLatestF('bull/CREATE_INVOICING_DOCUMENTS', execute);
}
