import {TableColumn} from '@fl/cmsch-fe-library';

import {MeasurementSearch} from 'api/gen/MeasurementSearch';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (tCommon: TFunction<'common'>): Array<TableColumn<MeasurementSearch>> => [
    {
        field: 'breedingNumber',
        column: tCommon('breeding'),
        type: 'string',
        disableFilter: true,
        width: 80,
    },
    {
        field: 'stableName',
        column: tCommon('stable'),
        type: 'string',
        disableFilter: true,
        width: 80,
    },
    {
        field: 'ratingDate',
        column: tCommon('ratingDate'),
        type: 'date',
        dateFormat: 'date',
        disableFilter: true,
        width: 162,
    },
    {
        field: 'userCode',
        column: tCommon('userCode'),
        type: 'string',
        align: 'right',
        disableFilter: true,
        width: 92,
    },
    {
        field: 'lactation',
        column: tCommon('lactation'),
        type: 'number',
        disableFilter: true,
        width: 92,
    },
];
