import React, {FC, memo} from 'react';

import {useOurTranslation} from 'app/translations';
import {Content, PageHeader} from 'common/layout';

import {SentEmailsTable} from '../components';

const SentEmailsBase: FC = () => {
    const {t} = useOurTranslation('email');

    return (
        <Content>
            <PageHeader title={t('sentEmails')} />
            <SentEmailsTable />
        </Content>
    );
};

export const SentEmails = memo(SentEmailsBase);
