import {toggleItem} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {useOurTranslation} from 'app/translations';
import {Content, LoaderWrapper, PageHeader, simpleLayoutSelector} from 'common/layout';

import {SaveButtonPanel, DistrictList} from '../components';
import {districtAction, simpleDistrictSelector} from '../model';

const ListBase: FC = _ => {
    const districts = useSelector(simpleDistrictSelector.districts);
    const selectedDistricts = useSelector(simpleDistrictSelector.selectedDistricts);
    const updateDistrictInProgress = useSelector(simpleLayoutSelector.isItemLoading('updateUserDistricts'));
    const loading = useSelector(simpleLayoutSelector.isItemLoading('districts'));

    const {t} = useOurTranslation('district');
    const dispatch = useDispatch();

    const selectedDistrictIds = useMemo((): Array<number> =>
        selectedDistricts?.map(d => d.id) ?? [],
    [selectedDistricts]);

    const saveSelectedDistricts = useCallback(() => {
        if (selectedDistricts) dispatch(districtAction.updateUserDistricts(selectedDistricts));
    }, [dispatch, selectedDistricts]);

    const toggleSelectDistrict = useCallback((districtId: number) => {
        const nextSelectedIds = toggleItem(districtId)(selectedDistrictIds);
        const nextSelectedDistricts = (districts || []).filter(d => nextSelectedIds.includes(d.id));

        dispatch(districtAction.updateSelectedDistricts(nextSelectedDistricts));
    }, [dispatch, districts, selectedDistrictIds]);

    useEffect(() => {
        dispatch(districtAction.loadDistricts());
    }, [dispatch]);

    return (
        <Content>
            <PageHeader title={t('districts')} />

            <LoaderWrapper loading={loading}>
                <SaveButtonPanel
                    selectedDistricts={selectedDistricts}
                    updateDistrictInProgress={updateDistrictInProgress}
                    saveSelectedDistricts={saveSelectedDistricts}
                />
                <DistrictList
                    districts={districts}
                    toggleSelectDistrict={toggleSelectDistrict}
                    selectedIds={selectedDistrictIds}
                />
            </LoaderWrapper>
        </Content>
    );
};

export const List = memo(ListBase);
