import {none, Opt} from 'ts-opt';

import {UserDetails} from 'api/gen/UserDetails';

export interface UserState {
    currentUser: UserDetails | null;
    isUserLoading: boolean;
    isUserInitialized: boolean;
    allUsers: Array<UserDetails> | null;
    previousPathname: Opt<string>;
    currentPathname: Opt<string>;
    isServerError: boolean;
}

export const initialUserState: UserState = {
    currentUser: null,
    isUserLoading: false,
    isUserInitialized: false,
    allUsers: null,
    previousPathname: none,
    currentPathname: none,
    isServerError: false,
};
