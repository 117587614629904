import {takeLatestF, putAll, showBeError, downloadFileFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {ExportBreederSuitesAction} from '../action';

function* execute({payload: {ids}}: ExportBreederSuitesAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('exportBreederSuites', true));

    const response = yield* call(Api.exportAllBreederSuites, {breederSuiteIds: ids});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('breederSuites/messages')('exportBreederSuitesTitle')));
    }

    yield* put(layoutAction.setItemLoading('exportBreederSuites', false));
}

export function* exportBreederSuitesSaga(): SagaIterator {
    yield takeLatestF('breederSuite/EXPORT_BREEDER_SUITES', execute);
}
