export const adminTranslations = {
    CS: {
        'admin/messages': {
            getAppInfo: 'Načtení informací o aplikaci',
            getMobileApps: 'Načtení mobilních aplikací',
        },
        'admin/AppInfo': {
            title: 'O aplikaci',
            frontend: 'Frontend',
            backend: 'Backend',
            library: 'Knihovna',
            deployment: 'Nasazení',
            deployDate: 'Datum nasazení',
            version: 'Verze',
            dbVersion: 'Verze databáze',
            noData: 'Žádná data',
            mobileApplications: 'Mobilní aplikace',
            download: 'Stáhnout',
            downloadMobileApp: 'Stažení mobilní aplikace',
        },
    },
};
