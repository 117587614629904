import {showBeError, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {
    convertTableDataParamsToDto,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {bullAction, GetBullsAction} from '../action';

function* execute({payload: {tableDataRequest}}: GetBullsAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(bullAction.getBullsSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('bull'));

    const tableState = yield* select(simpleTableSelector.tableState('bull'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'bull',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    },
    );

    const {filters, params} = convertTableDataParamsToDto({
        tableDataParams,
        fulltextFilterFields: ['name', 'lineRegistry'],
    });

    const response = yield* call(Api.findAllBulls, filters, params);

    if (response.isSuccess) {
        yield* put(bullAction.getBullsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('bull', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('bull/sagas')('getBulls')));
    }
}

export function* getBullsSaga(): SagaIterator {
    yield takeLatestF('bull/GET_BULLS', execute);
}
