import React, {FC, memo, useEffect} from 'react';

import {useOurTranslation} from 'app/translations';
import {FooterButtons} from 'common/buttons';
import {LoaderWrapper, Panel} from 'common/layout';
import {useForm} from 'utils/forms';

import {
    breederFormName,
    UpdateBreederFormValues,
    updateBreederInitialValues,
} from '../../types/update-breeder-form-values';
import {validate} from './validate';

interface Props {
    initialValues: UpdateBreederFormValues | null;
    onSubmit(): void;
    setBreederFormValues(breeder: UpdateBreederFormValues): void;
}

const BreederFormBase: FC<Props> = props => {
    const {initialValues, onSubmit, setBreederFormValues} = props;

    const {t, tCommon} = useOurTranslation('breeder');

    useEffect(() => {
        if (initialValues) {
            setBreederFormValues(initialValues);
        }
    }, [initialValues, setBreederFormValues]);

    const {Fields, Form, submitting} = useForm({
        form: breederFormName,
        initialValues: updateBreederInitialValues,
        onSubmit,
        validate,
    });

    return (
        <Panel>
            <LoaderWrapper loading={submitting || !initialValues}>
                <Form>
                    <Fields.Show
                        name="name"
                        label={t('name')}
                        showType="string"
                        biggerLabel
                    />
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <Fields.Input
                                name="ico"
                                type="text"
                                label={t('ico')}
                                biggerLabel
                            />
                        </div>
                        <div className="col-md-6">
                            <Fields.Show
                                name="dic"
                                label={t('dic')}
                                showType="string"
                                hideEmpty
                                biggerLabel
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Fields.Input
                                name="phone"
                                type="text"
                                label={t('phone')}
                                biggerLabel
                            />
                        </div>
                        <div className="col-md-6">
                            <Fields.Input
                                name="email"
                                type="text"
                                label={t('emailNotForPlanning')}
                                placeholder={tCommon('email')}
                                biggerLabel
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <FooterButtons
                            withoutCancel
                            submitting={submitting}
                        />
                    </div>
                </Form>
            </LoaderWrapper>
        </Panel>
    );
};

export const BreederForm = memo(BreederFormBase);
