import React, {FC, memo, useEffect} from 'react';

import {useOurTranslation} from 'app/translations';
import {FooterButtons} from 'common/buttons';
import {LoaderWrapper, Panel} from 'common/layout';
import {useForm} from 'utils/forms';

import {StableFormValues, stableInitialValues, stableFormName} from '../../types/stable-form-values';
import {validate} from './validate';

interface Props {
    initialValues: StableFormValues | null;
    onSubmit(): void;
    setStableFormValues(stable: StableFormValues): void;
}

const StableFormBase: FC<Props> = props => {
    const {initialValues, onSubmit, setStableFormValues} = props;

    const {t, tCommon} = useOurTranslation('breeder');

    useEffect(() => {
        if (initialValues) {
            setStableFormValues(initialValues);
        }
    }, [initialValues, setStableFormValues]);

    const {Fields, Form, submitting} = useForm({
        form: stableFormName,
        initialValues: stableInitialValues,
        validate,
        onSubmit,
    });

    return (
        <Panel>
            <LoaderWrapper loading={submitting || !initialValues}>
                <Form>
                    <div className="row">
                        <div className="col-md-6">
                            <Fields.Input
                                name="phone"
                                type="text"
                                label={t('phone')}
                                biggerLabel
                            />
                        </div>
                        <div className="col-md-6">
                            <Fields.Input
                                name="email"
                                type="text"
                                label={tCommon('email')}
                                biggerLabel
                            />
                        </div>
                        <div className="col-md-6">
                            <Fields.Input
                                name="note"
                                type="text"
                                label={tCommon('note')}
                                biggerLabel
                            />
                        </div>
                        <div className="col-md-6">
                            <Fields.Tags
                                name="alternativeEmails"
                                label={t('alternativeEmails')}
                                biggerLabel
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <FooterButtons
                            withoutCancel
                            submitting={submitting}
                        />
                    </div>
                </Form>
            </LoaderWrapper>
        </Panel>
    );
};

export const StableForm = memo(StableFormBase);
