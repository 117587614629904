// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTagWithoutCountry, EarTagWithoutCountrySchema } from 'api/gen/EarTagWithoutCountry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserCode, UserCodeSchema } from 'api/gen/UserCode';

const requiredPart = t.interface({
  dead: t.boolean,
  earTag: EarTagWithoutCountrySchema,
  id: PositiveLongSchema,
  lactation: PositiveIntegerSchema,
  ratingTs: DateTimeSchema,
  userCode: UserCodeSchema,
});

export const RatingWithUnknownBreedingSchema = excess(requiredPart);

export interface RatingWithUnknownBreeding extends t.TypeOf<typeof RatingWithUnknownBreedingSchema> {}
