import {takeLatestF, putAll, showBeError} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {EmailTemplateType} from 'api/gen/EmailTemplateType';
import {t} from 'app/translations';

import {planningAction, GetEmailTemplateAction} from '../action';

function* execute(_: GetEmailTemplateAction): SagaIterator {
    const templateType: EmailTemplateType = 'SEND_PLAN';
    const response = yield* call(Api.getByTemplateType, {templateType});

    if (response.isSuccess) {
        yield* put(planningAction.getEmailTemplateSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('breederSuites/messages')('getEmailTemplate')));
    }
}

export function* getEmailTemplateSaga(): SagaIterator {
    yield takeLatestF('planning/GET_EMAIL_TEMPLATE', execute);
}
