import * as O from 'optics-ts';
import {pipe} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialUserState, UserState} from './state';

const userStateO = O.optic<UserState>();

export const userReducer = (state: UserState = initialUserState, action: AppAction): UserState => {
    switch (action.type) {
        case 'user/INITIALIZE':
        case 'user/LOG_IN_USER': {
            return O.set(userStateO.prop('isUserLoading'))(true)(state);
        }
        case 'user/INITIALIZE_FINISH': {
            return pipe(
                state,
                O.set(userStateO.prop('isUserInitialized'))(true),
                O.set(userStateO.prop('isUserLoading'))(false),
            );
        }
        case 'user/CURRENT_USER_LOADED': {
            const {currentUser} = action.payload;

            return pipe(
                state,
                O.set(userStateO.prop('currentUser'))(currentUser),
                O.set(userStateO.prop('isUserLoading'))(false),
            );
        }

        case 'user/CURRENT_USER_LOAD_FAILED': {
            return pipe(
                state,
                O.set(userStateO.prop('currentUser'))(null),
                O.set(userStateO.prop('isUserLoading'))(false),
            );
        }

        case 'user/LOG_OUT_USER_SUCCESS': {
            return O.set(userStateO.prop('currentUser'))(null)(state);
        }

        case 'user/GET_ALL_USERS_SUCCESS': {
            const {allUsers} = action.payload;

            return O.set(userStateO.prop('allUsers'))(allUsers)(state);
        }

        case 'user/SET_LOADING_USER': {
            const {isUserLoading} = action.payload;

            return O.set(userStateO.prop('isUserLoading'))(isUserLoading)(state);
        }

        case 'user/SET_INITIAL_LOAD_DONE': {
            const {value} = action.payload;

            return O.set(userStateO.prop('isUserInitialized'))(value)(state);
        }

        case 'user/SET_PREVIOUS_AND_CURRENT_PATHNAME': {
            const {current, previous} = action.payload;

            return pipe(
                state,
                O.set(userStateO.prop('previousPathname'))(previous),
                O.set(userStateO.prop('currentPathname'))(current),
            );
        }

        case 'user/SET_SERVER_ERROR': {
            const {isServerError} = action.payload;

            return O.set(userStateO.prop('isServerError'))(isServerError)(state);
        }

        default:
            return state;
    }
};
