import {TablesState as GenericTablesState, setupTables} from '@fl/cmsch-fe-library';

import {TableFilter} from 'api/gen/TableFilter';
import {AppAction, State} from 'app/setup';
import {TableRowMap} from 'types/table-row-map';

export const {
    ConnectedTable,
    getRequestedTablePageParams,
    initialTablesState,
    tablesAction,
    tablesReducer,
    tablesSagas,
    simpleTableSelector,
    convertTableDataParamsToDto,
} = setupTables<TableRowMap, State, AppAction, TableFilter>();

export type TablesState = GenericTablesState<TableRowMap>;
