// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// The username of a user.
// Example: "drizhalova"

export const usernameRegexGen = (): RegExp => new RegExp("^[\\w]{3,255}$");

export const UsernameSchema = StringPatternSchema<Username>(usernameRegexGen());

export type Username = string;
