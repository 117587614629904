import {TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {Opt} from 'ts-opt';

import {Breeder} from 'api/gen/Breeder';
import {useOurTranslation} from 'app/translations';
import {Content, PageHeader} from 'common/layout';

import {BreederTable} from '../components';
import {breederAction} from '../model';

const ListBase: FC = _ => {
    const {t} = useOurTranslation('breeder');
    const dispatch = useDispatch();

    const getBreeders = useCallback((tableDataRequest: Opt<TableDataRequest<Breeder>>) => {
        dispatch(breederAction.getBreeders(tableDataRequest));
    }, [dispatch]);

    const goTo = useCallback((path: string) => {
        dispatch(routerActions.push(path));
    }, [dispatch]);

    return (
        <Content container>
            <PageHeader title={t('farmAdministration')} />

            <BreederTable
                getBreeders={getBreeders}
                onGoTo={goTo}
            />
        </Content>
    );
};

export const List = memo(ListBase);
