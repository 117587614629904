import React from 'react';
import {Route} from 'react-router-dom';

import {List} from './screens';

export const databaseOfMeasuredRoutes = [
    (
        <Route
            exact
            path="/database-of-measured"
            component={List}
            key="databaseOfMeasured"
        />
    ),
];
