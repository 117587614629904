import {addAnimalsToPlanSaga} from './add-animals-to-plan-saga';
import {deletePlanSaga} from './delete-plan-saga';
import {exportUserMeasurementsSaga} from './export-user-measurements-saga';
import {getAdminPlansSaga} from './get-admin-plans-saga';
import {getEmailTemplateSaga} from './get-email-template';
import {getPlanSaga} from './get-plan-saga';
import {printBreederPlanSaga} from './print-breeder-plan-saga';
import {printBreederStablePlanSaga} from './print-breeder-stable-plan-saga';
import {printPlanSaga} from './print-plan-saga';
import {removeAnimalsFromPlanSaga} from './remove-animals-from-plan-saga';
import {removeBreederFromPlan} from './remove-breeder-from-plan-saga';
import {sendPlanBreederEmailSaga} from './send-plan-breeder-email-saga';
import {sendPlanStableEmailSaga} from './send-plan-stable-email-saga';
import {setAnimalsAsDeadSaga} from './set-animals-as-dead-saga';

export const planningSagas = [
    getPlanSaga,
    addAnimalsToPlanSaga,
    removeAnimalsFromPlanSaga,
    getAdminPlansSaga,
    exportUserMeasurementsSaga,
    sendPlanStableEmailSaga,
    sendPlanBreederEmailSaga,
    printPlanSaga,
    deletePlanSaga,
    printBreederPlanSaga,
    printBreederStablePlanSaga,
    removeBreederFromPlan,
    getEmailTemplateSaga,
    setAnimalsAsDeadSaga,
];
