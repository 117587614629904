import {putAll, showBeError, showSuccess, extractFormErrorsFromResponse, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {editRatingFormName} from '../../types/edit-rating-values';
import {UpdateRatingAction, measurementAction} from '../action';
import {simpleMeasurementSelector} from '../selector';

function* execute({payload: {rating}}: UpdateRatingAction): SagaIterator {
    const title = t('measurement/table')('editRating');
    const ratingId = (yield* select(simpleMeasurementSelector.ratingInEditId)).orCrash('no ratingInEdit');

    yield* put(formHelpers.startSubmit(editRatingFormName));

    const response = yield* call(Api.updateRating, rating, {ratingId});

    if (response.isSuccess) {
        yield* put(showSuccess(
            title,
            t('measurement/messages')('editMeasurementSuccess'),
            false,
            'edit-measurement-success',
        ));
        yield* put(layoutAction.setModalVisibility('editRating', false));
        yield* put(measurementAction.getMeasurements(opt({action: 'refresh'})));
        yield* put(measurementAction.setRatingInEdit());
        yield* put(measurementAction.setEditedRating(opt(ratingId)));
    } else {
        yield putAll(showBeError(response, title));
        yield* put(formHelpers.stopSubmit(editRatingFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* updateRatingSaga(): SagaIterator {
    yield takeLatestF('measurement/UPDATE_RATING', execute);
}
