// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { WholeName, WholeNameSchema } from 'api/gen/WholeName';

const requiredPart = t.interface({
  dead: PositiveOrZeroIntegerSchema,
  measured: PositiveOrZeroIntegerSchema,
  total: PositiveOrZeroIntegerSchema,
  updated: t.union([DateSchema, t.null]),
  user: WholeNameSchema,
});

export const PlanStatisticsSchema = excess(requiredPart);

export interface PlanStatistics extends t.TypeOf<typeof PlanStatisticsSchema> {}
