import {TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairFleckviehMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairFleckviehMeasurementFormValues, 'properties'>;

const UdderFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="frontUdderLength"
                label={tCommon('frontUdderLength')}
                isRequired
            />
            <Fields.NumberInput
                name="frontUdderAttachmentAngle"
                label={tCommon('frontUdderAttachmentAngle')}
                isRequired
            />
            <Fields.NumberInput
                name="rearUdderLength"
                label={tCommon('rearUdderLength')}
                isRequired
            />
            <Fields.NumberInput
                name="rearUdderAttachment"
                label={tCommon('rearUdderAttachment')}
                isRequired
            />
            <Fields.NumberInput
                name="suspensoryLigament"
                label={tCommon('suspensoryLigament')}
                isRequired
            />
            <Fields.NumberInput
                name="udderDepth"
                label={tCommon('udderDepth')}
                isRequired
            />
            <Fields.NumberInput
                name="frontTeatsPlacement"
                label={tCommon('frontTeatsPlacement')}
                isRequired
            />
            <Fields.NumberInput
                name="rearTeatsPlacement"
                label={tCommon('rearTeatsPlacement')}
                isRequired
            />
            <Fields.NumberInput
                name="rearTeatsPosition"
                label={tCommon('rearTeatsPosition')}
                isRequired
            />
            <Fields.NumberInput
                name="teatsLength"
                label={tCommon('teatsLength')}
                isRequired
            />
            <Fields.NumberInput
                name="teatsWidth"
                label={tCommon('teatsWidth')}
                isRequired
            />
        </Fragment>
    );
};

export const UdderFormSection = memo(UdderFormSectionBase);
