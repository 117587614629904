import {take} from '@fl/cmsch-fe-library';
import * as Sentry from '@sentry/react';

import {Config} from 'app/config';

const sentryLoggerDsn = 'https://a89021840b51439cb9df7624d7e37e26@o435172.ingest.sentry.io/6054403';

const anonymousUsername = '(Anonymous)';
const maxActionPayloadLength = 2000;

const ignoredActionTypes: Array<string> = ['@@redux-form/REGISTER_FIELD'];

const ignoredErrors: Array<string> = [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
];

// eslint-disable-next-line import/no-unused-modules
export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: action => {
        if (ignoredActionTypes.includes(action.type)) {
            // Return null to not log the action to Sentry
            return null;
        }

        if (action.payload) {
            let stringifiedPayload: string;
            try {
                stringifiedPayload = JSON.stringify(action.payload);
            } catch (_) {
                stringifiedPayload = 'Error: object contained cyclical dependency or BigInt';
            }
            const shortenedPayload = take(maxActionPayloadLength)(stringifiedPayload);

            return {...action, payload: shortenedPayload};
        }

        return action;
    },
});

class Logger {
    constructor(private readonly disable: boolean) {
        if (!disable) {
            Sentry.init({
                dsn: sentryLoggerDsn,
                environment: Config.environment,
                normalizeDepth: 10,
                maxBreadcrumbs: 50,
                beforeSend: event => {
                    const value = event.exception?.values?.[0]?.value;

                    if (value && ignoredErrors.includes(value)) return null;

                    return event;
                },
            });
            this.setAnonymousUser();
        }
    }

    public setUser(username: string): void {
        Sentry.setUser({username});
    }

    public setAnonymousUser(): void {
        Sentry.setUser({username: anonymousUsername});
    }

    public logError(err: Error): void {
        if (!this.disable) {
            Sentry.captureException(err);
        }
    }

    public logErrorAndConsole(err: Error): void {
        // eslint-disable-next-line no-console
        console.error(err);
        this.logError(err);
    }

    public logMessage(message: string): void {
        if (!this.disable) {
            Sentry.captureMessage(message);
        }
    }
}

export const logger = new Logger(Config.disableSentry);
