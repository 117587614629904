// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AfterDateFilter, AfterDateFilterSchema } from 'api/gen/AfterDateFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BeforeDateFilter, BeforeDateFilterSchema } from 'api/gen/BeforeDateFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BetweenDatesFilter, BetweenDatesFilterSchema } from 'api/gen/BetweenDatesFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BetweenDecimalsFilter, BetweenDecimalsFilterSchema } from 'api/gen/BetweenDecimalsFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BetweenEarTagFilter, BetweenEarTagFilterSchema } from 'api/gen/BetweenEarTagFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BetweenIntegersFilter, BetweenIntegersFilterSchema } from 'api/gen/BetweenIntegersFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BooleanFilter, BooleanFilterSchema } from 'api/gen/BooleanFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ContainsStringFilter, ContainsStringFilterSchema } from 'api/gen/ContainsStringFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EqualsDecimalFilter, EqualsDecimalFilterSchema } from 'api/gen/EqualsDecimalFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EqualsIntegerFilter, EqualsIntegerFilterSchema } from 'api/gen/EqualsIntegerFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FulltextFilter, FulltextFilterSchema } from 'api/gen/FulltextFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GreaterThanBreedFilter, GreaterThanBreedFilterSchema } from 'api/gen/GreaterThanBreedFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GreaterThanDecimalFilter, GreaterThanDecimalFilterSchema } from 'api/gen/GreaterThanDecimalFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GreaterThanEarTagFilter, GreaterThanEarTagFilterSchema } from 'api/gen/GreaterThanEarTagFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { GreaterThanIntegerFilter, GreaterThanIntegerFilterSchema } from 'api/gen/GreaterThanIntegerFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LessThanDecimalFilter, LessThanDecimalFilterSchema } from 'api/gen/LessThanDecimalFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LessThanEarTagFilter, LessThanEarTagFilterSchema } from 'api/gen/LessThanEarTagFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LessThanIntegerFilter, LessThanIntegerFilterSchema } from 'api/gen/LessThanIntegerFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MultiSelectNumberFilter, MultiSelectNumberFilterSchema } from 'api/gen/MultiSelectNumberFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MultiSelectStringFilter, MultiSelectStringFilterSchema } from 'api/gen/MultiSelectStringFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { NullFilter, NullFilterSchema } from 'api/gen/NullFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { OptionalBooleanFilter, OptionalBooleanFilterSchema } from 'api/gen/OptionalBooleanFilter';

export const TableFilterSchema = t.union([NullFilterSchema, BeforeDateFilterSchema, AfterDateFilterSchema, BetweenDatesFilterSchema, LessThanDecimalFilterSchema, GreaterThanDecimalFilterSchema, BetweenDecimalsFilterSchema, LessThanIntegerFilterSchema, GreaterThanIntegerFilterSchema, BetweenIntegersFilterSchema, ContainsStringFilterSchema, MultiSelectStringFilterSchema, MultiSelectNumberFilterSchema, BooleanFilterSchema, OptionalBooleanFilterSchema, EqualsDecimalFilterSchema, EqualsIntegerFilterSchema, FulltextFilterSchema, GreaterThanBreedFilterSchema, BetweenEarTagFilterSchema, GreaterThanEarTagFilterSchema, LessThanEarTagFilterSchema]);

export type TableFilter = t.TypeOf<typeof TableFilterSchema>;
