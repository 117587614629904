// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// Example: "2021-12"

export const yearMonthRegexGen = (): RegExp => new RegExp("^\\d{4}-\\d{2}$");

export const YearMonthSchema = StringPatternSchema<YearMonth>(yearMonthRegexGen());

export type YearMonth = string;
