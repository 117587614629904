export const repairTranslations = {
    CS: {
        'repair/messages': {
            getAllApprovedMeasurements: 'Načtení schválených měření pro zvíře',
            getApprovedMeasurement: 'Načtení schváleného měření',
            updateApprovedMeasurement: 'Aktualizace schváleného měření',
            updateApprovedMeasurementSuccess: 'Schválené měření bylo úspěšně aktualizováno',
            findAnimals: 'Vyhledávání zvířat',
        },
        'repair/List': {
            title: 'Opravy',
        },
        'repair/Detail': {
            title: 'Detail hodnocení {{earTag}}',
            backToList: 'Zpět na Opravy',
            notFound: 'Měření, které hledáte, neexistuje.',
        },
        'repair/RepairMeasurementTable': {
            noMeasurements: 'Nebyla nalezena žádná měření.',
            detail: 'Detail měření',
        },
    },
};
