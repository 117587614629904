// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FleckviehMeasurementAnimal, FleckviehMeasurementAnimalSchema } from 'api/gen/FleckviehMeasurementAnimal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinMeasurementAnimal, HolsteinMeasurementAnimalSchema } from 'api/gen/HolsteinMeasurementAnimal';

export const MeasurementAnimalSchema = t.union([FleckviehMeasurementAnimalSchema, HolsteinMeasurementAnimalSchema]);

export type MeasurementAnimal = t.TypeOf<typeof MeasurementAnimalSchema>;
