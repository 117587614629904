// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableInPlan, StableInPlanSchema } from 'api/gen/StableInPlan';

const requiredPart = t.interface({
  id: PositiveLongSchema,
  name: BreederNameSchema,
  stables: t.array(StableInPlanSchema),
});

export const BreederInPlanSchema = excess(requiredPart);

export interface BreederInPlan extends t.TypeOf<typeof BreederInPlanSchema> {}
