// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-app-repair-components-repair-measurement-show-field-wrapper-___styles__repairMeasurementShowFieldWrapper___nEzFE>div{margin-bottom:16px !important}", "",{"version":3,"sources":["webpack://./src/app/repair/components/repair-measurement-show-field-wrapper/styles.sass"],"names":[],"mappings":"AAAA,0HACI,6BAAA","sourcesContent":[".repairMeasurementShowFieldWrapper > div\n    margin-bottom: 16px !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"repairMeasurementShowFieldWrapper": "src-app-repair-components-repair-measurement-show-field-wrapper-___styles__repairMeasurementShowFieldWrapper___nEzFE"
};
export default ___CSS_LOADER_EXPORT___;
