import {ActionSettings, Table, TableColumn} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt, opt} from 'ts-opt';

import {Stable} from 'api/gen/Stable';
import {TFunction, useOurTranslation} from 'app/translations';

interface Props {
    stables: Array<Stable> | null;
    editedStableId: Opt<number>;
    onGoTo(path: string): void;
}

const getColumns = (t: TFunction<'common'>): Array<TableColumn<Stable>> => [
    {
        field: 'name',
        column: t('stable'),
        type: 'string',
        disableFilter: true,
    },
];

const BreederStablesTableBase: FC<Props> = props => {
    const {stables, editedStableId, onGoTo} = props;

    const {t, tCommon} = useOurTranslation('breeder');

    const goToDetail = useCallback((stableId: number) => {
        onGoTo(`/stable-detail/${stableId}`);
    }, [onGoTo]);

    const actionSettings: ActionSettings<Stable> = useMemo(() => ({
        extraActions: [
            {
                id: 'goToDetail',
                column: tCommon('detail'),
                icon: 'searchOutlined',
                callback: goToDetail,
                testId: 'detail-action-button',
            },
        ],
    }), [goToDetail, tCommon]);

    return (
        <Table
            tableId="stables"
            columns={getColumns(tCommon)}
            rows={opt(stables).orElse([])}
            emptyTableMessages={{noRows: t('emptyCompanyMessage')}}
            actionSettings={actionSettings}
            highlightedRowId={editedStableId.orUndef()}
            rowActionsOnRight
        />
    );
};

export const BreederStablesTable = memo(BreederStablesTableBase);
