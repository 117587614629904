// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  adjustedBodyStructure: t.Integer,
  adjustedExtremities: t.Integer,
  adjustedLactationPerformance: t.Integer,
  adjustedOverallCondition: t.Integer,
  adjustedUdder: t.Integer,
  backAngle: t.Integer,
  backWidth: t.Integer,
  bodyDepth: t.Integer,
  bonesQuality: t.Integer,
  calculatedBodyStructure: t.Integer,
  calculatedExtremities: t.Integer,
  calculatedLactationPerformance: t.Integer,
  calculatedOverallCondition: t.Integer,
  calculatedUdder: t.Integer,
  chestWidth: t.Integer,
  edginess: t.Integer,
  extremitiesDefect: t.boolean,
  fitness: t.Integer,
  foreUdderAttachment: t.Integer,
  frame: t.Integer,
  frontTeatsPlacement: t.Integer,
  height: t.Integer,
  hooves: t.Integer,
  pasternDefect: t.boolean,
  rearPosture: t.Integer,
  rearTeatsPlacement: t.Integer,
  rearUdderHeight: t.Integer,
  shortSacrum: t.boolean,
  shouldersDefect: t.boolean,
  sidePosture: t.Integer,
  supernumeraryTeats: t.boolean,
  suspensoryLigament: t.Integer,
  teatsDefect: t.boolean,
  teatsLength: t.Integer,
  udderDefect: t.boolean,
  udderDepth: t.Integer,
  udderWidth: t.Integer,
  upperLineDefect: t.boolean,
  walkQuality: t.Integer,
});

export const HolsteinMeasurementPropertiesSchema = excess(requiredPart);

export interface HolsteinMeasurementProperties extends t.TypeOf<typeof HolsteinMeasurementPropertiesSchema> {}
