import {TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairFleckviehMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairFleckviehMeasurementFormValues, 'properties'>;

const GeneralFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="lastMilkingAt"
                label={tCommon('lastMilkingAt')}
                isRequired
            />
        </Fragment>
    );
};

export const GeneralFormSection = memo(GeneralFormSectionBase);
