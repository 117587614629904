export const breederTranslations = {
    CS: {
        breeder: {
            tableName: 'Přehled zvířat',
            companies: 'Podniky',
            name: 'Název',
            ico: 'IČO',
            dic: 'DIČ',
            phone: 'Telefon',
            emailNotForPlanning: 'E-mail (informační -- neslouží k odesílání plánů)',
            save: 'Uložit',
            breedingNumber: 'Číslo chovu',
            areal: 'Plošné',
            emptyStableMessage: 'Pro danou stáj nejsou k dispozici žádné chovy',
            emptyCompanyMessage: 'Pro daný podnik nejsou k dispozici žádné stáje',
            emptyFilterMessage: 'Pro daný filtr nejsou k dispozici žádné podniky',
            stable: 'Stáj',
            actions: 'Akce',
            farm: 'Podnik',
            farmsAdministration: 'Správa podniků',
            farmAdministration: 'Správa podniků',
            stablesAdministration: 'Správa stájí',
            stableAdministration: 'Správa stáje',
            breedingsOverview: 'Přehled chovů',
            arealRating: 'Plošné hodnocení',
            changesSaved: 'Změny byly uloženy',
            alternativeEmails: 'Alternativní e-mailové adresy',
            getStablesAlternativeEmails: 'Načtení alternativních emailů stájí',
        },
    },
};
