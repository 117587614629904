import {t} from 'app/translations';
import {Errors, Validator} from 'utils/validator';

import {SendToBreedersFormValues} from './form-values';

const te = t('breederSuites/SendToBreedersForm');

export const validate = (values: SendToBreedersFormValues): Errors<SendToBreedersFormValues> => {
    const validator = new Validator(values);

    validator.nonEmpty('emails', te('emails'));
    validator.emailArray('emails');
    validator.emailArray('emailsToSendCopyTo');

    return validator.generateErrorsObject();
};
