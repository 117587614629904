export const planningTranslations = {
    CS: {
        'planning': {
            title: 'Plánování',
            defaultPrintFilename: 'plan.pdf',
            defaultPrintBreederFilename: 'plan_chovatele.pdf',
            defaultPrintStableFilename: 'plan_staje.pdf',
        },
        'planning/plan': {
            title: 'Aktuální plán:',
            noAnimals: '0 zvířat',
            planUnset: 'Není nastaven aktuální plán.',
            inflectBreeders: {
                first: 'podnik',
                second: 'podniky',
                third: 'podniků',
            },
            inflectStables: {
                first: 'stáj',
                second: 'stáje',
                third: 'stájí',
            },
        },
        'planning/planActionPanel': {
            noSelectedAnimals: 'Nemáte vybráno žádné zvíře',
            animalsSelected: 'Vybráno zvířat: {{selectedAnimalsCount}}',
            breedersSelected: 'Vybráno podniků: {{selectedCompaniesCount}}',
            removeSelectedAnimals: 'Odebrat vybraná zvířata',
            setSelectedAnimalsAsDead: 'Umrtvit vybraná zvířata',
            printPlan: 'Tisk plánu',
            collapseAll: 'Skrýt vše',
            uncollapseAll: 'Rozbalit vše',
            deletePlan: 'Smazat plán',
            deletePlanConfirm: 'Opravdu chcete smazat plán?',
        },
        'planning/planTable': {
            title: 'Stáj: {{name}} ({{count}} {{animalsInflectWord}})',
            sendStable: 'Odeslat stáji',
            printStable: 'Vytisknout stáj',
            collapseStable: 'Skrýt stáj',
            uncollapseStable: 'Rozbalit stáj',
            removeFromPlanConfirmMessage: 'Opravdu si přejete odebrat stáj z plánu?',
            removeFromPlan: 'Odebrat stáj',
            noAnimals: 'Žádná zvířata',
        },
        'planning/planBreeder': {
            breeder: 'Podnik:',
            printBreeder: 'Vytisknout podnik',
            sendToBreeder: 'Odeslat podniku',
        },
        'planning/adminPlanTable': {
            header: {
                user: 'Uživatel',
                updated: 'Poslední změna',
                measured: 'Ohodnoceno',
                total: 'Celkem zvířat',
                approved: 'Schváleno',
                created: 'Datum vytvoření',
                dead: 'Vyřazeno',
            },
            tooltip: {
                updated: 'Datum posledního nahrání dat z PDA do webové aplikace',
                measured: 'Počet hodnocených zvířat od začátku měsíce',
                total: 'Celkový počet odeslaných zvířat z PDA do webové aplikace',
                approved: 'Schváleno zvířat z plánu v PDA',
                dead: 'Počet vyřazených zvířat od začátku měsíce',
                created: 'Datum vytvoření plánu v PDA',
            },
            emptyText: '-',
            noPlans: 'Žádné plány',
        },
        'planning/exportMeasurements': {
            allUsers: 'Všichni uživatelé',
            month: 'Měsíc',
            measuredAnimals: 'Hodnocená zvířata',
            deadAnimals: 'Mrtvá zvířata',
            repairs: 'Chybník',
            test: 'Sezpívání',
            errors: {
                user: 'Vyberte uživatele',
                month: 'Vyberte měsíc',
            },
        },
        'planning/email': {
            sendStableTitle: 'Odeslat plán stáji:',
            sendBreederTitle: 'Odeslat plán podniku:',
            content: 'Text e-mailu',
        },
        'planning/MeasuredAnimalsModal': {
            chooseDataType: 'Vyberte typ dat',
            processedData: 'Zpracovaná data',
            rawData: 'Nezpracovaná data',
        },
        'planning/messages': {
            deleteTitle: 'Smazání aktivního plánu',
            removeAnimalsPlanSuccess: 'Zvířata z plánu byla úspešně odebrána',
            setAnimalsAsDeadPlanSuccess: 'Zvířata z plánu byla úspešné umrtvena',
            deleteSuccess: 'Aktivní plán byl úspěšně smazán',
            exportError: 'Export plánů měření',
            adminPlansError: 'Načtení všech plánů pro administraci',
            printError: 'Tisk plánu',
            printBreederError: 'Tisk plánu chovatele',
            printStableError: 'Tisk plánu stáje',
            breederRemoveTitle: 'Odebrání podniku z plánu',
            breederRemoveSuccess: 'Podnik byl odebrán z plánu.',
            stablePlanSendTitle: 'Odeslání plánu stáji',
            stablePlanSendSuccess: 'Plán byl odeslán',
            stablePlanSendError: 'Plán se nepodařilo odeslat',
            updatePlanTitle: 'Úprava plánu',
        },
        'planning/AdminPlans': {
            title: 'Export',
            pdaManagement: 'Správa PDA',
        },
    },
};
