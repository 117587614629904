import {Nullable} from '@fl/cmsch-fe-library';
import {createSelector, Selector} from 'reselect';
import {Opt} from 'ts-opt';

import {Tenant} from 'api/gen/Tenant';
import {UserDetails} from 'api/gen/UserDetails';
import {State} from 'app/setup';

import {UserState} from './state';

interface PathNames {
    previous: Opt<string>;
    current: Opt<string>;
}

export const simpleUserSelector = {
    currentUser: ({user}: State): Nullable<UserDetails> => user.currentUser,
    isUserLoading: ({user}: State): boolean => user.isUserLoading,
    isUserInitialized: ({user}: State): boolean => user.isUserInitialized,
    isAdmin: ({user}: State): boolean => user.currentUser?.role === 'ADMIN',
    tenant: ({user}: State): Tenant | undefined => user.currentUser?.tenant,
    allUsers: ({user}: State): Nullable<Array<UserDetails>> => user.allUsers,
    isServerError: ({user}: State): boolean => user.isServerError,
};

export const pathNamesSelector = createSelector<[Selector<State, UserState>], PathNames>(
    ({user}: State) => user,
    (user: UserState) => ({current: user.currentPathname, previous: user.previousPathname}),
);
