import {none, Opt} from 'ts-opt';

import {PageMeasurementAnimal} from 'api/gen/PageMeasurementAnimal';
import {PageRatingToApprove} from 'api/gen/PageRatingToApprove';
import {RatingToApproveStatistics} from 'api/gen/RatingToApproveStatistics';

export interface MeasurementState {
    allRatings: Opt<PageRatingToApprove>;
    ratingsOfAnimal: Opt<PageMeasurementAnimal>;
    foundEarTags: Array<string> | null;
    ratingInEdit: Opt<number>;
    editedRating: Opt<number>;
    stats: Opt<RatingToApproveStatistics>;
}

export const initialMeasurementState: MeasurementState = {
    allRatings: none,
    ratingsOfAnimal: none,
    foundEarTags: null,
    ratingInEdit: none,
    editedRating: none,
    stats: none,
};
