import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {userAction, GetAllUsersAction} from '../action';

function* execute(_: GetAllUsersAction): SagaIterator {
    const response = yield* call(Api.getUsers);

    if (response.isSuccess) {
        yield* put(userAction.getAllUsersSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('user/messages')('loadingUsersTitle')));
    }
}

export function* getAllUsersSaga(): SagaIterator {
    yield takeLatestF('user/GET_ALL_USERS', execute);
}
