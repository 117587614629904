import * as React from 'react';
import {createRoot} from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import {logger} from 'app/sentry-logger';

import App from './app/app';

const getAppElement = (): HTMLElement => {
    const element = document.getElementById('app');

    if (!element) {
        const err = new Error('app element not found');
        logger.logError(err);
        throw err;
    }

    return element;
};

// Assert used to https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const root = createRoot(getAppElement()!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
const renderApplication = (): void => {
    root.render(<App />);
};

renderApplication();
