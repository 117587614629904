import {notificationReducer} from '@fl/cmsch-fe-library';
import {ReducersMapObject, combineReducers, Reducer, AnyAction} from 'redux';
import {RouterState} from 'redux-first-history';
import {reducer as formReducer, FormReducerMapObject} from 'redux-form';

import {adminReducer} from 'app/admin';
import {breederReducer} from 'app/breeder';
import {breederSuiteReducer} from 'app/breeder-suite/model';
import {breedingReducer} from 'app/breeding';
import {bullReducer} from 'app/bull';
import {databaseOfMeasuredReducer} from 'app/database-of-measured/model';
import {districtReducer} from 'app/district';
import {emailReducer} from 'app/email';
import {importKkutReducer} from 'app/import-kkut';
import {measurementReducer} from 'app/measurement';
import {planningReducer} from 'app/planning';
import {repairReducer} from 'app/repair';
import {unknownAnimalReducer} from 'app/unknown-animal';
import {userReducer} from 'app/user';
import {layoutReducer} from 'common/layout/model/reducer';
import {tablesReducer} from 'utils/tables';

import {AppAction} from './app-action';
import {formReducers} from './form-reducers';
import {defaultState, State} from './state';

type StateWithoutRouter = Omit<State, 'router'>;

const reducers: ReducersMapObject<StateWithoutRouter, AnyAction> = {
    toastr: notificationReducer<AnyAction>(),
    form: formReducer.plugin(formReducers as FormReducerMapObject) as Reducer,
    layout: layoutReducer,
    user: userReducer,
    importKkut: importKkutReducer,
    district: districtReducer,
    breeding: breedingReducer,
    bull: bullReducer,
    planning: planningReducer,
    measurements: measurementReducer,
    breeder: breederReducer,
    unknownAnimal: unknownAnimalReducer,
    breederSuite: breederSuiteReducer,
    databaseOfMeasured: databaseOfMeasuredReducer,
    repair: repairReducer,
    tables: tablesReducer,
    admin: adminReducer,
    email: emailReducer,
};

export const createReducer = (routerReducer: Reducer<RouterState>): Reducer<State, AnyAction> =>
    combineReducers<State>({
        router: routerReducer,
        ...reducers,
    });

export const resetEnhancer = (rootReducer: Reducer<State, AppAction>) =>
    (state: State | undefined, action: AppAction): State => {
        if (action.type !== 'user/LOG_OUT_USER_SUCCESS') return rootReducer(state, action);

        const newState = defaultState;
        newState.router = state?.router;

        return newState as State;
    };
