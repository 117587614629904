/* eslint-disable import/no-unused-modules */
import {District} from 'api/gen/District';

export const districtAction = {
    loadDistricts: () => ({
        type: 'district/LOAD_DISTRICTS',
    }) as const,
    loadDistrictsSuccess: (districts: Array<District>) => ({
        type: 'district/LOAD_DISTRICTS_SUCCESS',
        payload: {districts},
    }) as const,
    updateUserDistricts: (userDistricts: Array<District>) => ({
        type: 'district/UPDATE_USER_DISTRICTS',
        payload: {userDistricts},
    }) as const,
    updateSelectedDistricts: (selectedDistricts: Array<District>) => ({
        type: 'district/UPDATE_SELECTED_DISTRICTS',
        payload: {selectedDistricts},
    }) as const,
    getActiveDistricts: () => ({
        type: 'district/GET_ACTIVE_DISTRICTS',
    }) as const,
    getActiveDistrictsSuccess: (districts: Array<District>) => ({
        type: 'district/GET_ACTIVE_DISTRICTS_SUCCESS',
        payload: {districts},
    }) as const,
};

export type LoadDistrictsAction = ReturnType<typeof districtAction.loadDistricts>;
export type LoadDistrictsSuccessAction = ReturnType<typeof districtAction.loadDistrictsSuccess>;
export type UpdateSelectedDistrictsAction = ReturnType<typeof districtAction.updateSelectedDistricts>;
export type UpdateUserDistrictsAction = ReturnType<typeof districtAction.updateUserDistricts>;
export type GetActiveDistrictsAction = ReturnType<typeof districtAction.getActiveDistricts>;
export type GetActiveDistrictsSuccessAction = ReturnType<typeof districtAction.getActiveDistrictsSuccess>;

export type DistrictAction =
    | LoadDistrictsSuccessAction
    | LoadDistrictsAction
    | UpdateSelectedDistrictsAction
    | UpdateUserDistrictsAction
    | GetActiveDistrictsAction
    | GetActiveDistrictsSuccessAction;
