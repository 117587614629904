import {Table, mergeObjects} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import React, {FC, memo, useMemo} from 'react';
import {opt} from 'ts-opt';

import {PlanStatistics} from 'api/gen/PlanStatistics';
import {useOurTranslation} from 'app/translations';

import {getColumns, PlanStatisticsTableType} from './columns';

interface Props {
    adminPlans: Array<PlanStatistics> | null;
    isAdmin: boolean;
}

const AdminPlansTableBase: FC<Props> = props => {
    const {adminPlans, isAdmin} = props;
    const {t} = useOurTranslation('planning/adminPlanTable');

    const data = useMemo(() =>
        opt(adminPlans).orElse([]).map((x, id): PlanStatisticsTableType => mergeObjects(x, {id}))
    , [adminPlans]);
    const columns = useMemo(() => getColumns(t, isAdmin), [t, isAdmin]);

    return (
        <Table
            tableId="admin-plans"
            columns={columns}
            rows={data}
            emptyTableMessages={{noRows: t('noPlans')}}
            scroll={{x: true}}
            loading={isNull(adminPlans)}
        />
    );
};

export const AdminPlansTable = memo(AdminPlansTableBase);
