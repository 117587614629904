import {Nullable} from '@fl/cmsch-fe-library';

import {BasicImport} from 'api/gen/BasicImport';
import {Import} from 'api/gen/Import';
import {State} from 'app/setup';

export const simpleImportKkutSelector = {
    imports: ({importKkut}: State): Nullable<Array<Import>> => importKkut.imports,
    importResult: ({importKkut}: State): Nullable<BasicImport> => importKkut.importResult,
};
