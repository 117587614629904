import {inflectWord} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {TFunction, useOurTranslation} from 'app/translations';
import {inflectAnimalWord} from 'app/utils/inflect-animal-word';
import {InfoPanel} from 'common/layout';

const inflectSelectedAnimalsWord = (
    count: number,
    t: TFunction<'breeding/BreedingDetailActionPanel'>,
): ReactNode => inflectWord(
    t('inflectSelected.first'),
    t('inflectSelected.second'),
    t('inflectSelected.third'),
    count,
);

interface Props {
    countOfAnimalsInPlan: number;
    countOfBreedingAnimalsInPlan: number;
    countOfMeasurableAnimalsInBreeding: number;
}

const BreedingDetailActionPanelBase: FC<Props> = props => {
    const {countOfAnimalsInPlan, countOfBreedingAnimalsInPlan, countOfMeasurableAnimalsInBreeding} = props;

    const {t} = useOurTranslation('breeding/BreedingDetailActionPanel');

    const infoText = t('infoText', {
        selectedWord: inflectSelectedAnimalsWord(countOfAnimalsInPlan, t),
        animalsInPlanCount: countOfAnimalsInPlan,
        animalsInPlanWord: inflectAnimalWord(countOfAnimalsInPlan),
        measurableAnimalsInBreedingCount: countOfMeasurableAnimalsInBreeding,
        measurableAnimalsInBreedingCountWord: inflectAnimalWord(countOfMeasurableAnimalsInBreeding),
        secondSelectedWord: inflectSelectedAnimalsWord(countOfBreedingAnimalsInPlan, t),
        breedingAnimalsInPlanCount: countOfBreedingAnimalsInPlan,
        breedingAnimalsInPlanWord: inflectAnimalWord(countOfBreedingAnimalsInPlan),

    });

    return (
        <InfoPanel
            message={infoText}
            marginBottom
        />
    );
};

export const BreedingDetailActionPanel = memo(BreedingDetailActionPanelBase);
