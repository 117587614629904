export const exportuserMeasurementsFormName = 'exportUserMeasurements';

export interface ExportUserMeasurementsFormValues {
    userItem: number | null;
    monthYear: string | null;
}

export const exportUserMeasurementsInitialValues: ExportUserMeasurementsFormValues = {
    monthYear: null,
    userItem: null,
};
