import React, {FC, memo, useEffect, useMemo} from 'react';
import {opt, Opt} from 'ts-opt';

import {BreederInPlan} from 'api/gen/BreederInPlan';
import {EmailTemplate} from 'api/gen/EmailTemplate';
import {Plan} from 'api/gen/Plan';
import {StableInPlan} from 'api/gen/StableInPlan';
import {useOurTranslation} from 'app/translations';
import {Modal} from 'common/layout';
import {LoadingItemId} from 'common/layout/types/loading-item-id';
import {replacePlaceholderInTemplate} from 'utils/replace-text-editor-placeholder';

import {PlanEmailFormValues} from '../../types/plan-email-form-values';
import {PlanEmailForm} from '../plan-email-form';

const findStableById = (
    stables: Array<StableInPlan> | undefined,
    stableId: number | null,
): StableInPlan | undefined => (
    stables?.find(b => b.id === stableId));

const findBreeder = (plan: Plan | null, planEmailModalBreederStableId: number | null): BreederInPlan | undefined =>
    plan?.breeders?.find(x => findStableById(x.stables, planEmailModalBreederStableId));

interface Props {
    plan: Plan | null;
    planEmailModalStableId: number | null;
    loadingItems: Array<LoadingItemId>;
    emailTemplate: Opt<EmailTemplate>;
    alternativeEmails: Array<string>;
    sendPlanEmail(data: PlanEmailFormValues): void;
    closePlanEmailModal(): void;
    getStablesAlternativeEmails(stableIds: Array<number>): void;
    setPlanEmailFormValues(values: PlanEmailFormValues): void;
}

const PlanStableEmailModalBase: FC<Props> = props => {
    const {
        plan,
        planEmailModalStableId,
        loadingItems,
        emailTemplate,
        alternativeEmails,
        sendPlanEmail,
        closePlanEmailModal,
        getStablesAlternativeEmails,
        setPlanEmailFormValues,
    } = props;

    const {t} = useOurTranslation('planning/email');

    useEffect(() => {
        opt(planEmailModalStableId).onSome(x => getStablesAlternativeEmails([x]));
    }, [getStablesAlternativeEmails, planEmailModalStableId]);

    const breeder = useMemo(() => findBreeder(plan, planEmailModalStableId), [plan, planEmailModalStableId]);
    const stable = useMemo(
        () => findStableById(breeder?.stables, planEmailModalStableId),
        [breeder, planEmailModalStableId],
    );
    const values = useMemo((): PlanEmailFormValues => ({
        emailsTo: [stable?.email || []].flat(),
        emailsToSendCopyTo: alternativeEmails,
        content: replacePlaceholderInTemplate(emailTemplate.prop('body').orNull()) || '',
    }), [alternativeEmails, emailTemplate, stable]);

    if (!stable) return null;

    return (
        <Modal
            modalId="planStableEmail"
            title={`${t('sendStableTitle')} ${stable.name}`}
            footer={null}
            width={620}
            onCancel={closePlanEmailModal}
        >
            <PlanEmailForm
                values={values}
                loading={loadingItems.includes('sendPlanStableEmail')}
                onSubmit={sendPlanEmail}
                onCancel={closePlanEmailModal}
                setPlanEmailFormValues={setPlanEmailFormValues}
            />
        </Modal>
    );
};

export const PlanStableEmailModal = memo(PlanStableEmailModalBase);
