import {ColorAnnotation, Options, TableRowBackground, useIdFromRoute} from '@fl/cmsch-fe-library';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {District} from 'api/gen/District';
import {PlanningDaughterWithMark} from 'api/gen/PlanningDaughterWithMark';
import {Tenant} from 'api/gen/Tenant';
import {getDistrictOptions} from 'app/district';
import {TFunction, useOurTranslation} from 'app/translations';
import {simpleUserSelector} from 'app/user';

interface ReturnType {
    bullId: number;
    t: TFunction<'bull/daughtersTable'>;
    tCommon: TFunction<'common'>;
    districtOptions: Options<string>;
    tenant: Tenant | undefined;
    breedTranslation: TFunction<'breeding/AnimalsTable'>;
}

export const getTableAnnotations = (t: TFunction<'bull/daughtersTable'>): Array<ColorAnnotation> => [
    {
        color: 'special',
        label: t('annotation'),
    },
];

export const calcRowBackgroundDesign =
    (row: PlanningDaughterWithMark, _index: number): TableRowBackground | undefined =>
        row.isInBreedingWithMeasurement ? 'special' : undefined;

export const useDaughterPrepare = (districts: Array<District> | null): ReturnType => {
    const bullId = useIdFromRoute('bullId');
    const {t, tCommon} = useOurTranslation('bull/daughtersTable');
    const {t: breedTranslation} = useOurTranslation('breeding/AnimalsTable');

    const districtOptions = useMemo(() => getDistrictOptions(districts), [districts]);
    const tenant = useSelector(simpleUserSelector.tenant);

    return {
        bullId,
        t,
        tCommon,
        districtOptions,
        tenant,
        breedTranslation,
    };
};
