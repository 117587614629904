import {TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairFleckviehMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairFleckviehMeasurementFormValues, 'properties'>;

const ColorsFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="colorType"
                label={tCommon('colorType')}
                isRequired
            />
            <Fields.NumberInput
                name="mottleType"
                label={tCommon('mottleType')}
                isRequired
            />
            <Fields.NumberInput
                name="headColor"
                label={tCommon('headColor')}
                isRequired
            />
        </Fragment>
    );
};

export const ColorsFormSection = memo(ColorsFormSectionBase);
