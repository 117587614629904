import {TableDataRequest, TableLegend} from '@fl/cmsch-fe-library';
import React, {FC, Fragment, memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Opt} from 'ts-opt';

import {PlanningDaughterWithMark} from 'api/gen/PlanningDaughterWithMark';
import {bullAction, simpleBullSelector} from 'app/bull/model';
import {ConnectedTable} from 'utils/tables';

import {calcRowBackgroundDesign, getTableAnnotations, useDaughterPrepare} from '../../hooks/use-daughter-prepare';
import {DaughtersProps} from '../../types/daughters-props';
import {getColumns} from './columns';

const DaughtersTableBase: FC<DaughtersProps> = ({districts}) => {
    const {
        t,
        tCommon,
        bullId,
        breedTranslation,
        districtOptions,
        tenant,
    } = useDaughterPrepare(districts);
    const dispatch = useDispatch();
    const getDaughters = useCallback((tableDataRequest: Opt<TableDataRequest<PlanningDaughterWithMark>>) => {
        dispatch(bullAction.getPlannigDaughters(tableDataRequest, bullId));
    }, [bullId, dispatch]);

    return (
        <Fragment>
            <ConnectedTable
                tableId="daughters"
                rowDataPageSelector={simpleBullSelector.daughters}
                onFetchTableData={getDaughters}
                columns={getColumns(tenant, districtOptions, breedTranslation, tCommon)}
                refreshOnMount
                emptyTableMessages={{noRows: t('noDaughters')}}
                calcRowBackgroundDesign={calcRowBackgroundDesign}
                showFulltextFilter
            />
            <div className="mt-2">
                <TableLegend annotations={getTableAnnotations(t)} />
            </div>
        </Fragment>
    );
};

export const DaughtersTable = memo(DaughtersTableBase);
