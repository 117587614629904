import {putAll, takeLeadingF, getAuthData, showBeError} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {homePagePath, loginPagePath} from 'app/consts';
import {t} from 'app/translations';
import {simpleLayoutSelector} from 'common/layout';

import {InitializeAction, userAction} from '../action';
import {goToLoginScreen} from './go-to-login';

function* redirectToPage(): SagaIterator {
    const {pathname} = (yield* select(simpleLayoutSelector.location)) ?? {};
    const {redirectTo} = (yield* select(simpleLayoutSelector.locationState)) ?? {};

    if (redirectTo) {
        yield* put(routerActions.push(redirectTo));
    } else if (pathname === loginPagePath) {
        yield* put(routerActions.push(homePagePath));
    }
}

function* execute({payload: {params}}: InitializeAction): SagaIterator {
    const savedLogin = getAuthData().orUndef();

    if (!savedLogin && params.needsLogin) {
        yield* call(goToLoginScreen);
    }

    if (savedLogin) {
        const response = yield* call(Api.getUserDetails);

        if (response.isSuccess) {
            yield* put(userAction.currentUserLoaded(response.data));
            yield* call(redirectToPage);
        } else {
            yield putAll(showBeError(response, t('user/messages')('initializingUser')));
            yield* put(userAction.logOutUser());
        }
    }

    yield* put(userAction.initializeFinish());
}

export function* initializeSaga(): SagaIterator {
    yield takeLeadingF('user/INITIALIZE', execute);
}
