import {findAnimalsSaga} from './find-animals-saga';
import {getAllApprovedMeasurementsSaga} from './get-all-approved-measurements';
import {getApprovedMeasurementSaga} from './get-approved-measurement-saga';
import {updateApprovedMeasurementSaga} from './update-approved-measurement';

export const repairSagas = [
    getAllApprovedMeasurementsSaga,
    getApprovedMeasurementSaga,
    updateApprovedMeasurementSaga,
    findAnimalsSaga,
];
