// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// The calve age (in days / months format) of an animal.
// Example: "- / -"

export const calveAgeRegexGen = (): RegExp => new RegExp("^([\\d-]+) / ([\\d-]+)$");

export const CalveAgeSchema = StringPatternSchema<CalveAge>(calveAgeRegexGen());

export type CalveAge = string;
