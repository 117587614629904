import React, {FC, memo} from 'react';

import {useOurTranslation} from 'app/translations';
import {Button} from 'common/buttons';

interface Props {
    goToDistricts(): void;
}

const NoUserDistrictsBase: FC<Props> = props => {
    const {goToDistricts} = props;

    const {t} = useOurTranslation('district');

    return (
        <div className="container-fluid d-flex mt-3 pt-5 pb-5 flex-column align-items-center">
            <div className="w-100 pl-4 pr-4">
                <div className="text-center">
                    <h5>
                        {t('noChosenDistricts')}
                    </h5>
                </div>
                <div className="text-center">
                    <Button
                        visuals="primary"
                        onClick={goToDistricts}
                    >
                        {t('chooseDistricts')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const NoUserDistricts = memo(NoUserDistrictsBase);
