import {Option, Options, Nullable} from '@fl/cmsch-fe-library';
import {map, uniqBy, compact} from 'lodash/fp';
import {createSelector, Selector} from 'reselect';
import {Opt, pipe} from 'ts-opt';

import {PageBreederSuite} from 'api/gen/PageBreederSuite';
import {State} from 'app/setup';

import {BreederSuiteRow} from '../components/breeder-suites-table/columns';
import {BreederSuiteState} from './state';

export const simpleBreederSuiteSelector = {
    breederSuite: ({breederSuite}: State): BreederSuiteState => breederSuite,
    breederSuitePage: ({breederSuite}: State): Opt<PageBreederSuite> => breederSuite.breederSuitePage,
    emailTemplate: ({breederSuite}: State): Nullable<string> => breederSuite.emailTemplate.prop('body').orNull(),
    selectedBreederSuites: ({breederSuite}: State): Array<BreederSuiteRow> =>
        breederSuite.selectedBreederSuites,
    selectedBreederSuitesIds: ({breederSuite}: State): Array<string> => breederSuite.selectedBreederSuitesIds,
};

export const selectedBreederSuitesEmailsOptionsSelector = createSelector<
    Array<Selector<State, Array<BreederSuiteRow>>>,
    Options<string>
>(
    simpleBreederSuiteSelector.selectedBreederSuites,
    (selectedBreederSuites: Array<BreederSuiteRow>): Options<string> => pipe(
        selectedBreederSuites,
        map(x => x.stableEmail),
        compact,
        map(x => ({label: x, value: x})),
        uniqBy<Option<string>>('value'),
    ),
);
