import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const planningRoutes = [
    (
        <Route
            exact
            path="/plan"
            component={Screens.Plan}
            key="plan"
        />
    ),
    (
        <Route
            exact
            path="/admin-plans"
            component={Screens.AdminPlans}
            key="admin-plans"
        />
    ),
];
