import React, {FC, memo} from 'react';

import {useOurTranslation} from 'app/translations';
import {Content, PageHeader} from 'common/layout';

import {InvoicesTable} from '../components';
import {useGetDataForInvoices} from '../hooks/use-get-data-for-invoices';

const InvoicesBase: FC = () => {
    const {t} = useOurTranslation('bull/invoicesTable');

    const {
        loadingItems,
        selectedBullsIds,
        createInvoicingDocuments,
        exportBullMarkedDaughters,
        getInvoicingDocuments,
        getInvoices,
        goTo,
        toggleSelectBulls,
    } = useGetDataForInvoices();

    return (
        <Content>
            <PageHeader
                title={t('invoicesOverview')}
            />
            <InvoicesTable
                getInvoices={getInvoices}
                selectedBullsIds={selectedBullsIds}
                loadingItems={loadingItems}
                toggleSelectBulls={toggleSelectBulls}
                getInvoicingDocuments={getInvoicingDocuments}
                createInvoicingDocuments={createInvoicingDocuments}
                exportBullDaughters={exportBullMarkedDaughters}
                goTo={goTo}
            />
        </Content>
    );
};

export const Invoices = memo(InvoicesBase);
