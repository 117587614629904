import React, {FC, memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';

import {homePagePath} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {Ant} from 'common/ant';
import {Button} from 'common/buttons';

interface Props {
    title?: string;
    text?: string;
    buttonText?: string;
    link?: string;
}

const NotFoundBase: FC<Props> = props => {
    const {t} = useOurTranslation('user/messages');
    const {
        title = t('somethingWrong'),
        text = t('notFound'),
        buttonText = t('backHome'),
        link = homePagePath,
    } = props;

    const dispatch = useDispatch();
    const goToLink = useCallback(() => {
        dispatch(routerActions.push(link));
    }, [dispatch, link]);

    return (
        <Ant.Result
            status="404"
            title={title}
            subTitle={text}
            extra={(
                <Button
                    icon="rollbackOutlined"
                    visuals="primary"
                    onClick={goToLink}
                >
                    {buttonText}
                </Button>
            )}
        />
    );
};

export const NotFound = memo(NotFoundBase);
