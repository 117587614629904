import {Options, TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Opt} from 'ts-opt';

import {EmailAudit} from 'api/gen/EmailAudit';
import {EmailTemplateType} from 'api/gen/EmailTemplateType';
import {TFunction, useOurTranslation} from 'app/translations';
import {ConnectedTable} from 'utils/tables';

import {emailAction, simpleEmailSelector} from '../../model';
import {getColumns} from './columns';

const getTypeOptions = (t: TFunction<'sentEmailsTable'>): Options<EmailTemplateType> => [
    {value: 'SEND_BREEDER_SUITE', label: t('sendBreederSuite')},
    {value: 'SEND_PLAN', label: t('sendPlan')},
];

const SentEmailsTableBase: FC = () => {
    const {t} = useOurTranslation('sentEmailsTable');
    const dispatch = useDispatch();

    const getSentEmails = useCallback((tableDataRequest: Opt<TableDataRequest<EmailAudit>>) => {
        dispatch(emailAction.getSentEmails(tableDataRequest));
    }, [dispatch]);

    return (
        <ConnectedTable
            tableId="sentEmails"
            columns={getColumns(getTypeOptions(t), t)}
            rowDataPageSelector={simpleEmailSelector.sentEmailsPage}
            onFetchTableData={getSentEmails}
            emptyTableMessages={{noRows: t('noSentEmails')}}
            withoutVerticalScrollbar
            showFulltextFilter
        />
    )
    ;
};

export const SentEmailsTable = memo(SentEmailsTableBase);
