import {none, Opt} from 'ts-opt';

import {PageMeasurement} from 'api/gen/PageMeasurement';

export interface DatabaseOfMeasuredState {
    measurementsPage: Opt<PageMeasurement>;
}

export const initialDatabaseOfMeasuredState: DatabaseOfMeasuredState = {
    measurementsPage: none,
};
