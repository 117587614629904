import React, {FC, memo} from 'react';

import {District} from 'api/gen/District';
import {buttonsGutter} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {Ant} from 'common/ant';
import {Button} from 'common/buttons';
import {InfoPanel} from 'common/layout';

interface Props {
    selectedDistricts: Array<District> | null;
    updateDistrictInProgress: boolean;

    saveSelectedDistricts(): void;
}

const SaveButtonPanelBase: FC<Props> = props => {
    const {
        selectedDistricts,
        updateDistrictInProgress,
        saveSelectedDistricts,
    } = props;

    const {t} = useOurTranslation('district');

    const areDistrictsEmpty = selectedDistricts === null || selectedDistricts.length === 0;
    const title = areDistrictsEmpty ? 'Vyberte nejméně jeden okres' : undefined;

    return (
        <InfoPanel
            message={t(
                'selectedDistricts',
                {selectedDistricts: selectedDistricts !== null ? selectedDistricts.length : 0},
            )}
            marginBottom
            actions={(
                <Ant.Row
                    gutter={buttonsGutter}
                    justify="end"
                >
                    <Ant.Col
                        xs={24}
                        sm={12}
                        md={7}
                        lg={5}
                        xl={4}
                        xxl={3}
                    >
                        <Button
                            onClick={saveSelectedDistricts}
                            visuals="primary"
                            loading={updateDistrictInProgress}
                            disabled={areDistrictsEmpty}
                            tooltip={title}
                            icon="saveOutlined"
                            block
                            testId="districts-save-button"
                        >
                            {t('saveChanges')}
                        </Button>
                    </Ant.Col>
                </Ant.Row>
            )}
            messageColProps={{
                xs: 24,
                sm: 12,
                md: 6,
            }}
            actionsColProps={{
                xs: 24,
                sm: 12,
                md: 18,
            }}
        />
    );
};

export const SaveButtonPanel = memo(SaveButtonPanelBase);
