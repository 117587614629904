import * as O from 'optics-ts';
import {pipe} from 'ts-opt';

import {AppAction} from 'app/setup';

import {DistrictState, initialDistrictState} from './state';

const districtStateO = O.optic<DistrictState>();

export const districtReducer = (
    state: DistrictState = initialDistrictState,
    action: AppAction,
): DistrictState => {
    switch (action.type) {
        case 'district/LOAD_DISTRICTS_SUCCESS': {
            const {districts} = action.payload;

            return pipe(
                state,
                O.set(districtStateO.prop('districts'))(districts),
                O.set(districtStateO.prop('selectedDistricts'))(null),
            );
        }

        case 'district/UPDATE_SELECTED_DISTRICTS': {
            const {selectedDistricts} = action.payload;

            return O.set(districtStateO.prop('selectedDistricts'))(selectedDistricts)(state);
        }

        case 'district/GET_ACTIVE_DISTRICTS_SUCCESS': {
            const {payload: {districts}} = action;

            return O.set(districtStateO.prop('activeDistricts'))(districts)(state);
        }

        case 'user/LOG_OUT_USER': {
            return pipe(
                state,
                O.set(districtStateO.prop('districts'))(null),
                O.set(districtStateO.prop('activeDistricts'))(null),
            );
        }

        default:
            return state;
    }
};
