import {isEmpty} from 'lodash/fp';
import React, {FC, memo, useMemo} from 'react';

import {Plan} from 'api/gen/Plan';
import {buttonsGutter} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {Ant} from 'common/ant';
import {Button, PopconfirmButton} from 'common/buttons';
import {InfoPanel} from 'common/layout';
import {LoadingItemId} from 'common/layout/types/loading-item-id';

import styles from './styles.sass';

interface Props {
    selectedAnimalsCount: number;
    selectedCompaniesCount: number;
    plan: Plan | null;
    loadingItems: Array<LoadingItemId>;
    visibleStables: Array<number>;
    removeSelectedAnimalsFromPlan(): void;
    setSelectedAnimalsAsDead(): void;
    printPlan(): void;
    deletePlan(): void;
    onCollapseAll(): void;
    onUncollapseAll(): void;
}

// eslint-disable-next-line max-lines-per-function
const PlanActionPanelBase: FC<Props> = props => {
    const {
        plan,
        selectedAnimalsCount,
        selectedCompaniesCount,
        loadingItems,
        visibleStables,
        printPlan,
        deletePlan,
        onCollapseAll,
        onUncollapseAll,
        removeSelectedAnimalsFromPlan,
        setSelectedAnimalsAsDead,
    } = props;

    const {t} = useOurTranslation('planning/planActionPanel');

    const animalRemoveCount = useMemo((): string =>
        `${t('animalsSelected', {selectedAnimalsCount})}, ${t('breedersSelected', {selectedCompaniesCount})}`
    , [selectedAnimalsCount, selectedCompaniesCount, t]);

    const animalSelectionTooltip = !selectedAnimalsCount ? t('noSelectedAnimals') : undefined;
    const allCollapsed = isEmpty(visibleStables);

    const removeSelectedAnimalsCol = (
        <Ant.Col
            xs={24}
            sm={12}
            xl={6}
        >
            <Button
                onClick={removeSelectedAnimalsFromPlan}
                visuals="primary"
                loading={loadingItems.includes('updatePlan')}
                disabled={!selectedAnimalsCount}
                tooltip={!selectedAnimalsCount ? animalSelectionTooltip : undefined}
                block
                icon="closeOutlined"
                testId="remove-selected-animals-button"
            >
                {t('removeSelectedAnimals')}
            </Button>
        </Ant.Col>
    );
    const setSelectedAnimalsCol = (
        <Ant.Col
            xs={24}
            sm={12}
            xl={6}
        >
            <Button
                onClick={setSelectedAnimalsAsDead}
                visuals="primary"
                loading={loadingItems.includes('setAnimalsAsDead')}
                disabled={!selectedAnimalsCount}
                tooltip={!selectedAnimalsCount ? animalSelectionTooltip : undefined}
                block
                icon="closeOutlined"
                testId="set-animal-dead-button"
            >
                {t('setSelectedAnimalsAsDead')}
            </Button>
        </Ant.Col>
    );

    const printPlanCol = (
        <Ant.Col
            xs={24}
            sm={12}
            xl={4}
        >
            <Button
                onClick={printPlan}
                visuals="primary"
                loading={loadingItems.includes('printPlan')}
                disabled={isEmpty(plan?.breeders)}
                block
                icon="printerOutlined"
                testId="print-plan-button"
            >
                {t('printPlan')}
            </Button>
        </Ant.Col>
    );

    const deletePlanCol = (
        <Ant.Col
            xs={24}
            sm={12}
            xl={4}
        >
            <PopconfirmButton
                onClick={deletePlan}
                confirmTitle={t('deletePlanConfirm')}
                okText={t('deletePlan')}
                visuals="primary"
                loading={loadingItems.includes('deletePlan')}
                disabled={isEmpty(plan?.breeders)}
                block
                icon="deleteOutlined"
                testId="delete-plan-button"
            >
                {t('deletePlan')}
            </PopconfirmButton>
        </Ant.Col>
    );

    const collapseAllCol = (
        <Ant.Col
            xs={24}
            xl={4}
        >
            <Button
                onClick={allCollapsed ? onUncollapseAll : onCollapseAll}
                disabled={isEmpty(plan?.breeders)}
                block
                icon={allCollapsed ? 'downOutlined' : 'upOutlined'}
                testId="collapse-all-button"
            >
                {t(allCollapsed ? 'uncollapseAll' : 'collapseAll')}
            </Button>
        </Ant.Col>
    );

    return (
        <div
            className={styles.buttons}
            data-test-id="plan-action-panel"
        >
            <InfoPanel
                message={animalRemoveCount}
                marginBottom
                actions={(
                    <Ant.Row
                        gutter={buttonsGutter}
                        justify="end"
                    >
                        {removeSelectedAnimalsCol}
                        {setSelectedAnimalsCol}
                        {printPlanCol}
                        {deletePlanCol}
                        {collapseAllCol}
                    </Ant.Row>
                )}
                messageColProps={{
                    xs: 24,
                    sm: 12,
                    xl: 18,
                    xxl: 6,
                }}
                actionsColProps={{
                    xs: 24,
                    xxl: {span: 18},
                }}
            /></div>
    );
};

export const PlanActionPanel = memo(PlanActionPanelBase);
