import {isEmpty, isNull} from 'lodash/fp';
import React, {FC} from 'react';

import {RatingWithUnknownBreeding} from 'api/gen/RatingWithUnknownBreeding';
import {Tenant} from 'api/gen/Tenant';
import {MeasurementTableAnnotations} from 'app/measurement/components/measurement-table-annotations';
import {useOurTranslation} from 'app/translations';
import {LoaderWrapper} from 'common/layout/components/loader-wrapper';
import {PageHeader} from 'common/layout/components/page-header';

import {UnassignedAnimalsTable} from '../unassigned-animals-table';

interface Props {
    animals: Array<RatingWithUnknownBreeding> | null;
    editedRating?: number;
    tenant?: Tenant;
    onAssignAnimalToBreeding(animal: RatingWithUnknownBreeding): void;
}

export const UnassignedAnimalsSection: FC<Props> = props => {
    const {animals, editedRating, tenant, onAssignAnimalToBreeding} = props;
    const {t} = useOurTranslation('unknownAnimals');

    if (!isNull(animals) && isEmpty(animals)) return null;

    return (
        <div data-test-id="unassigned-animals-section">
            <PageHeader title={t('unassignedAnimals')} />

            <LoaderWrapper loading={isNull(animals)}>
                <UnassignedAnimalsTable
                    animals={animals ?? []}
                    editedRating={editedRating}
                    tenant={tenant}
                    onAssignAnimalToBreeding={onAssignAnimalToBreeding}
                />
            </LoaderWrapper>
            {animals && !isEmpty(animals) ? <MeasurementTableAnnotations /> : null}
        </div>
    );
};
