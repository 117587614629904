export const databaseOfMeasuredTranslations = {
    CS: {
        'databaseOfMeasured/list': {
            databaseOfMeasured: 'Databáze hodnocených',
        },
        'databaseOfMeasured/DatabaseOfMeasuredTable': {
            backHeightPoints: 'Výška v kříži v bodech',
            backHeightPointsAbbr: 'Výš. v kříži v bod.',
            backLengthPoints: 'Délka zádě v bodech',
            backLengthPointsAbbr: 'Dél. zádě v bod.',
            backWidthPoints: 'Šířka zádě v bodech',
            backWidthPointsAbbr: 'Šíř. zádě v bod.',
            bodyDepthPoints: 'Hloubka těla v bodech',
            bodyDepthPointsAbbr: 'Hloub. těla v bod.',
            firstDefect: 'Vada 1',
            secondDefect: 'Vada 2',
            thirdDefect: 'Vada 3',
            fourthDefect: 'Vada 4',
            unknownFlag: 'Číslo',
            flag: 'ZnakAr',
            unknownDate: 'Kontrdatum',
            exportMeasured: 'Export do XLSX',
            bodyDepth: 'Střední hloubka těla',
        },
        'databaseOfMeasured/messages': {
            exportMeasuredTitle: 'Exportování do XLSX',
        },
    },
};
