// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CountryCode, CountryCodeSchema } from 'api/gen/CountryCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTagWithoutCountry, EarTagWithoutCountrySchema } from 'api/gen/EarTagWithoutCountry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserCode, UserCodeSchema } from 'api/gen/UserCode';

const requiredPart = t.interface({
  animalMarked: t.union([t.boolean, t.null]),
  breederName: t.union([BreederNameSchema, t.null]),
  breedingInArealRating: t.union([t.boolean, t.null]),
  breedingNumber: t.union([BreedingNumberSchema, t.null]),
  countryCode: CountryCodeSchema,
  earTag: EarTagWithoutCountrySchema,
  id: PositiveLongSchema,
  lactation: PositiveIntegerSchema,
  lineRegistry: t.union([LineRegistrySchema, t.null]),
  ratingDate: DateSchema,
  stableName: t.union([StableNameSchema, t.null]),
  tag: t.string,
  userCode: UserCodeSchema,
});

export const AbstractMeasurementViewSchema = excess(requiredPart);

export interface AbstractMeasurementView extends t.TypeOf<typeof AbstractMeasurementViewSchema> {}
