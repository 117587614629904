import {none, Opt} from 'ts-opt';

import {EmailTemplate} from 'api/gen/EmailTemplate';
import {Plan} from 'api/gen/Plan';
import {PlanStatistics} from 'api/gen/PlanStatistics';

export interface PlanningState {
    adminPlans: Array<PlanStatistics> | null;
    plan: Plan | null;
    selectedAnimals: Array<number>;
    selectedCompanies: Array<number>;
    planEmailModalStableId: number | null;
    planEmailModalBreederId: number | null;
    emailTemplate: Opt<EmailTemplate>;
}

export const initialPlanningState: PlanningState = {
    adminPlans: null,
    plan: null,
    selectedAnimals: [],
    selectedCompanies: [],
    planEmailModalStableId: null,
    planEmailModalBreederId: null,
    emailTemplate: none,
};
