import React, {FC, memo} from 'react';
import {Opt} from 'ts-opt';

import {useOurTranslation} from 'app/translations';
import {Modal} from 'common/layout';

import {EditBullFormValues} from '../../types/edit-bull-form-values';
import {BullForm} from '../bull-form';

interface Props {
    title: Opt<string>;
    editBull(bull: EditBullFormValues): void;
    closeModal(): void;
}

const BullEditModalBase: FC<Props> = props => {
    const {title, editBull, closeModal} = props;

    const {t} = useOurTranslation('bull/bullForm');

    return (
        <Modal
            modalId="bullEdit"
            title={`${t('editBull')} ${title.orElse('')}`}
            footer={null}
            onCancel={closeModal}
        >
            <BullForm
                onSubmit={editBull}
                closeModal={closeModal}
            />
        </Modal>
    );
};

export const BullEditModal = memo(BullEditModalBase);
