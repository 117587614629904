export const stableFormName = 'stableForm';

export interface StableFormValues {
    email: string | null;
    phone: string | null;
    note: string | null;
    alternativeEmails: Array<string> | null;
}

export const stableInitialValues: StableFormValues = {
    phone: null,
    email: null,
    note: null,
    alternativeEmails: null,
};
