import * as O from 'optics-ts';
import {opt} from 'ts-opt';

import {AppAction} from 'app/setup';

import {BullState, initialBullState} from './state';

const bullStateO = O.optic<BullState>();

export const bullReducer = (
    state: BullState = initialBullState,
    action: AppAction,
): BullState => {
    switch (action.type) {
        case 'bull/GET_BULLS_SUCCESS': {
            const {bullsPage} = action.payload;

            return O.set(bullStateO.prop('bullsPage'))(bullsPage)(state);
        }
        case 'bull/GET_BULL_ANIMALS_SUCCESS': {
            const {animals} = action.payload;

            return O.set(bullStateO.prop('daughters'))(opt(animals))(state);
        }
        case 'bull/GET_INVOICES_DAUGHTER_SUCCESS': {
            const {animals} = action.payload;

            return O.set(bullStateO.prop('invoicesDaughters'))(opt(animals))(state);
        }
        case 'bull/TOGGLE_SELECT_BULLS': {
            const {ids} = action.payload;

            return O.set(bullStateO.prop('selectedBullsIds'))(ids)(state);
        }
        case 'bull/SET_BULL_IN_EDIT': {
            const {bullId} = action.payload;

            return O.set(bullStateO.prop('bullInEdit'))(opt(bullId))(state);
        }
        case 'bull/GET_INVOICES_SUCCESS': {
            const {invoicesPage} = action.payload;

            return O.set(bullStateO.prop('invoicesPage'))(invoicesPage)(state);
        }

        default:
            return state;
    }
};
