// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';

const requiredPart = t.interface({
  numOfMeasurements: PositiveOrZeroLongSchema,
  numOfMortifiedAnimals: PositiveOrZeroLongSchema,
});

export const RatingToApproveStatisticsSchema = excess(requiredPart);

export interface RatingToApproveStatistics extends t.TypeOf<typeof RatingToApproveStatisticsSchema> {}
