import {TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairHolsteinMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairHolsteinMeasurementFormValues, 'properties'>;

const LactationPerformanceFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="edginess"
                label={tCommon('edginess')}
                isRequired
            />
            <Fields.NumberInput
                name="bonesQuality"
                label={tCommon('bonesQuality')}
                isRequired
            />
            <Fields.NumberInput
                name="chestWidth"
                label={tCommon('chestWidth')}
                isRequired
            />
            <Fields.NumberInput
                name="bodyDepth"
                label={tCommon('bodyDepth')}
                isRequired
            />
            <Fields.NumberInput
                name="frame"
                label={tCommon('frame')}
                isRequired
            />
        </Fragment>
    );
};

export const LactationPerformanceFormSection = memo(LactationPerformanceFormSectionBase);
