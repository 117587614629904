import {ColorAnnotation, TableLegend} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {TFunction, useOurTranslation} from 'app/translations';

const getTableAnnotations = (t: TFunction<'measurement/list'>): Array<ColorAnnotation> => [
    {
        color: 'dangerous',
        label: t('duplicateMeasurement'),
    },
];

const MeasurementTableAnnotationsBase: FC = () => {
    const {t} = useOurTranslation('measurement/list');

    return (
        <div
            className="mt-2"
            data-test-id="duplicate-measurement-annotation"
        >
            <TableLegend annotations={getTableAnnotations(t)} />
        </div>
    );
};

export const MeasurementTableAnnotations = memo(MeasurementTableAnnotationsBase);
