/* eslint-disable import/no-unused-modules */
import {BasicImport} from 'api/gen/BasicImport';
import {Import} from 'api/gen/Import';

export const importKkutAction = {
    importKkutFile: (file: File) => ({
        type: 'importKkut/IMPORT_KKUT_FILE',
        payload: {file},
    }) as const,
    importKkutFileSuccess: (importResult: BasicImport) => ({
        type: 'importKkut/IMPORT_KKUT_FILE_SUCCESS',
        payload: {importResult},
    }) as const,
    getImports: () => ({
        type: 'importKkut/GET_IMPORTS',
    }) as const,
    getImportsSuccess: (imports: Array<Import>) => ({
        type: 'importKkut/GET_IMPORTS_SUCCESS',
        payload: {imports},
    }) as const,
    resetImports: () => ({
        type: 'importKkut/RESET_IMPORTS',
    }) as const,
};

export type ImportKkutFileAction = ReturnType<typeof importKkutAction.importKkutFile>;
export type ImportKkutFileSuccessAction = ReturnType<typeof importKkutAction.importKkutFileSuccess>;
export type GetImportsAction = ReturnType<typeof importKkutAction.getImports>;
export type GetImportsSuccessAction = ReturnType<typeof importKkutAction.getImportsSuccess>;
export type ResetImportsAction = ReturnType<typeof importKkutAction.resetImports>;

export type ImportKkutAction =
    | ImportKkutFileAction
    | ImportKkutFileSuccessAction
    | GetImportsAction
    | GetImportsSuccessAction
    | ResetImportsAction;
