import {map, uniq} from 'lodash/fp';
import React, {FC, memo, useEffect, useMemo} from 'react';
import {filter, opt, Opt, pipe} from 'ts-opt';

import {BreederInPlan} from 'api/gen/BreederInPlan';
import {Email} from 'api/gen/Email';
import {EmailTemplate} from 'api/gen/EmailTemplate';
import {Plan} from 'api/gen/Plan';
import {useOurTranslation} from 'app/translations';
import {Modal} from 'common/layout';
import {LoadingItemId} from 'common/layout/types/loading-item-id';
import {replacePlaceholderInTemplate} from 'utils/replace-text-editor-placeholder';

import {PlanEmailFormValues} from '../../types/plan-email-form-values';
import {PlanEmailForm} from '../plan-email-form';

const findBreeder = (plan: Plan | null, planEmailModalBreederId: number | null): BreederInPlan | undefined =>
    plan?.breeders?.find(x => x.id === planEmailModalBreederId);

interface Props {
    plan: Plan | null;
    planEmailModalBreederId: number | null;
    loadingItems: Array<LoadingItemId>;
    emailTemplate: Opt<EmailTemplate>;
    alternativeEmails: Array<string>;
    sendPlanEmail(data: PlanEmailFormValues): void;
    closePlanEmailModal(): void;
    getStablesAlternativeEmails(stableIds: Array<number>): void;
    setPlanEmailFormValues(values: PlanEmailFormValues): void;
}

const PlanBreederEmailModalBase: FC<Props> = props => {
    const {
        plan,
        planEmailModalBreederId,
        loadingItems,
        emailTemplate,
        alternativeEmails,
        sendPlanEmail,
        closePlanEmailModal,
        getStablesAlternativeEmails,
        setPlanEmailFormValues,
    } = props;

    const {t} = useOurTranslation('planning/email');

    const breeder = useMemo(() => findBreeder(plan, planEmailModalBreederId), [plan, planEmailModalBreederId]);
    const stables = opt(breeder).prop('stables').orElse([]);
    const stablesEmails = useMemo(() => pipe(
        stables,
        map(stable => stable.email || ''),
        filter((x: Email) => x !== ''),
        uniq,
    ), [stables]);

    useEffect(() => {
        opt(breeder).onSome(x => getStablesAlternativeEmails(x.stables.map(y => y.id)));
    }, [getStablesAlternativeEmails, breeder]);

    const values = useMemo((): PlanEmailFormValues => ({
        emailsTo: stablesEmails,
        emailsToSendCopyTo: alternativeEmails,
        content: replacePlaceholderInTemplate(emailTemplate.prop('body').orNull()) || '',
    }), [alternativeEmails, emailTemplate, stablesEmails]);

    if (!breeder) return null;

    return (
        <Modal
            modalId="planBreederEmail"
            title={`${t('sendBreederTitle')} ${breeder.name}`}
            footer={null}
            width={620}
            onCancel={closePlanEmailModal}
        >
            <PlanEmailForm
                values={values}
                loading={loadingItems.includes('sendPlanBreederEmail')}
                onSubmit={sendPlanEmail}
                onCancel={closePlanEmailModal}
                setPlanEmailFormValues={setPlanEmailFormValues}
            />
        </Modal>
    );
};

export const PlanBreederEmailModal = memo(PlanBreederEmailModalBase);
