import {TableColumn, Options} from '@fl/cmsch-fe-library';

import {AnimalInPlanType} from 'api/gen/AnimalInPlanType';
import {RatingToApprove} from 'api/gen/RatingToApprove';
import {TFunction} from 'app/translations';

export const getColumns = (
    typeOptions: Options<AnimalInPlanType>,
    t: TFunction<'measurement/table'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<RatingToApprove>> => {
    const columns: Array<TableColumn<RatingToApprove>> = [
        {
            field: 'ratingTs',
            column: tCommon('ratingDate'),
            type: 'date',
            dateFormat: 'datetime',
            dontShowTime: true,
            width: 162,
            sortable: true,
        },
        {
            field: 'earTag',
            column: tCommon('earTag'),
            type: 'string',
            width: 140,
            fulltextFilterEnabled: true,
            sortable: true,
        },
        {
            field: 'breederName',
            column: tCommon('company'),
            type: 'string',
            width: 400,
            fulltextFilterEnabled: true,
            sortable: true,
        },
        {
            field: 'stableName',
            column: tCommon('stable'),
            type: 'string',
            width: 400,
            fulltextFilterEnabled: true,
            sortable: true,
        },
        {
            field: 'userCode',
            column: tCommon('userCode'),
            type: 'string',
            width: 92,
            align: 'right',
            sortable: true,
        },
        {
            field: 'lactation',
            column: tCommon('lactation'),
            type: 'number',
            width: 92,
            sortable: true,
        },
        {
            field: 'type',
            column: t('type'),
            type: 'option',
            filterTypes: ['multiSelectString'],
            options: typeOptions,
            width: 110,
            sortable: true,
        },
        {
            field: 'dead',
            column: tCommon('dead'),
            type: 'boolean',
            width: 85,
            sortable: true,
        },
    ];

    return columns;
};
