import {ShowField} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';
import {Opt} from 'ts-opt';

import styles from './styles.sass';

interface Props {
    label: string;
    value: Opt<string>;
}

const RepairMeasurementShowFieldWrapperBase: FC<Props> = props => {
    const {label, value} = props;

    return (
        <div className={styles.repairMeasurementShowFieldWrapper}>
            <ShowField
                label={label}
                showType="string"
                value={value}
            />
        </div>
    );
};

export const RepairMeasurementShowFieldWrapper = memo(RepairMeasurementShowFieldWrapperBase);
