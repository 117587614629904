import {putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {UpdateApprovedMeasurementAction} from '../action';

const te = t('repair/messages');

function* execute(action: UpdateApprovedMeasurementAction): SagaIterator {
    const {data, measurementId} = action.payload;

    const response = yield* call(Api.updateApprovedMeasurementById, data, {measurementId});

    if (response.isSuccess) {
        yield* put(routerActions.push('/repairs'));
        yield* put(showSuccess(te('updateApprovedMeasurement'), te('updateApprovedMeasurementSuccess')));
    } else {
        yield putAll(showBeError(response, te('updateApprovedMeasurement')));
    }
}

export function* updateApprovedMeasurementSaga(): SagaIterator {
    yield takeLatestF('repair/UPDATE_APPROVED_MEASUREMENT', execute);
}
