import {putAll, showBeError, downloadFileFromResponse, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {PrintBreederStablePlanAction} from '../action';

function* execute({payload: {stableId}}: PrintBreederStablePlanAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('printStable', true));

    const response = yield* call(Api.exportPlanToPdfForStable, {stableId});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('planning/messages')('printStableError')));
    } else {
        yield* call(downloadFileFromResponse, response, t('planning')('defaultPrintStableFilename'));
    }

    yield* put(layoutAction.setItemLoading('printStable', false));
}

export function* printBreederStablePlanSaga(): SagaIterator {
    yield takeLatestF('planning/PRINT_BREEDER_STABLE_PLAN', execute);
}
