// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: 1

export const positiveIntegerMinimum = 1.0;

export const PositiveIntegerSchema = t.refinement(t.Integer, x => x >= positiveIntegerMinimum, 'PositiveInteger');

export type PositiveInteger = t.TypeOf<typeof PositiveIntegerSchema>;
