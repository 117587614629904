import {animalNameMaxLength} from 'api/gen/AnimalName';
import {bullNoteMaxLength} from 'api/gen/BullNote';
import {t, tCommon} from 'app/translations';
import {Errors, Validator} from 'utils/validator';

import {EditBullFormValues} from '../../types/edit-bull-form-values';

const tr = t('bull/bullForm');

export const validate = (values: EditBullFormValues): Errors<EditBullFormValues> => {
    const validator = new Validator(values);

    validator.maxStringLength('name', animalNameMaxLength, tCommon('name'));
    validator.maxStringLength('note', bullNoteMaxLength, tr('note'));

    return validator.generateErrorsObject();
};
