import {flatMap} from 'lodash/fp';
import {opt} from 'ts-opt';

import {BasicAnimal} from 'api/gen/BasicAnimal';
import {BreederInPlan} from 'api/gen/BreederInPlan';
import {Plan} from 'api/gen/Plan';
import {StableInPlan} from 'api/gen/StableInPlan';

const getAnimalIdsFromStable = (stable: StableInPlan): Array<number> => stable.animals.map(animal => animal.id);

const getAnimalIdsFromBreeder = (breeder: BreederInPlan): Array<number> =>
    flatMap(getAnimalIdsFromStable)(breeder.stables);

export const getAnimalIdsInPlan = (plan: Plan | null): Array<number> =>
    opt(plan?.breeders).map(flatMap(getAnimalIdsFromBreeder)).orElse([]);

const getAnimalsFromStable = (breederId: number) => (stable: StableInPlan): Array<BasicAnimal> =>
    stable.animals.filter(x => x.breedingId === breederId);

const getAnimalsFromBreeder = (breederId: number) => (breeder: BreederInPlan): Array<BasicAnimal> =>
    flatMap(getAnimalsFromStable(breederId))(breeder.stables);

export const getAnimalsInPlanForBreeder = (breederId: number, plan: Plan | null): Array<BasicAnimal> =>
    opt(plan?.breeders).map(flatMap(getAnimalsFromBreeder(breederId))).orElse([]);
