import * as React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const emailRoutes = [
    (
        <Route
            exact
            path="/sent-emails"
            component={Screens.SentEmails}
            key="sent-emails"
        />
    ),
];
