// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The name of a subject.
// Example: "CRV Czech Republic, spol. s r.o."

export const subjectNameMinLength = 1;
export const subjectNameMaxLength = 255;

export const SubjectNameSchema = t.refinement(t.string, n => n.length >= subjectNameMinLength && n.length <= subjectNameMaxLength, 'SubjectName');

export type SubjectName = string;
