import * as t from 'io-ts';
import { Response, EmptyString, EmptyStringSchema, ArrayBufferSchema, FormDataSchema, excess, validateSchema } from '@fl/cmsch-fe-library';

import { apiRequester } from 'app/api';


// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalWithBreedingSearch, AnimalWithBreedingSearchSchema } from 'api/gen/AnimalWithBreedingSearch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { AppInfo, AppInfoSchema } from 'api/gen/AppInfo';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BasicBreeding, BasicBreedingSchema } from 'api/gen/BasicBreeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BasicImport, BasicImportSchema } from 'api/gen/BasicImport';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breeder, BreederSchema } from 'api/gen/Breeder';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederSuiteEmail, BreederSuiteEmailSchema } from 'api/gen/BreederSuiteEmail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederSuiteId, BreederSuiteIdSchema } from 'api/gen/BreederSuiteId';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breeding, BreedingSchema } from 'api/gen/Breeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingDetails, BreedingDetailsSchema } from 'api/gen/BreedingDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CreateUser, CreateUserSchema } from 'api/gen/CreateUser';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { District, DistrictSchema } from 'api/gen/District';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTagWithoutCountry, EarTagWithoutCountrySchema } from 'api/gen/EarTagWithoutCountry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTags, EarTagsSchema } from 'api/gen/EarTags';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplate, EmailTemplateSchema } from 'api/gen/EmailTemplate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateType, EmailTemplateTypeSchema } from 'api/gen/EmailTemplateType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FleckviehLegacyMeasurement, FleckviehLegacyMeasurementSchema } from 'api/gen/FleckviehLegacyMeasurement';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinLegacyMeasurement, HolsteinLegacyMeasurementSchema } from 'api/gen/HolsteinLegacyMeasurement';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { IdSet, IdSetSchema } from 'api/gen/IdSet';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Import, ImportSchema } from 'api/gen/Import';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { JwtDetails, JwtDetailsSchema } from 'api/gen/JwtDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LegacyPlan, LegacyPlanSchema } from 'api/gen/LegacyPlan';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MeasurementDetail, MeasurementDetailSchema } from 'api/gen/MeasurementDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MeasurementSearch, MeasurementSearchSchema } from 'api/gen/MeasurementSearch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MeasurementUpdate, MeasurementUpdateSchema } from 'api/gen/MeasurementUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MobileAppName, MobileAppNameSchema } from 'api/gen/MobileAppName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MobileApps, MobileAppsSchema } from 'api/gen/MobileApps';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageBreeder, PageBreederSchema } from 'api/gen/PageBreeder';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageBreederSuite, PageBreederSuiteSchema } from 'api/gen/PageBreederSuite';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageBullView, PageBullViewSchema } from 'api/gen/PageBullView';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageEmailAudit, PageEmailAuditSchema } from 'api/gen/PageEmailAudit';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageInvoicingDocuments, PageInvoicingDocumentsSchema } from 'api/gen/PageInvoicingDocuments';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMeasurement, PageMeasurementSchema } from 'api/gen/PageMeasurement';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMeasurementAnimal, PageMeasurementAnimalSchema } from 'api/gen/PageMeasurementAnimal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagePlanningAnimal, PagePlanningAnimalSchema } from 'api/gen/PagePlanningAnimal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagePlanningBreeding, PagePlanningBreedingSchema } from 'api/gen/PagePlanningBreeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagePlanningDaughter, PagePlanningDaughterSchema } from 'api/gen/PagePlanningDaughter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PagePlanningDaughterWithMark, PagePlanningDaughterWithMarkSchema } from 'api/gen/PagePlanningDaughterWithMark';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageRatingToApprove, PageRatingToApproveSchema } from 'api/gen/PageRatingToApprove';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Plan, PlanSchema } from 'api/gen/Plan';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PlanEmail, PlanEmailSchema } from 'api/gen/PlanEmail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PlanStatistics, PlanStatisticsSchema } from 'api/gen/PlanStatistics';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RatingToApproveStatistics, RatingToApproveStatisticsSchema } from 'api/gen/RatingToApproveStatistics';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RatingUpdate, RatingUpdateSchema } from 'api/gen/RatingUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RatingWithUnknownBreeding, RatingWithUnknownBreedingSchema } from 'api/gen/RatingWithUnknownBreeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Stable, StableSchema } from 'api/gen/Stable';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableEmail, StableEmailSchema } from 'api/gen/StableEmail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableWithBreedings, StableWithBreedingsSchema } from 'api/gen/StableWithBreedings';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { TableFilter, TableFilterSchema } from 'api/gen/TableFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateBreeder, UpdateBreederSchema } from 'api/gen/UpdateBreeder';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateBreeding, UpdateBreedingSchema } from 'api/gen/UpdateBreeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateBull, UpdateBullSchema } from 'api/gen/UpdateBull';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateDistricts, UpdateDistrictsSchema } from 'api/gen/UpdateDistricts';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UpdateStable, UpdateStableSchema } from 'api/gen/UpdateStable';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserDetails, UserDetailsSchema } from 'api/gen/UserDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserLoginDetails, UserLoginDetailsSchema } from 'api/gen/UserLoginDetails';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { YearMonth, YearMonthSchema } from 'api/gen/YearMonth';
// imports for custom endpoints


function fileToFormData(file: File): FormData {
    const data = new FormData();
    data.append('file', file);
    return data;
}

const findAllMeasurementsForAnimalQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllMeasurementsForAnimalQueryParamsSchema = excess(findAllMeasurementsForAnimalQueryParamsRequiredPart);
export interface FindAllMeasurementsForAnimalQueryParams extends t.TypeOf<typeof FindAllMeasurementsForAnimalQueryParamsSchema> {}


const findAllMeasurementsForAnimalPathParamsRequiredPart = t.interface({
  animalId: PositiveLongSchema,
});
export const FindAllMeasurementsForAnimalPathParamsSchema = excess(findAllMeasurementsForAnimalPathParamsRequiredPart);
export interface FindAllMeasurementsForAnimalPathParams extends t.TypeOf<typeof FindAllMeasurementsForAnimalPathParamsSchema> {}


const searchAnimalsWithBreedingsQueryParamsRequiredPart = t.interface({
  value: t.string,
});
export const SearchAnimalsWithBreedingsQueryParamsSchema = excess(searchAnimalsWithBreedingsQueryParamsRequiredPart);
export interface SearchAnimalsWithBreedingsQueryParams extends t.TypeOf<typeof SearchAnimalsWithBreedingsQueryParamsSchema> {}


const searchEarTagsQueryParamsRequiredPart = t.interface({
  value: t.string,
});
export const SearchEarTagsQueryParamsSchema = excess(searchEarTagsQueryParamsRequiredPart);
export interface SearchEarTagsQueryParams extends t.TypeOf<typeof SearchEarTagsQueryParamsSchema> {}


const exportAllBreederSuitesQueryParamsRequiredPart = t.interface({
  breederSuiteIds: t.array(BreederSuiteIdSchema),
});
export const ExportAllBreederSuitesQueryParamsSchema = excess(exportAllBreederSuitesQueryParamsRequiredPart);
export interface ExportAllBreederSuitesQueryParams extends t.TypeOf<typeof ExportAllBreederSuitesQueryParamsSchema> {}


const findAllBreederSuitesQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllBreederSuitesQueryParamsSchema = excess(findAllBreederSuitesQueryParamsRequiredPart);
export interface FindAllBreederSuitesQueryParams extends t.TypeOf<typeof FindAllBreederSuitesQueryParamsSchema> {}


const sendEmailToBreedersWithMergedStablesQueryParamsRequiredPart = t.interface({
  breederSuiteIds: t.array(BreederSuiteIdSchema),
});
export const SendEmailToBreedersWithMergedStablesQueryParamsSchema = excess(sendEmailToBreedersWithMergedStablesQueryParamsRequiredPart);
export interface SendEmailToBreedersWithMergedStablesQueryParams extends t.TypeOf<typeof SendEmailToBreedersWithMergedStablesQueryParamsSchema> {}


const sendEmailToBreedersWithSeparateStablesQueryParamsRequiredPart = t.interface({
  breederSuiteIds: t.array(BreederSuiteIdSchema),
});
export const SendEmailToBreedersWithSeparateStablesQueryParamsSchema = excess(sendEmailToBreedersWithSeparateStablesQueryParamsRequiredPart);
export interface SendEmailToBreedersWithSeparateStablesQueryParams extends t.TypeOf<typeof SendEmailToBreedersWithSeparateStablesQueryParamsSchema> {}


const findAllBreedersQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllBreedersQueryParamsSchema = excess(findAllBreedersQueryParamsRequiredPart);
export interface FindAllBreedersQueryParams extends t.TypeOf<typeof FindAllBreedersQueryParamsSchema> {}


const findBreederByIdPathParamsRequiredPart = t.interface({
  breederId: PositiveLongSchema,
});
export const FindBreederByIdPathParamsSchema = excess(findBreederByIdPathParamsRequiredPart);
export interface FindBreederByIdPathParams extends t.TypeOf<typeof FindBreederByIdPathParamsSchema> {}


const getStablesByBreederPathParamsRequiredPart = t.interface({
  breederId: PositiveLongSchema,
});
export const GetStablesByBreederPathParamsSchema = excess(getStablesByBreederPathParamsRequiredPart);
export interface GetStablesByBreederPathParams extends t.TypeOf<typeof GetStablesByBreederPathParamsSchema> {}


const updateBreederPathParamsRequiredPart = t.interface({
  breederId: PositiveLongSchema,
});
export const UpdateBreederPathParamsSchema = excess(updateBreederPathParamsRequiredPart);
export interface UpdateBreederPathParams extends t.TypeOf<typeof UpdateBreederPathParamsSchema> {}


const findBreedingByIdPathParamsRequiredPart = t.interface({
  breedingId: PositiveLongSchema,
});
export const FindBreedingByIdPathParamsSchema = excess(findBreedingByIdPathParamsRequiredPart);
export interface FindBreedingByIdPathParams extends t.TypeOf<typeof FindBreedingByIdPathParamsSchema> {}


const updateBreedingPathParamsRequiredPart = t.interface({
  breedingId: PositiveLongSchema,
});
export const UpdateBreedingPathParamsSchema = excess(updateBreedingPathParamsRequiredPart);
export interface UpdateBreedingPathParams extends t.TypeOf<typeof UpdateBreedingPathParamsSchema> {}


const findAllBullsQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllBullsQueryParamsSchema = excess(findAllBullsQueryParamsRequiredPart);
export interface FindAllBullsQueryParams extends t.TypeOf<typeof FindAllBullsQueryParamsSchema> {}


const getCsvWithBullDaughtersPathParamsRequiredPart = t.interface({
  bullId: PositiveLongSchema,
});
export const GetCsvWithBullDaughtersPathParamsSchema = excess(getCsvWithBullDaughtersPathParamsRequiredPart);
export interface GetCsvWithBullDaughtersPathParams extends t.TypeOf<typeof GetCsvWithBullDaughtersPathParamsSchema> {}


const updateBullPathParamsRequiredPart = t.interface({
  bullId: PositiveLongSchema,
});
export const UpdateBullPathParamsSchema = excess(updateBullPathParamsRequiredPart);
export interface UpdateBullPathParams extends t.TypeOf<typeof UpdateBullPathParamsSchema> {}


const getByTemplateTypePathParamsRequiredPart = t.interface({
  templateType: EmailTemplateTypeSchema,
});
export const GetByTemplateTypePathParamsSchema = excess(getByTemplateTypePathParamsRequiredPart);
export interface GetByTemplateTypePathParams extends t.TypeOf<typeof GetByTemplateTypePathParamsSchema> {}


const findAllEmailsAuditsQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllEmailsAuditsQueryParamsSchema = excess(findAllEmailsAuditsQueryParamsRequiredPart);
export interface FindAllEmailsAuditsQueryParams extends t.TypeOf<typeof FindAllEmailsAuditsQueryParamsSchema> {}


const findAllInvoicingDocumentsQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllInvoicingDocumentsQueryParamsSchema = excess(findAllInvoicingDocumentsQueryParamsRequiredPart);
export interface FindAllInvoicingDocumentsQueryParams extends t.TypeOf<typeof FindAllInvoicingDocumentsQueryParamsSchema> {}


const getCsvWithMarkedBullDaughtersPathParamsRequiredPart = t.interface({
  bullId: PositiveLongSchema,
});
export const GetCsvWithMarkedBullDaughtersPathParamsSchema = excess(getCsvWithMarkedBullDaughtersPathParamsRequiredPart);
export interface GetCsvWithMarkedBullDaughtersPathParams extends t.TypeOf<typeof GetCsvWithMarkedBullDaughtersPathParamsSchema> {}


const getInvoicingDocumentsForBullPathParamsRequiredPart = t.interface({
  bullId: PositiveLongSchema,
});
export const GetInvoicingDocumentsForBullPathParamsSchema = excess(getInvoicingDocumentsForBullPathParamsRequiredPart);
export interface GetInvoicingDocumentsForBullPathParams extends t.TypeOf<typeof GetInvoicingDocumentsForBullPathParamsSchema> {}


const exportAllApprovedQueryParamsRequiredPart = t.interface({
  sort: t.union([t.array(t.string), t.null]),
});
export const ExportAllApprovedQueryParamsSchema = excess(exportAllApprovedQueryParamsRequiredPart);
export interface ExportAllApprovedQueryParams extends t.TypeOf<typeof ExportAllApprovedQueryParamsSchema> {}


const findAllApprovedQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllApprovedQueryParamsSchema = excess(findAllApprovedQueryParamsRequiredPart);
export interface FindAllApprovedQueryParams extends t.TypeOf<typeof FindAllApprovedQueryParamsSchema> {}


const findAllApprovedByAnimalQueryParamsRequiredPart = t.interface({
  earTag: EarTagWithoutCountrySchema,
});
export const FindAllApprovedByAnimalQueryParamsSchema = excess(findAllApprovedByAnimalQueryParamsRequiredPart);
export interface FindAllApprovedByAnimalQueryParams extends t.TypeOf<typeof FindAllApprovedByAnimalQueryParamsSchema> {}


const getMeasurementByIdPathParamsRequiredPart = t.interface({
  measurementId: PositiveLongSchema,
});
export const GetMeasurementByIdPathParamsSchema = excess(getMeasurementByIdPathParamsRequiredPart);
export interface GetMeasurementByIdPathParams extends t.TypeOf<typeof GetMeasurementByIdPathParamsSchema> {}


const updateApprovedMeasurementByIdPathParamsRequiredPart = t.interface({
  measurementId: PositiveLongSchema,
});
export const UpdateApprovedMeasurementByIdPathParamsSchema = excess(updateApprovedMeasurementByIdPathParamsRequiredPart);
export interface UpdateApprovedMeasurementByIdPathParams extends t.TypeOf<typeof UpdateApprovedMeasurementByIdPathParamsSchema> {}


const exportDeadAnimalsQueryParamsRequiredPart = t.interface({
  yearMonth: YearMonthSchema,
  userIds: t.union([t.array(PositiveLongSchema), t.null]),
});
export const ExportDeadAnimalsQueryParamsSchema = excess(exportDeadAnimalsQueryParamsRequiredPart);
export interface ExportDeadAnimalsQueryParams extends t.TypeOf<typeof ExportDeadAnimalsQueryParamsSchema> {}


const exportMeasurementRepairsQueryParamsRequiredPart = t.interface({
  yearMonth: YearMonthSchema,
  userIds: t.union([t.array(PositiveLongSchema), t.null]),
});
export const ExportMeasurementRepairsQueryParamsSchema = excess(exportMeasurementRepairsQueryParamsRequiredPart);
export interface ExportMeasurementRepairsQueryParams extends t.TypeOf<typeof ExportMeasurementRepairsQueryParamsSchema> {}


const exportMeasurementsQueryParamsRequiredPart = t.interface({
  yearMonth: YearMonthSchema,
  userIds: t.union([t.array(PositiveLongSchema), t.null]),
  testing: t.union([t.boolean, t.null]),
});
export const ExportMeasurementsQueryParamsSchema = excess(exportMeasurementsQueryParamsRequiredPart);
export interface ExportMeasurementsQueryParams extends t.TypeOf<typeof ExportMeasurementsQueryParamsSchema> {}


const exportRawMeasurementsQueryParamsRequiredPart = t.interface({
  yearMonth: YearMonthSchema,
  userIds: t.union([t.array(PositiveLongSchema), t.null]),
});
export const ExportRawMeasurementsQueryParamsSchema = excess(exportRawMeasurementsQueryParamsRequiredPart);
export interface ExportRawMeasurementsQueryParams extends t.TypeOf<typeof ExportRawMeasurementsQueryParamsSchema> {}


const downloadMobileAppPathParamsRequiredPart = t.interface({
  name: MobileAppNameSchema,
});
export const DownloadMobileAppPathParamsSchema = excess(downloadMobileAppPathParamsRequiredPart);
export interface DownloadMobileAppPathParams extends t.TypeOf<typeof DownloadMobileAppPathParamsSchema> {}


const findAllAnimalsForPlanningQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllAnimalsForPlanningQueryParamsSchema = excess(findAllAnimalsForPlanningQueryParamsRequiredPart);
export interface FindAllAnimalsForPlanningQueryParams extends t.TypeOf<typeof FindAllAnimalsForPlanningQueryParamsSchema> {}


const findAllAnimalsForPlanningPathParamsRequiredPart = t.interface({
  breedingId: PositiveLongSchema,
});
export const FindAllAnimalsForPlanningPathParamsSchema = excess(findAllAnimalsForPlanningPathParamsRequiredPart);
export interface FindAllAnimalsForPlanningPathParams extends t.TypeOf<typeof FindAllAnimalsForPlanningPathParamsSchema> {}


const findAllBreedingForPlanningQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllBreedingForPlanningQueryParamsSchema = excess(findAllBreedingForPlanningQueryParamsRequiredPart);
export interface FindAllBreedingForPlanningQueryParams extends t.TypeOf<typeof FindAllBreedingForPlanningQueryParamsSchema> {}


const findAllDaughtersQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllDaughtersQueryParamsSchema = excess(findAllDaughtersQueryParamsRequiredPart);
export interface FindAllDaughtersQueryParams extends t.TypeOf<typeof FindAllDaughtersQueryParamsSchema> {}


const findAllDaughtersPathParamsRequiredPart = t.interface({
  bullId: PositiveLongSchema,
});
export const FindAllDaughtersPathParamsSchema = excess(findAllDaughtersPathParamsRequiredPart);
export interface FindAllDaughtersPathParams extends t.TypeOf<typeof FindAllDaughtersPathParamsSchema> {}


const findAllDaughtersForInvoicingDocumentsQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllDaughtersForInvoicingDocumentsQueryParamsSchema = excess(findAllDaughtersForInvoicingDocumentsQueryParamsRequiredPart);
export interface FindAllDaughtersForInvoicingDocumentsQueryParams extends t.TypeOf<typeof FindAllDaughtersForInvoicingDocumentsQueryParamsSchema> {}


const findAllDaughtersForInvoicingDocumentsPathParamsRequiredPart = t.interface({
  bullId: PositiveLongSchema,
});
export const FindAllDaughtersForInvoicingDocumentsPathParamsSchema = excess(findAllDaughtersForInvoicingDocumentsPathParamsRequiredPart);
export interface FindAllDaughtersForInvoicingDocumentsPathParams extends t.TypeOf<typeof FindAllDaughtersForInvoicingDocumentsPathParamsSchema> {}


const addAnimalsToPlanQueryParamsRequiredPart = t.interface({
  animalIds: t.array(PositiveLongSchema),
});
export const AddAnimalsToPlanQueryParamsSchema = excess(addAnimalsToPlanQueryParamsRequiredPart);
export interface AddAnimalsToPlanQueryParams extends t.TypeOf<typeof AddAnimalsToPlanQueryParamsSchema> {}


const exportPlanToPdfForBreederPathParamsRequiredPart = t.interface({
  breederId: PositiveLongSchema,
});
export const ExportPlanToPdfForBreederPathParamsSchema = excess(exportPlanToPdfForBreederPathParamsRequiredPart);
export interface ExportPlanToPdfForBreederPathParams extends t.TypeOf<typeof ExportPlanToPdfForBreederPathParamsSchema> {}


const exportPlanToPdfForStablePathParamsRequiredPart = t.interface({
  stableId: PositiveLongSchema,
});
export const ExportPlanToPdfForStablePathParamsSchema = excess(exportPlanToPdfForStablePathParamsRequiredPart);
export interface ExportPlanToPdfForStablePathParams extends t.TypeOf<typeof ExportPlanToPdfForStablePathParamsSchema> {}


const removeAnimalsFromPlanQueryParamsRequiredPart = t.interface({
  animalIds: t.array(PositiveLongSchema),
});
export const RemoveAnimalsFromPlanQueryParamsSchema = excess(removeAnimalsFromPlanQueryParamsRequiredPart);
export interface RemoveAnimalsFromPlanQueryParams extends t.TypeOf<typeof RemoveAnimalsFromPlanQueryParamsSchema> {}


const sendPlanEmailForBreederPathParamsRequiredPart = t.interface({
  breederId: PositiveLongSchema,
});
export const SendPlanEmailForBreederPathParamsSchema = excess(sendPlanEmailForBreederPathParamsRequiredPart);
export interface SendPlanEmailForBreederPathParams extends t.TypeOf<typeof SendPlanEmailForBreederPathParamsSchema> {}


const sendPlanEmailForStablePathParamsRequiredPart = t.interface({
  stableId: PositiveLongSchema,
});
export const SendPlanEmailForStablePathParamsSchema = excess(sendPlanEmailForStablePathParamsRequiredPart);
export interface SendPlanEmailForStablePathParams extends t.TypeOf<typeof SendPlanEmailForStablePathParamsSchema> {}


const assignAnimalToRatingQueryParamsRequiredPart = t.interface({
  earTag: EarTagWithoutCountrySchema,
});
export const AssignAnimalToRatingQueryParamsSchema = excess(assignAnimalToRatingQueryParamsRequiredPart);
export interface AssignAnimalToRatingQueryParams extends t.TypeOf<typeof AssignAnimalToRatingQueryParamsSchema> {}


const assignAnimalToRatingPathParamsRequiredPart = t.interface({
  ratingId: PositiveLongSchema,
});
export const AssignAnimalToRatingPathParamsSchema = excess(assignAnimalToRatingPathParamsRequiredPart);
export interface AssignAnimalToRatingPathParams extends t.TypeOf<typeof AssignAnimalToRatingPathParamsSchema> {}


const assignBreedingToRatingQueryParamsRequiredPart = t.interface({
  breedingId: PositiveLongSchema,
});
export const AssignBreedingToRatingQueryParamsSchema = excess(assignBreedingToRatingQueryParamsRequiredPart);
export interface AssignBreedingToRatingQueryParams extends t.TypeOf<typeof AssignBreedingToRatingQueryParamsSchema> {}


const assignBreedingToRatingPathParamsRequiredPart = t.interface({
  ratingId: PositiveLongSchema,
});
export const AssignBreedingToRatingPathParamsSchema = excess(assignBreedingToRatingPathParamsRequiredPart);
export interface AssignBreedingToRatingPathParams extends t.TypeOf<typeof AssignBreedingToRatingPathParamsSchema> {}


const deleteRatingPathParamsRequiredPart = t.interface({
  ratingId: PositiveLongSchema,
});
export const DeleteRatingPathParamsSchema = excess(deleteRatingPathParamsRequiredPart);
export interface DeleteRatingPathParams extends t.TypeOf<typeof DeleteRatingPathParamsSchema> {}


const findAllRatingsToApproveQueryParamsRequiredPart = t.interface({
  page: t.union([t.Integer, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([t.array(t.string), t.null]),
});
export const FindAllRatingsToApproveQueryParamsSchema = excess(findAllRatingsToApproveQueryParamsRequiredPart);
export interface FindAllRatingsToApproveQueryParams extends t.TypeOf<typeof FindAllRatingsToApproveQueryParamsSchema> {}


const mortifyAnimalsRatingPathParamsRequiredPart = t.interface({
  ratingId: PositiveLongSchema,
});
export const MortifyAnimalsRatingPathParamsSchema = excess(mortifyAnimalsRatingPathParamsRequiredPart);
export interface MortifyAnimalsRatingPathParams extends t.TypeOf<typeof MortifyAnimalsRatingPathParamsSchema> {}


const updateRatingPathParamsRequiredPart = t.interface({
  ratingId: PositiveLongSchema,
});
export const UpdateRatingPathParamsSchema = excess(updateRatingPathParamsRequiredPart);
export interface UpdateRatingPathParams extends t.TypeOf<typeof UpdateRatingPathParamsSchema> {}


const findAllEmailsByStableIdsQueryParamsRequiredPart = t.interface({
  stableIds: t.array(t.Integer),
});
export const FindAllEmailsByStableIdsQueryParamsSchema = excess(findAllEmailsByStableIdsQueryParamsRequiredPart);
export interface FindAllEmailsByStableIdsQueryParams extends t.TypeOf<typeof FindAllEmailsByStableIdsQueryParamsSchema> {}


const updateEmailsOfStablePathParamsRequiredPart = t.interface({
  stableId: t.Integer,
});
export const UpdateEmailsOfStablePathParamsSchema = excess(updateEmailsOfStablePathParamsRequiredPart);
export interface UpdateEmailsOfStablePathParams extends t.TypeOf<typeof UpdateEmailsOfStablePathParamsSchema> {}


const findBreedingsByStablePathParamsRequiredPart = t.interface({
  stableId: PositiveLongSchema,
});
export const FindBreedingsByStablePathParamsSchema = excess(findBreedingsByStablePathParamsRequiredPart);
export interface FindBreedingsByStablePathParams extends t.TypeOf<typeof FindBreedingsByStablePathParamsSchema> {}


const findStableByIdPathParamsRequiredPart = t.interface({
  stableId: PositiveLongSchema,
});
export const FindStableByIdPathParamsSchema = excess(findStableByIdPathParamsRequiredPart);
export interface FindStableByIdPathParams extends t.TypeOf<typeof FindStableByIdPathParamsSchema> {}


const updateStablePathParamsRequiredPart = t.interface({
  stableId: PositiveLongSchema,
});
export const UpdateStablePathParamsSchema = excess(updateStablePathParamsRequiredPart);
export interface UpdateStablePathParams extends t.TypeOf<typeof UpdateStablePathParamsSchema> {}



export class Api {
  // Creates an account for a user with the given details. (/account/sign-up)
  public static async createAccount(data: CreateUser): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/account/sign-up`,
      method: 'post',
      data,
      requestSchema: CreateUserSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 201,
    });
  }

  // Gets information about the application (version, deploy date etc.). (/admin/app-info)
  public static async getApplicationInfo(): Promise<Response<AppInfo>> {
    return apiRequester.makeRequest({
      url: `/admin/app-info`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: AppInfoSchema,
      expectedStatus: 200,
    });
  }

  // Finds all measurements for the animal that match the search criteria and are either approved or measured by the logged-in user. (/animals/{animalId}/measurements/find)
  public static async findAllMeasurementsForAnimal(data: TableFilter[], params: FindAllMeasurementsForAnimalQueryParams, pathParams: FindAllMeasurementsForAnimalPathParams): Promise<Response<PageMeasurementAnimal>> {
    validateSchema(FindAllMeasurementsForAnimalQueryParamsSchema, 'FE')(params);
    validateSchema(FindAllMeasurementsForAnimalPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/animals/${pathParams.animalId}/measurements/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PageMeasurementAnimalSchema,
      expectedStatus: 200,
    });
  }

  // Searches for animals with breedings by the given ear tag value. (/animals/search)
  public static async searchAnimalsWithBreedings(params: SearchAnimalsWithBreedingsQueryParams): Promise<Response<AnimalWithBreedingSearch[]>> {
    validateSchema(SearchAnimalsWithBreedingsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/animals/search`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: t.array(AnimalWithBreedingSearchSchema),
      expectedStatus: 200,
    });
  }

  // Searches for ear tags that contain the given value. (/animals/search/ear-tags)
  public static async searchEarTags(params: SearchEarTagsQueryParams): Promise<Response<EarTags>> {
    validateSchema(SearchEarTagsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/animals/search/ear-tags`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: EarTagsSchema,
      expectedStatus: 200,
    });
  }

  // Set animals with given IDs as 'dead'. (/animals/dead)
  public static async setAnimalsAsDead(data: IdSet): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/animals/dead`,
      method: 'post',
      data,
      requestSchema: IdSetSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Generates an Excel report with breeder suites. (/breeder-suites/export)
  public static async exportAllBreederSuites(params: ExportAllBreederSuitesQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportAllBreederSuitesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/breeder-suites/export`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Finds all breeder suites that match the search criteria. (/breeder-suites/find)
  public static async findAllBreederSuites(data: TableFilter[], params: FindAllBreederSuitesQueryParams): Promise<Response<PageBreederSuite>> {
    validateSchema(FindAllBreederSuitesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/breeder-suites/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PageBreederSuiteSchema,
      expectedStatus: 200,
    });
  }

  // Sends an e-mail with an Excel report of breeder suites to the given e-mail addresses. (/breeder-suites/email/stables/merged)
  public static async sendEmailToBreedersWithMergedStables(data: BreederSuiteEmail, params: SendEmailToBreedersWithMergedStablesQueryParams): Promise<Response<EmptyString>> {
    validateSchema(SendEmailToBreedersWithMergedStablesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/breeder-suites/email/stables/merged`,
      method: 'post',
      params,
      data,
      requestSchema: BreederSuiteEmailSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Sends an e-mail with an Excel report of breeder suites per each stable to the given e-mail addresses. (/breeder-suites/email/stables/separate)
  public static async sendEmailToBreedersWithSeparateStables(data: BreederSuiteEmail, params: SendEmailToBreedersWithSeparateStablesQueryParams): Promise<Response<EmptyString>> {
    validateSchema(SendEmailToBreedersWithSeparateStablesQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/breeder-suites/email/stables/separate`,
      method: 'post',
      params,
      data,
      requestSchema: BreederSuiteEmailSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all breeders that match the search criteria. (/breeders/find)
  public static async findAllBreeders(data: TableFilter[], params: FindAllBreedersQueryParams): Promise<Response<PageBreeder>> {
    validateSchema(FindAllBreedersQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/breeders/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PageBreederSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about the breeder. (/breeders/{breederId})
  public static async findBreederById(pathParams: FindBreederByIdPathParams): Promise<Response<Breeder>> {
    validateSchema(FindBreederByIdPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/breeders/${pathParams.breederId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: BreederSchema,
      expectedStatus: 200,
    });
  }

  // Finds all stables that belong to the breeder. (/breeders/{breederId}/stables)
  public static async getStablesByBreeder(pathParams: GetStablesByBreederPathParams): Promise<Response<Stable[]>> {
    validateSchema(GetStablesByBreederPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/breeders/${pathParams.breederId}/stables`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(StableSchema),
      expectedStatus: 200,
    });
  }

  // Updates the breeder with the given details. (/breeders/{breederId})
  public static async updateBreeder(data: UpdateBreeder, pathParams: UpdateBreederPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateBreederPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/breeders/${pathParams.breederId}`,
      method: 'put',
      data,
      requestSchema: UpdateBreederSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds breeding details. (/breedings/{breedingId})
  public static async findBreedingById(pathParams: FindBreedingByIdPathParams): Promise<Response<BreedingDetails>> {
    validateSchema(FindBreedingByIdPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/breedings/${pathParams.breedingId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: BreedingDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Finds breeding numbers of breedings assigned to the logged-in user. (/breedings/breeding-numbers)
  public static async findBreedingNumbers(): Promise<Response<BasicBreeding[]>> {
    return apiRequester.makeRequest({
      url: `/breedings/breeding-numbers`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(BasicBreedingSchema),
      expectedStatus: 200,
    });
  }

  // Updates breeding with the given details. (/breedings/{breedingId})
  public static async updateBreeding(data: UpdateBreeding, pathParams: UpdateBreedingPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateBreedingPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/breedings/${pathParams.breedingId}`,
      method: 'put',
      data,
      requestSchema: UpdateBreedingSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds a page of bulls that match the search criteria. (/bulls/find)
  public static async findAllBulls(data: TableFilter[], params: FindAllBullsQueryParams): Promise<Response<PageBullView>> {
    validateSchema(FindAllBullsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/bulls/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PageBullViewSchema,
      expectedStatus: 200,
    });
  }

  // Exports the measured daughters of the bulls to CSV. (/bulls/{bullId}/animals/export)
  public static async getCsvWithBullDaughters(pathParams: GetCsvWithBullDaughtersPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetCsvWithBullDaughtersPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/bulls/${pathParams.bullId}/animals/export`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Updates the bull with given details. (/bulls/{bullId})
  public static async updateBull(data: UpdateBull, pathParams: UpdateBullPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateBullPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/bulls/${pathParams.bullId}`,
      method: 'put',
      data,
      requestSchema: UpdateBullSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all districts (/districts)
  public static async findAllDistricts(): Promise<Response<District[]>> {
    return apiRequester.makeRequest({
      url: `/districts`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(DistrictSchema),
      expectedStatus: 200,
    });
  }

  // Finds all districts assigned to the logged-in user. (/districts/active)
  public static async findAllDistrictsByUser(): Promise<Response<District[]>> {
    return apiRequester.makeRequest({
      url: `/districts/active`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(DistrictSchema),
      expectedStatus: 200,
    });
  }

  // Updates the districts of the logged-in user. (/districts/users)
  public static async updateDistricts(data: UpdateDistricts): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/districts/users`,
      method: 'put',
      data,
      requestSchema: UpdateDistrictsSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds e-mail template of the given type. (/email-templates/{templateType})
  public static async getByTemplateType(pathParams: GetByTemplateTypePathParams): Promise<Response<EmailTemplate>> {
    validateSchema(GetByTemplateTypePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/email-templates/${pathParams.templateType}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: EmailTemplateSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of e-mails audits that match the search criteria. (/emails-audit/find)
  public static async findAllEmailsAudits(data: TableFilter[], params: FindAllEmailsAuditsQueryParams): Promise<Response<PageEmailAudit>> {
    validateSchema(FindAllEmailsAuditsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/emails-audit/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PageEmailAuditSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about the plan of the logged-in user. (/mobile/fleckvieh/plans/active)
  public static async getMobileFleckviehPlan(): Promise<Response<LegacyPlan>> {
    return apiRequester.makeRequest({
      url: `/mobile/fleckvieh/plans/active`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: LegacyPlanSchema,
      expectedStatus: 200,
    });
  }

  // Uploads all measurements to the server and logs them to the application log. (/mobile/fleckvieh/all-measurements)
  public static async logFleckviehMeasurements(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/mobile/fleckvieh/all-measurements`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Uploads the measurements to the server and saves them to database. (/mobile/fleckvieh/measurements)
  public static async uploadFleckviehMeasurements(data: FleckviehLegacyMeasurement[]): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/mobile/fleckvieh/measurements`,
      method: 'post',
      data,
      requestSchema: t.array(FleckviehLegacyMeasurementSchema),
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds details about the plan of the logged-in user. (/mobile/holstein/plans/active)
  public static async getMobileHolsteinPlan(): Promise<Response<LegacyPlan>> {
    return apiRequester.makeRequest({
      url: `/mobile/holstein/plans/active`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: LegacyPlanSchema,
      expectedStatus: 200,
    });
  }

  // Uploads all measurements to the server and logs them to the application log. (/mobile/holstein/all-measurements)
  public static async logHolsteinMeasurements(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/mobile/holstein/all-measurements`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Uploads the measurements to the server and saves them to database. (/mobile/holstein/measurements)
  public static async uploadHolsteinMeasurements(data: HolsteinLegacyMeasurement[]): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/mobile/holstein/measurements`,
      method: 'post',
      data,
      requestSchema: t.array(HolsteinLegacyMeasurementSchema),
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Creates invoicing documents for the bulls with the provided IDs. (/invoicing-documents/bulls)
  public static async createInvoicingDocuments(data: PositiveLong[]): Promise<Response<ArrayBuffer>> {
    return apiRequester.makeRequest({
      url: `/invoicing-documents/bulls`,
      method: 'post',
      data,
      requestSchema: t.array(PositiveLongSchema),
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of invoicing documents that match the search criteria. (/invoicing-documents/bulls/find)
  public static async findAllInvoicingDocuments(data: TableFilter[], params: FindAllInvoicingDocumentsQueryParams): Promise<Response<PageInvoicingDocuments>> {
    validateSchema(FindAllInvoicingDocumentsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/invoicing-documents/bulls/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PageInvoicingDocumentsSchema,
      expectedStatus: 200,
    });
  }

  // Exports the marked, measured daughters of the bull to CSV. (/invoicing-documents/bulls/{bullId}/animals/export)
  public static async getCsvWithMarkedBullDaughters(pathParams: GetCsvWithMarkedBullDaughtersPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetCsvWithMarkedBullDaughtersPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/invoicing-documents/bulls/${pathParams.bullId}/animals/export`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Gets invoicing documents for the bull with the provided ID. (/invoicing-documents/bulls/{bullId})
  public static async getInvoicingDocumentsForBull(pathParams: GetInvoicingDocumentsForBullPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(GetInvoicingDocumentsForBullPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/invoicing-documents/bulls/${pathParams.bullId}`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Authenticates the user and creates the jwt token. (/mobile/login)
  public static async jwtLogin(data: UserLoginDetails): Promise<Response<JwtDetails>> {
    return apiRequester.makeRequest({
      url: `/mobile/login`,
      method: 'post',
      data,
      requestSchema: UserLoginDetailsSchema,
      responseSchema: JwtDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Finds all imported KKUT files. (/imports)
  public static async findAllKkutFiles(): Promise<Response<Import[]>> {
    return apiRequester.makeRequest({
      url: `/imports`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(ImportSchema),
      expectedStatus: 200,
    });
  }

  // Imports the KKUT file. (/imports)
  public static async importKkut(data: File): Promise<Response<BasicImport>> {
    return apiRequester.makeRequest({
      url: `/imports`,
      method: 'post',
      data: fileToFormData(data),
      requestSchema: FormDataSchema,
      responseSchema: BasicImportSchema,
      expectedStatus: 200,
    });
  }

  // Logs out the logged-in user. (/logout)
  public static async logout(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/logout`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Export all approved measurements to an Excel file. (/measurements/approved/export)
  public static async exportAllApproved(data: TableFilter[], params: ExportAllApprovedQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportAllApprovedQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/measurements/approved/export`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
      timeout: 180000,
    });
  }

  // Find all approved measurements. (/measurements/approved/find)
  public static async findAllApproved(data: TableFilter[], params: FindAllApprovedQueryParams): Promise<Response<PageMeasurement>> {
    validateSchema(FindAllApprovedQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/measurements/approved/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PageMeasurementSchema,
      expectedStatus: 200,
    });
  }

  // Finds all approved measurements of an animal. (/measurements/approved/search)
  public static async findAllApprovedByAnimal(params: FindAllApprovedByAnimalQueryParams): Promise<Response<MeasurementSearch[]>> {
    validateSchema(FindAllApprovedByAnimalQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/measurements/approved/search`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: t.array(MeasurementSearchSchema),
      expectedStatus: 200,
    });
  }

  // Finds all details about the approved measurement. (/measurements/approved/{measurementId})
  public static async getMeasurementById(pathParams: GetMeasurementByIdPathParams): Promise<Response<MeasurementDetail>> {
    validateSchema(GetMeasurementByIdPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/measurements/approved/${pathParams.measurementId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: MeasurementDetailSchema,
      expectedStatus: 200,
    });
  }

  // Updates the (approved) measurement. (/measurements/approved/{measurementId})
  public static async updateApprovedMeasurementById(data: MeasurementUpdate, pathParams: UpdateApprovedMeasurementByIdPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateApprovedMeasurementByIdPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/measurements/approved/${pathParams.measurementId}`,
      method: 'put',
      data,
      requestSchema: MeasurementUpdateSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Exports dead animals that match the search criteria to a text file. (/animals/dead/export)
  public static async exportDeadAnimals(params: ExportDeadAnimalsQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportDeadAnimalsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/animals/dead/export`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports measurement repairs that match the search criteria. (/measurements/repairs/export)
  public static async exportMeasurementRepairs(params: ExportMeasurementRepairsQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportMeasurementRepairsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/measurements/repairs/export`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports all measurements that match the search criteria. (/measurements/export)
  public static async exportMeasurements(params: ExportMeasurementsQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportMeasurementsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/measurements/export`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports raw measurements that match the search criteria. (/measurements/raw/export)
  public static async exportRawMeasurements(params: ExportRawMeasurementsQueryParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportRawMeasurementsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/measurements/raw/export`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Downloads the mobile app with the given name from AWS. (/mobile-apps/{name})
  public static async downloadMobileApp(pathParams: DownloadMobileAppPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(DownloadMobileAppPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/mobile-apps/${pathParams.name}`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Finds names of the mobile apps available for download on AWS. (/mobile-apps)
  public static async getMobileApps(): Promise<Response<MobileApps>> {
    return apiRequester.makeRequest({
      url: `/mobile-apps`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: MobileAppsSchema,
      expectedStatus: 200,
    });
  }

  // Finds a page of animals that belong to the breeding and are eligible for planning. (/planning/breedings/{breedingId}/animals/find)
  public static async findAllAnimalsForPlanning(data: TableFilter[], params: FindAllAnimalsForPlanningQueryParams, pathParams: FindAllAnimalsForPlanningPathParams): Promise<Response<PagePlanningAnimal>> {
    validateSchema(FindAllAnimalsForPlanningQueryParamsSchema, 'FE')(params);
    validateSchema(FindAllAnimalsForPlanningPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/planning/breedings/${pathParams.breedingId}/animals/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagePlanningAnimalSchema,
      expectedStatus: 200,
    });
  }

  // Finds all breeding that contain animals that may be added to a plan. (/planning/breedings/find)
  public static async findAllBreedingForPlanning(data: TableFilter[], params: FindAllBreedingForPlanningQueryParams): Promise<Response<PagePlanningBreeding>> {
    validateSchema(FindAllBreedingForPlanningQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/planning/breedings/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagePlanningBreedingSchema,
      expectedStatus: 200,
    });
  }

  // Finds all daughters of the bull that are eligible for planning. (/planning/bulls/{bullId}/animals)
  public static async findAllDaughters(data: TableFilter[], params: FindAllDaughtersQueryParams, pathParams: FindAllDaughtersPathParams): Promise<Response<PagePlanningDaughterWithMark>> {
    validateSchema(FindAllDaughtersQueryParamsSchema, 'FE')(params);
    validateSchema(FindAllDaughtersPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/planning/bulls/${pathParams.bullId}/animals`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagePlanningDaughterWithMarkSchema,
      expectedStatus: 200,
    });
  }

  // Finds all marked daughters of the bull that are eligible for planning. (/planning/invoicing-documents/bulls/{bullId}/animals)
  public static async findAllDaughtersForInvoicingDocuments(data: TableFilter[], params: FindAllDaughtersForInvoicingDocumentsQueryParams, pathParams: FindAllDaughtersForInvoicingDocumentsPathParams): Promise<Response<PagePlanningDaughter>> {
    validateSchema(FindAllDaughtersForInvoicingDocumentsQueryParamsSchema, 'FE')(params);
    validateSchema(FindAllDaughtersForInvoicingDocumentsPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/planning/invoicing-documents/bulls/${pathParams.bullId}/animals`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PagePlanningDaughterSchema,
      expectedStatus: 200,
    });
  }

  // Adds animals to the user's plan. If the user does not have a plan yet, creates a new one. (/plans)
  public static async addAnimalsToPlan(params: AddAnimalsToPlanQueryParams): Promise<Response<EmptyString>> {
    validateSchema(AddAnimalsToPlanQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/plans`,
      method: 'put',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Exports the logged-in user's plan to PDF. (/plans/export)
  public static async exportPlanToPdf(): Promise<Response<ArrayBuffer>> {
    return apiRequester.makeRequest({
      url: `/plans/export`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports the planned animals for the breeder in the logged-in user's plan. (/plans/breeders/{breederId}/export)
  public static async exportPlanToPdfForBreeder(pathParams: ExportPlanToPdfForBreederPathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportPlanToPdfForBreederPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/plans/breeders/${pathParams.breederId}/export`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Exports the planned animals in the stable. (/plans/stables/{stableId}/export)
  public static async exportPlanToPdfForStable(pathParams: ExportPlanToPdfForStablePathParams): Promise<Response<ArrayBuffer>> {
    validateSchema(ExportPlanToPdfForStablePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/plans/stables/${pathParams.stableId}/export`,
      method: 'get',
      requestSchema: t.void,
      responseType: 'arraybuffer',
      responseSchema: ArrayBufferSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about the plan of the logged-in user. (/plans)
  public static async getPlanForLoggedUser(): Promise<Response<Plan>> {
    return apiRequester.makeRequest({
      url: `/plans`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: PlanSchema,
      expectedStatus: 200,
    });
  }

  // Finds statistics about the plan(s). (/plans/statistics)
  public static async getPlanStatistics(): Promise<Response<PlanStatistics[]>> {
    return apiRequester.makeRequest({
      url: `/plans/statistics`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(PlanStatisticsSchema),
      expectedStatus: 200,
    });
  }

  // Removes animals from the user's plan. (/plans)
  public static async removeAnimalsFromPlan(params: RemoveAnimalsFromPlanQueryParams): Promise<Response<EmptyString>> {
    validateSchema(RemoveAnimalsFromPlanQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/plans`,
      method: 'delete',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Sends an e-mail about the planned animals in the stables of the breeder to the breeder. (/plans/breeders/{breederId}/email)
  public static async sendPlanEmailForBreeder(data: PlanEmail, pathParams: SendPlanEmailForBreederPathParams): Promise<Response<EmptyString>> {
    validateSchema(SendPlanEmailForBreederPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/plans/breeders/${pathParams.breederId}/email`,
      method: 'post',
      data,
      requestSchema: PlanEmailSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Sends an e-mail about the planned animals in the stable to the breeder. (/plans/stables/{stableId}/email)
  public static async sendPlanEmailForStable(data: PlanEmail, pathParams: SendPlanEmailForStablePathParams): Promise<Response<EmptyString>> {
    validateSchema(SendPlanEmailForStablePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/plans/stables/${pathParams.stableId}/email`,
      method: 'post',
      data,
      requestSchema: PlanEmailSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Approves all the ratings of the logged-in user that have not been approved yet. (/ratings/approve)
  public static async approveRatings(): Promise<Response<EmptyString>> {
    return apiRequester.makeRequest({
      url: `/ratings/approve`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Assigns given animal to given rating. (/ratings/{ratingId}/assign-animal)
  public static async assignAnimalToRating(params: AssignAnimalToRatingQueryParams, pathParams: AssignAnimalToRatingPathParams): Promise<Response<EmptyString>> {
    validateSchema(AssignAnimalToRatingQueryParamsSchema, 'FE')(params);
    validateSchema(AssignAnimalToRatingPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/ratings/${pathParams.ratingId}/assign-animal`,
      method: 'post',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 200,
    });
  }

  // Assigns existing breeding to the rating with unknown breeding. (/ratings/{ratingId}/unknown-breedings)
  public static async assignBreedingToRating(params: AssignBreedingToRatingQueryParams, pathParams: AssignBreedingToRatingPathParams): Promise<Response<EmptyString>> {
    validateSchema(AssignBreedingToRatingQueryParamsSchema, 'FE')(params);
    validateSchema(AssignBreedingToRatingPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/ratings/${pathParams.ratingId}/unknown-breedings`,
      method: 'put',
      params,
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Deletes a rating that has not been approved yet. (/ratings/{ratingId})
  public static async deleteRating(pathParams: DeleteRatingPathParams): Promise<Response<EmptyString>> {
    validateSchema(DeleteRatingPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/ratings/${pathParams.ratingId}`,
      method: 'delete',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds a page of ratings that are not yet approved and match the search criteria. (/ratings/find)
  public static async findAllRatingsToApprove(data: TableFilter[], params: FindAllRatingsToApproveQueryParams): Promise<Response<PageRatingToApprove>> {
    validateSchema(FindAllRatingsToApproveQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/ratings/find`,
      method: 'post',
      params,
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: PageRatingToApproveSchema,
      expectedStatus: 200,
    });
  }

  // Finds ratings with unknown breedings rated by the logged-in user. (/ratings/unknown-breedings)
  public static async findRatingsWithUnknownBreedings(): Promise<Response<RatingWithUnknownBreeding[]>> {
    return apiRequester.makeRequest({
      url: `/ratings/unknown-breedings`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(RatingWithUnknownBreedingSchema),
      expectedStatus: 200,
    });
  }

  // Finds statistics about rating of the currently logged-in user that are not yet approved and match the search criteria. (/ratings/find/statistics)
  public static async getRatingToApproveStatistics(data: TableFilter[]): Promise<Response<RatingToApproveStatistics>> {
    return apiRequester.makeRequest({
      url: `/ratings/find/statistics`,
      method: 'post',
      data,
      requestSchema: t.array(TableFilterSchema),
      responseSchema: RatingToApproveStatisticsSchema,
      expectedStatus: 200,
    });
  }

  // Mortifies the rating and measurement of an animal. (/ratings/{ratingId}/mortify)
  public static async mortifyAnimalsRating(pathParams: MortifyAnimalsRatingPathParams): Promise<Response<EmptyString>> {
    validateSchema(MortifyAnimalsRatingPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/ratings/${pathParams.ratingId}/mortify`,
      method: 'post',
      requestSchema: t.void,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Updates the rating with given details. (/ratings/{ratingId})
  public static async updateRating(data: RatingUpdate, pathParams: UpdateRatingPathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateRatingPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/ratings/${pathParams.ratingId}`,
      method: 'patch',
      data,
      requestSchema: RatingUpdateSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all alternative e-mails of the stables with given IDs. (/stables/emails)
  public static async findAllEmailsByStableIds(params: FindAllEmailsByStableIdsQueryParams): Promise<Response<StableEmail[]>> {
    validateSchema(FindAllEmailsByStableIdsQueryParamsSchema, 'FE')(params);
    return apiRequester.makeRequest({
      url: `/stables/emails`,
      method: 'get',
      params,
      requestSchema: t.void,
      responseSchema: t.array(StableEmailSchema),
      expectedStatus: 200,
    });
  }

  // Updates the alternative e-mails of the stable. (/stables/{stableId}/emails)
  public static async updateEmailsOfStable(data: StableEmail[], pathParams: UpdateEmailsOfStablePathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateEmailsOfStablePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/stables/${pathParams.stableId}/emails`,
      method: 'put',
      data,
      requestSchema: t.array(StableEmailSchema),
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds all stables with their breedings. (/stables)
  public static async findAllStablesWithBreedings(): Promise<Response<StableWithBreedings[]>> {
    return apiRequester.makeRequest({
      url: `/stables`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(StableWithBreedingsSchema),
      expectedStatus: 200,
    });
  }

  // Finds all breedings in the stable. (/stables/{stableId}/breedings)
  public static async findBreedingsByStable(pathParams: FindBreedingsByStablePathParams): Promise<Response<Breeding[]>> {
    validateSchema(FindBreedingsByStablePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/stables/${pathParams.stableId}/breedings`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(BreedingSchema),
      expectedStatus: 200,
    });
  }

  // Finds details about the stable. (/stables/{stableId})
  public static async findStableById(pathParams: FindStableByIdPathParams): Promise<Response<Stable>> {
    validateSchema(FindStableByIdPathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/stables/${pathParams.stableId}`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: StableSchema,
      expectedStatus: 200,
    });
  }

  // Updates the stable with given details. (/stables/{stableId})
  public static async updateStable(data: UpdateStable, pathParams: UpdateStablePathParams): Promise<Response<EmptyString>> {
    validateSchema(UpdateStablePathParamsSchema, 'FE')(pathParams);
    return apiRequester.makeRequest({
      url: `/stables/${pathParams.stableId}`,
      method: 'put',
      data,
      requestSchema: UpdateStableSchema,
      responseSchema: EmptyStringSchema,
      expectedStatus: 204,
    });
  }

  // Finds details about the logged-in user. (/user)
  public static async getUserDetails(): Promise<Response<UserDetails>> {
    return apiRequester.makeRequest({
      url: `/user`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: UserDetailsSchema,
      expectedStatus: 200,
    });
  }

  // Finds details about all users. (/users)
  public static async getUsers(): Promise<Response<UserDetails[]>> {
    return apiRequester.makeRequest({
      url: `/users`,
      method: 'get',
      requestSchema: t.void,
      responseSchema: t.array(UserDetailsSchema),
      expectedStatus: 200,
    });
  }

// custom endpoints

  public static async login(loginDetails: UserLoginDetails): Promise<Response<JwtDetails>> {
    return apiRequester.makeRequest({
      url: '/login',
      method: 'post',
      data: loginDetails,
      requestSchema: UserLoginDetailsSchema,
      responseSchema: JwtDetailsSchema,
      expectedStatus: 200,
    });
  }

}
