import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {DatabaseOfMeasuredState, initialDatabaseOfMeasuredState} from './state';

const databaseOfMeasuredStateO = O.optic<DatabaseOfMeasuredState>();

export const databaseOfMeasuredReducer = (
    state: DatabaseOfMeasuredState = initialDatabaseOfMeasuredState,
    action: AppAction,
): DatabaseOfMeasuredState => {
    switch (action.type) {
        case 'databaseOfMeasured/GET_MEASUREMENTS_SUCCESS': {
            const {measurementsPage} = action.payload;

            return O.set(databaseOfMeasuredStateO.prop('measurementsPage'))(measurementsPage)(state);
        }

        default:
            return state;
    }
};
