import {TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairFleckviehMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairFleckviehMeasurementFormValues, 'properties'>;

const OtherFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.Input
                name="udderPurity"
                type="text"
                label={tCommon('udderPurity')}
                isRequired
            />
            <Fields.NumberInput
                name="nervousness"
                label={tCommon('nervousness')}
                isRequired
            />
        </Fragment>
    );
};

export const OtherFormSection = memo(OtherFormSectionBase);
