import {putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {District} from 'api/gen/District';
import {UpdateDistricts} from 'api/gen/UpdateDistricts';
import {t, tCommon} from 'app/translations';
import {layoutAction} from 'common/layout';

import {districtAction, UpdateUserDistrictsAction} from '../action';

const prepareUpdateDistricts = (userDistricts: Array<District>): UpdateDistricts =>
    ({districtIds: userDistricts.map(d => d.id)});

function* execute({payload: {userDistricts}}: UpdateUserDistrictsAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('updateUserDistricts', true));

    const title = t('district')('districtsEdit');
    const response = yield* call(Api.updateDistricts, prepareUpdateDistricts(userDistricts));

    if (response.isSuccess) {
        yield* put(showSuccess(title, tCommon('changesSaved'), false, 'districts-changes'));
        yield* put(districtAction.loadDistricts());
    } else {
        yield putAll(showBeError(response, title));
    }

    yield* put(layoutAction.setItemLoading('updateUserDistricts', false));
}

export function* updateUserDistrictsSaga(): SagaIterator {
    yield takeLatestF('district/UPDATE_USER_DISTRICTS', execute);
}
