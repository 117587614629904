import {TableColumn} from '@fl/cmsch-fe-library';

import {PlanStatistics} from 'api/gen/PlanStatistics';
import {TFunction} from 'app/translations';

export interface PlanStatisticsTableType extends PlanStatistics {
    id: number;
}

const getUserColumn = (t: TFunction<'planning/adminPlanTable'>): TableColumn<PlanStatisticsTableType> => ({
    field: 'user',
    column: t('header.user'),
    type: 'string',
    disableFilter: true,
    width: 180,
});

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    t: TFunction<'planning/adminPlanTable'>,
    isAdmin: boolean,
): Array<TableColumn<PlanStatisticsTableType>> => [
    ...isAdmin ? [getUserColumn(t)] : [],
    {
        field: 'updated',
        column: t('header.updated'),
        type: 'date',
        dateFormat: 'date',
        tooltip: t('tooltip.updated'),
        disableFilter: true,
        width: 180,
    },
    {
        field: 'measured',
        column: t('header.measured'),
        type: 'number',
        tooltip: t('tooltip.measured'),
        disableFilter: true,
        width: 100,
    },
    {
        field: 'dead',
        column: t('header.dead'),
        type: 'number',
        tooltip: t('tooltip.dead'),
        disableFilter: true,
        width: 100,
    },
    {
        field: 'total',
        column: t('header.total'),
        type: 'number',
        tooltip: t('tooltip.total'),
        disableFilter: true,
        width: 100,
    },
];
