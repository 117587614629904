// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LibraryInfo, LibraryInfoSchema } from 'api/gen/LibraryInfo';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SemVer, SemVerSchema } from 'api/gen/SemVer';

const requiredPart = t.interface({
  appVersion: SemVerSchema,
  buildTs: DateTimeSchema,
  dbVersion: t.string,
  libraries: t.union([t.array(LibraryInfoSchema), t.null]),
});

export const AppInfoSchema = excess(requiredPart);

export interface AppInfo extends t.TypeOf<typeof AppInfoSchema> {}
