import {putAll, showBeError, downloadFileFromResponse, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {DownloadMobileAppAction} from '../action';

function* execute({payload: {name}}: DownloadMobileAppAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('downloadMobileApp', true));

    const response = yield* call(Api.downloadMobileApp, {name});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('admin/AppInfo')('downloadMobileApp')));
    } else {
        yield* call(downloadFileFromResponse, response, name);
    }

    yield* put(layoutAction.setItemLoading('downloadMobileApp', false));
}

export function* downloadMobileAppSaga(): SagaIterator {
    yield takeLatestF('admin/DOWNLOAD_MOBILE_APP', execute);
}
