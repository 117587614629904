// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The last name of a user.
// Example: "Doe"

export const lastNameMinLength = 1;
export const lastNameMaxLength = 255;

export const LastNameSchema = t.refinement(t.string, n => n.length >= lastNameMinLength && n.length <= lastNameMaxLength, 'LastName');

export type LastName = string;
