import {none, Opt} from 'ts-opt';

import {AppInfo} from 'api/gen/AppInfo';
import {MobileApps} from 'api/gen/MobileApps';

export interface AdminState {
    appInfo: Opt<AppInfo>;
    mobileApps: Opt<MobileApps>;
}

export const initialAdminState: AdminState = {
    appInfo: none,
    mobileApps: none,
};
