import React, {Fragment, FC, memo, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {useOurTranslation} from 'app/translations';
import {simpleUserSelector} from 'app/user';
import {UploadButton} from 'common/buttons';
import {Content, layoutAction, PageHeader, simpleLayoutSelector} from 'common/layout';

import {ImportTable, ImportKkutResultModal} from '../components';
import {importKkutAction} from '../model/action';
import {simpleImportKkutSelector} from '../model/selector';

const ListBase: FC = _ => {
    const imports = useSelector(simpleImportKkutSelector.imports);
    const importResult = useSelector(simpleImportKkutSelector.importResult);
    const importInProgress = useSelector(simpleLayoutSelector.isItemLoading('importKkut'));
    const loading = useSelector(simpleLayoutSelector.isItemLoading('getImports'));
    const isAdmin = useSelector(simpleUserSelector.isAdmin);

    const {t} = useOurTranslation('importKkut/list');
    const dispatch = useDispatch();

    const importKkutFile = useCallback((file: File) => {
        dispatch(importKkutAction.importKkutFile(file));
    }, [dispatch]);

    const closeImportKkutResultModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('importKkutResult', false));
    }, [dispatch]);

    const extra = useMemo(() => isAdmin ? (
        <UploadButton
            testId="import-kkut-input"
            id="import-kkut-button"
            label={t('importKkut')}
            onUpload={importKkutFile}
            loading={importInProgress}
        />
    ) : undefined, [importInProgress, importKkutFile, isAdmin, t]);

    useEffect(() => {
        dispatch(importKkutAction.resetImports());
        dispatch(importKkutAction.getImports());
    }, [dispatch]);

    return (
        <Fragment>
            <Content container>
                <PageHeader
                    title={t('kkutManagement')}
                    extra={extra}
                />

                <ImportTable
                    imports={imports}
                    loading={loading}
                />
            </Content>

            <ImportKkutResultModal
                importResult={importResult}
                closeImportKkutResultModal={closeImportKkutResultModal}
            />
        </Fragment>
    );
};

export const List = memo(ListBase);
