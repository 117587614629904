// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Sort, SortSchema } from 'api/gen/Sort';

const requiredPart = t.interface({
  offset: t.union([t.Integer, t.null]),
  pageNumber: t.union([t.Integer, t.null]),
  pageSize: t.union([t.Integer, t.null]),
  paged: t.union([t.boolean, t.null]),
  sort: t.union([SortSchema, t.null]),
  unpaged: t.union([t.boolean, t.null]),
});

export const PageableObjectSchema = excess(requiredPart);

export interface PageableObject extends t.TypeOf<typeof PageableObjectSchema> {}
