import {emailRegexGen} from 'api/gen/Email';
import {phoneNumberMaxLength} from 'api/gen/PhoneNumber';
import {t, tCommon} from 'app/translations';
import {Errors, Validator} from 'utils/validator';

import {StableFormValues} from '../../types/stable-form-values';

const te = t('breeder');

export const validate = (values: StableFormValues): Errors<StableFormValues> => {
    const validator = new Validator(values);

    validator.pattern('email', emailRegexGen(), tCommon('email'));
    validator.maxStringLength('phone', phoneNumberMaxLength, te('phone'));
    validator.emailArray('alternativeEmails');

    return validator.generateErrorsObject();
};
