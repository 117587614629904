export const districtTranslations = {
    CS: {
        district: {
            districts: 'Okresy',
            noChosenDistricts: 'Nemáte vybrané žádné okresy',
            chooseDistricts: 'Vybrat okresy',
            selectedDistricts: 'Vybráno okresů: {{selectedDistricts}}',
            saveChanges: 'Uložit změny',
            districtsEdit: 'Úprava okresů',
            loadingOfDistricts: 'Načtení okresů',
            loadingOfActiveDistricts: 'Načtení aktivních okresů',
            selectAll: 'Vybrat vše',
            deselectAll: 'Zrušit vše',
        },
    },
};
