import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {
    convertTableDataParamsToDto,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetMeasurementsAction, measurementAction} from '../action';

function* execute(action: GetMeasurementsAction): SagaIterator {
    const {tableDataRequest} = action.payload;

    if (tableDataRequest.isEmpty) {
        return yield* put(measurementAction.getMeasurementsSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('measurements'));
    const tableState = yield* select(simpleTableSelector.tableState('measurements'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'measurements',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    },
    );

    const {filters, params} = convertTableDataParamsToDto({
        tableDataParams,
        fulltextFilterFields: ['earTag', 'stableName', 'breederName'],
    });

    yield* put(measurementAction.getMeasurementsStats(tableDataRequest));

    const response = yield* call(Api.findAllRatingsToApprove, filters, params);

    if (response.isSuccess) {
        yield* put(measurementAction.getMeasurementsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('measurements', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('measurement/messages')('getMeasurementsSuccess')));
    }
}

export function* getMeasurementsSaga(): SagaIterator {
    yield takeLatestF('measurement/GET_MEASUREMENTS', execute);
}
