import React, {FC, memo, useEffect} from 'react';

import {useOurTranslation} from 'app/translations';
import {FooterButtons} from 'common/buttons';
import {useForm} from 'utils/forms';

import {planEmailFormName, PlanEmailFormValues, initialPlanEmailFormValues} from '../../types/plan-email-form-values';
import {validate} from './validate';

interface Props {
    values: PlanEmailFormValues;
    loading: boolean;
    onSubmit(data: PlanEmailFormValues): void;
    onCancel(): void;
    setPlanEmailFormValues(values: PlanEmailFormValues): void;
}

const PlanEmailFormBase: FC<Props> = props => {
    const {loading, values, onSubmit, onCancel, setPlanEmailFormValues} = props;

    const {t, tCommon} = useOurTranslation('planning/email');

    useEffect(() => {
        setPlanEmailFormValues(values);
    }, [setPlanEmailFormValues, values]);

    const {Fields, Form} = useForm({
        form: planEmailFormName,
        initialValues: initialPlanEmailFormValues,
        destroyOnUnmount: true,
        validate,
        onSubmit,
    });

    return (
        <Form>
            <Fields.Tags
                name="emailsTo"
                label={tCommon('whom')}
                disabled={loading}
                isRequired
            />
            <Fields.Tags
                name="emailsToSendCopyTo"
                label={tCommon('copy')}
            />
            <Fields.TextEditor
                name="content"
                label={t('content')}
                disabled={loading}
            />
            <FooterButtons
                onCancel={onCancel}
                submitting={loading}
                submitTitle={tCommon('send')}
            />
        </Form>
    );
};

export const PlanEmailForm = memo(PlanEmailFormBase);
