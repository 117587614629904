import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const importKkutPath = '/import-kkut';

export const importKkutRoutes = [
    (
        <Route
            exact
            path={importKkutPath}
            component={Screens.List}
            key="import-kkut"
        />
    ),
];
