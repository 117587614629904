import {putAll, showBeError, ErrorsFromBE, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {UserDetails} from 'api/gen/UserDetails';
import {logger} from 'app/sentry-logger';
import {t} from 'app/translations';

import {userAction, LoadCurrentUserAction} from '../action';

const setUser = ({lastName, firstName, id}: UserDetails): void =>
    logger.setUser(`${lastName} ${firstName} id: ${id}`);

function* execute(_: LoadCurrentUserAction): SagaIterator {
    yield* put(userAction.setLoadingUser(true));
    const response = yield* call(Api.getUserDetails);

    if (response.isSuccess) {
        setUser(response.data);
        yield* put(userAction.currentUserLoaded(response.data));
    } else {
        yield putAll(showBeError(response, t('user/messages')('loadingUserTitle')));
        yield* put(userAction.currentUserLoadFailed(response.data as ErrorsFromBE));
    }
    yield* put(userAction.setLoadingUser(false));
    yield* put(userAction.setInitialLoadDone(true));
}

export function* loadCurrentUserSaga(): SagaIterator {
    yield takeLatestF('user/LOAD_CURRENT_USER', execute);
}
