import * as O from 'optics-ts';
import {opt, pipe} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialMeasurementState, MeasurementState} from './state';

const measurementStateO = O.optic<MeasurementState>();

export const measurementReducer = (
    state: MeasurementState = initialMeasurementState,
    action: AppAction,
): MeasurementState => {
    switch (action.type) {
        case 'measurement/GET_MEASUREMENTS_SUCCESS': {
            const {ratingsOfAnimal} = action.payload;

            return pipe(
                state,
                O.set(measurementStateO.prop('allRatings'))(ratingsOfAnimal),
            );
        }

        case 'measurement/GET_RATINGS_OF_ANIMAL_SUCCESS': {
            const {ratingsOfAnimal} = action.payload;

            return O.set(measurementStateO.prop('ratingsOfAnimal'))(ratingsOfAnimal)(state);
        }

        case 'measurement/FIND_EARTAGS_SUCCESS': {
            const {earTags} = action.payload;

            return O.set(measurementStateO.prop('foundEarTags'))(earTags)(state);
        }

        case 'measurement/SET_RATING_IN_EDIT': {
            const {ratingId} = action.payload;

            return O.set(measurementStateO.prop('ratingInEdit'))(opt(ratingId))(state);
        }

        case 'measurement/SET_EDITED_RATING': {
            const {ratingId} = action.payload;

            return O.set(measurementStateO.prop('editedRating'))(ratingId)(state);
        }

        case 'measurement/GET_MEASUREMENTS_STATS_SUCCESS': {
            const {ratingStats} = action.payload;

            return O.set(measurementStateO.prop('stats'))(ratingStats)(state);
        }

        default:
            return state;
    }
};
