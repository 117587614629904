import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {useOurTranslation} from 'app/translations';
import {Button} from 'common/buttons';
import {Panel} from 'common/layout';

import styles from './styles.sass';

interface Props {
    measurementsCount: number;
    measurementsDeaths: number;
    measurementsAnimalsCount: number;
    approveInProgress: boolean;
    approveMeasurements(): void;
}

const MeasurementsActionPanelBase: FC<Props> = props => {
    const {
        measurementsCount,
        approveInProgress,
        measurementsAnimalsCount,
        measurementsDeaths,
        approveMeasurements,
    } = props;
    const {t} = useOurTranslation('measurement/actionPanel');

    return (
        <Panel>
            <div className={classNames(styles.panel, 'group')}>
                <span className="font-weight-bold">{`${t('ratedAnimals')}: ${measurementsAnimalsCount}`}</span>
                <span className="font-weight-bold">{`${t('mortifiedAnimals')}: ${measurementsDeaths}`}</span>
                <Button
                    onClick={approveMeasurements}
                    visuals="primary"
                    disabled={!measurementsCount}
                    loading={approveInProgress}
                    icon="checkOutlined"
                >
                    {t('approveMeasurements')}
                </Button>
            </div>
        </Panel>
    );
};

export const MeasurementsActionPanel = memo(MeasurementsActionPanelBase);
