// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A breeder suite ID.
// Example: "2019-01-01/3105261892/1/614"

export const breederSuiteIdRegexGen = (): RegExp => new RegExp("^\\d{4}-\\d{2}-\\d{2}\\u002F\\d{10}\\u002F\\d+\\u002F\\d{3}$");

export const BreederSuiteIdSchema = StringPatternSchema<BreederSuiteId>(breederSuiteIdRegexGen());

export type BreederSuiteId = string;
