import {putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {breedingAction} from 'app/breeding';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {planningAction, RemoveAnimalsFromPlanAction} from '../action';

function* execute({payload: {animalIds}}: RemoveAnimalsFromPlanAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('updatePlan', true));
    yield* put(breedingAction.addIdsToLoading(animalIds));

    const response = yield* call(Api.removeAnimalsFromPlan, {animalIds});

    yield* put(breedingAction.removeIdsFromLoading(animalIds));
    if (response.isSuccess) {
        yield* put(showSuccess(t('planning/messages')('updatePlanTitle'),
            t('planning/messages')('removeAnimalsPlanSuccess'), false, 'breeding'));
        yield* put(planningAction.getPlan());
    } else {
        yield putAll(showBeError(response, t('planning/messages')('updatePlanTitle')));
    }

    yield* put(layoutAction.setItemLoading('updatePlan', false));
}

export function* removeAnimalsFromPlanSaga(): SagaIterator {
    yield takeLatestF('planning/REMOVE_ANIMALS_FROM_PLAN', execute);
}
