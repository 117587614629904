import {flatMap, map, sum} from 'lodash/fp';
import {pipe} from 'ts-opt';

import {Plan} from 'api/gen/Plan';

export const getAnimalsCount = (plan: Plan): number =>
    pipe(
        plan.breeders,
        flatMap(breeder => breeder.stables),
        map(stable => stable.animals.length),
        sum,
    );
