import {componentsTranslations, beErrorsTranslations} from '@fl/cmsch-fe-library';
import merge from 'ts-deepmerge';

import {adminTranslations} from 'app/admin/translations';
import {breederSuiteTranslations} from 'app/breeder-suite/translations';
import {breederTranslations} from 'app/breeder/translations';
import {breedingTranslations} from 'app/breeding/translations';
import {bullTranslations} from 'app/bull/translations';
import {databaseOfMeasuredTranslations} from 'app/database-of-measured/translations';
import {districtTranslations} from 'app/district/translations';
import {emailTranslations} from 'app/email/translations';
import {importKkutTranslations} from 'app/import-kkut/translations';
import {measurementTranslations} from 'app/measurement/translations';
import {planningTranslations} from 'app/planning/translations';
import {repairTranslations} from 'app/repair/translations';
import {unknownAnimalTranslations} from 'app/unknown-animal/translations';
import {userTranslations} from 'app/user/translations';

import {commonTranslations} from './common-translations';

const beErrorTranslations = {
    CS: {
        beErrors: beErrorsTranslations.CS,
    },
};

export const translations = merge(
    beErrorTranslations,
    commonTranslations,
    breederTranslations,
    bullTranslations,
    breedingTranslations,
    districtTranslations,
    planningTranslations,
    unknownAnimalTranslations,
    importKkutTranslations,
    componentsTranslations,
    measurementTranslations,
    breederSuiteTranslations,
    databaseOfMeasuredTranslations,
    userTranslations,
    repairTranslations,
    adminTranslations,
    emailTranslations,
);
