import * as React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const breederRoutes = [
    (
        <Route
            exact
            path="/breeders"
            component={Screens.List}
            key="breeder"
        />
    ),
    (
        <Route
            exact
            path="/breeder-detail/:id"
            component={Screens.Detail}
            key="breederDetail"
        />
    ),
    (
        <Route
            exact
            path="/stable-detail/:id"
            component={Screens.StableDetail}
            key="stableDetail"
        />
    ),
];
