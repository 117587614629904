import {District} from 'api/gen/District';

export interface DistrictState {
    districts: Array<District> | null;
    selectedDistricts: Array<District> | null;
    activeDistricts: Array<District> | null;
}

export const initialDistrictState: DistrictState = {
    districts: null,
    selectedDistricts: null,
    activeDistricts: null,
};
