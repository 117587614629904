// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The first calve months of an animal.
// Example: 32

export const calveMonthsMaximum = 99.0;

export const CalveMonthsSchema = t.refinement(t.Integer, x => x <= calveMonthsMaximum, 'CalveMonths');

export type CalveMonths = t.TypeOf<typeof CalveMonthsSchema>;
