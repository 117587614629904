import {setItem, toggleItem} from '@fl/cmsch-fe-library';
import {intersection, isEmpty} from 'lodash/fp';
import * as O from 'optics-ts';
import {opt, pipe} from 'ts-opt';

import {AppAction} from 'app/setup';

import {initialPlanningState, PlanningState} from './state';

const planningStateO = O.optic<PlanningState>();

export const planningReducer = (
    state: PlanningState = initialPlanningState,
    action: AppAction,
): PlanningState => {
    switch (action.type) {
        case 'planning/RESET_ADMIN_PLANS_SCREEN': {
            return O.set(planningStateO.prop('adminPlans'))(null)(state);
        }

        case 'planning/GET_ADMIN_PLANS_SUCCESS': {
            const {adminPlans} = action.payload;

            return O.set(planningStateO.prop('adminPlans'))(adminPlans)(state);
        }

        case 'planning/RESET_PLAN_SCREEN': {
            return {
                ...state,
                plan: null,
                selectedAnimals: [],
                selectedCompanies: [],
                planEmailModalStableId: null,
                planEmailModalBreederId: null,
            };
        }

        case 'planning/GET_PLAN_SUCCESS': {
            const {plan} = action.payload;

            return O.set(planningStateO.prop('plan'))(plan)(state);
        }

        case 'planning/GET_ACTIVE_PLAN_ERROR': {
            return O.set(planningStateO.prop('plan'))(null)(state);
        }

        case 'planning/TOGGLE_SELECT_ANIMAL': {
            const {animalId, breederId, animalsInCompany} = action.payload;
            const {selectedAnimals} = state;

            const newSelectedAnimals = toggleItem(animalId)(selectedAnimals);
            const intersectionWithCompany = intersection(animalsInCompany)(newSelectedAnimals);

            return pipe(
                state,
                O.set(planningStateO.prop('selectedAnimals'))(newSelectedAnimals),
                O.modify(planningStateO.prop('selectedCompanies'))(
                    setItem(!isEmpty(intersectionWithCompany))(breederId),
                ),
            );
        }

        case 'planning/SET_PLAN_EMAIL_MODAL_STABLE_ID': {
            const {stableId} = action.payload;

            return O.set(planningStateO.prop('planEmailModalStableId'))(stableId)(state);
        }

        case 'planning/SET_PLAN_EMAIL_MODAL_BREEDER_ID': {
            const {breederId} = action.payload;

            return O.set(planningStateO.prop('planEmailModalBreederId'))(breederId)(state);
        }

        case 'planning/GET_EMAIL_TEMPLATE_SUCCESS': {
            const {emailTemplate} = action.payload;

            return O.set(planningStateO.prop('emailTemplate'))(opt(emailTemplate))(state);
        }

        case 'planning/RESET_SELECTED_ANIMALS_AND_COMPANIES': {
            return pipe(
                state,
                O.set(planningStateO.prop('selectedAnimals'))([]),
                O.set(planningStateO.prop('selectedCompanies'))([]),
            );
        }

        default:
            return state;
    }
};
