// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractMeasurementDetail, AbstractMeasurementDetailSchema } from 'api/gen/AbstractMeasurementDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinMeasurementProperties, HolsteinMeasurementPropertiesSchema } from 'api/gen/HolsteinMeasurementProperties';

const requiredPart = t.interface({
  tag: t.literal('HolsteinMeasurementDetail'),
  properties: HolsteinMeasurementPropertiesSchema,
});

export const HolsteinMeasurementDetailSchema = excess(t.intersection([AbstractMeasurementDetailSchema.type, requiredPart]));

export interface HolsteinMeasurementDetail extends t.TypeOf<typeof HolsteinMeasurementDetailSchema> {}
