export const bullFormName = 'bullForm';

export interface EditBullFormValues {
    name: string | null;
    marked: boolean;
    note: string | null;
}

export const initialEditBullValues: EditBullFormValues = {
    name: null,
    marked: false,
    note: null,
};
