export const breedingTranslations = {
    CS: {
        'breeding/list': {
            planning: 'Plánování',
        },
        'breeding/BreedingTable': {
            dangerousAnnotation: 'V chovu je zvíře, které je blízko hranice pro hodnocení',
            noRows: 'Nejsou k dispozici žádné chovy.',
            noRowsForFilters: 'Pro daný filtr nejsou k dispozici žádné chovy.',
            header: {
                districtNames: 'Okres',
                numOfPlannedAnimals: 'Počet vybraných',
                numOfMeasurableAnimals: 'Počet možných hodnocení',
                anyAnimalMarked: 'T',
                arealRating: 'P',
                actions: 'Akce',
            },
            anyAnimalMarkedFilterLabel: 'Testace',
            arealRatingFilterLabel: 'Plošný chov',
        },
        'breeding/detail': {
            backToPlanning: 'Plánování',
        },
        'breeding/AnimalsTable': {
            dangerousAnnotation: 'Zvíře je blízko hranice pro hodnocení',
            markedOptions: {
                T: 'T',
                X: 'X',
                all: 'Vše',
            },
            breedOptions: {
                firstOption: '75 a více',
                secondOptionFleckvieh: '87 a více',
                secondOptionHolstein: '85 a více',
                all: 'Vše',
            },
            header: {
                numOfMeasuredDaughtersInBreeding: 'H. v chovu',
            },
            tooltip: {
                numOfMeasuredDaughtersInBreeding: 'Hodnocených dcer v tomto chovu',
            },
        },
        'breeding/BreedingDetailActionPanel': {
            toMeasure: 'V chovu je možno hodnotit',
            infoText: 'V plánu {{selectedWord}} {{animalsInPlanCount}} {{animalsInPlanWord}}. V chovu je možno \
hodnotit {{measurableAnimalsInBreedingCount}} {{measurableAnimalsInBreedingCountWord}}, {{secondSelectedWord}} \
{{breedingAnimalsInPlanCount}} {{breedingAnimalsInPlanWord}}.',
            inflectSelected: {
                first: 'vybráno',
                second: 'vybrána',
                third: 'vybráno',
            },
        },
        'breeding/sagas': {
            loadingOfCows: 'Načtení seznamu krav pro chov',
            loadingOfFarmNumber: 'Načtení čísel chovů',
        },

    },
};
