// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Email, EmailSchema } from 'api/gen/Email';

const requiredPart = t.interface({
  content: t.string,
  emails: t.array(EmailSchema),
  emailsToSendCopyTo: t.union([t.array(EmailSchema), t.null]),
});

export const BreederSuiteEmailSchema = excess(requiredPart);

export interface BreederSuiteEmail extends t.TypeOf<typeof BreederSuiteEmailSchema> {}
