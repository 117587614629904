// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailList, EmailListSchema } from 'api/gen/EmailList';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EmailTemplateType, EmailTemplateTypeSchema } from 'api/gen/EmailTemplateType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  created: DateTimeSchema,
  emailList: EmailListSchema,
  id: PositiveLongSchema,
  type: EmailTemplateTypeSchema,
});

export const EmailAuditSchema = excess(requiredPart);

export interface EmailAudit extends t.TypeOf<typeof EmailAuditSchema> {}
