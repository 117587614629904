import {getAllUsersSaga} from './get-all-users-saga';
import {initializeSaga} from './initialize-saga';
import {loadCurrentUserSaga} from './load-current-user';
import {logInUserSaga} from './log-in-user';
import {logOutUserSaga} from './log-out-user';

export const userSagas = [
    loadCurrentUserSaga,
    logInUserSaga,
    logOutUserSaga,
    getAllUsersSaga,
    initializeSaga,
];
