// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A purity of animal's udder.
// Example: "700"

export const udderPurityRegexGen = (): RegExp => new RegExp("^\\d{3}$");

export const UdderPuritySchema = StringPatternSchema<UdderPurity>(udderPurityRegexGen());

export type UdderPurity = string;
