import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {breederAction, GetStablesAction} from '../action';

function* execute({payload: {breederId}}: GetStablesAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('breederStables', true));
    const response = yield* call(Api.getStablesByBreeder, {breederId});

    if (response.isSuccess) {
        yield* put(breederAction.getStablesSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('breeder')('stablesAdministration')));
    }

    yield* put(layoutAction.setItemLoading('breederStables', false));
}

export function* getStablesSaga(): SagaIterator {
    yield takeLatestF('breeder/GET_STABLES', execute);
}
