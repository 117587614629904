// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';

const requiredPart = t.interface({
  tag: t.literal('EQUALS_INTEGER'),
  field: t.string,
  value: t.Integer,
});

export const EqualsIntegerFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface EqualsIntegerFilter extends t.TypeOf<typeof EqualsIntegerFilterSchema> {}
