import React, {FC} from 'react';

import {useOurTranslation} from 'app/translations';
import {Button} from 'common/buttons';
import {Panel} from 'common/layout';
import {useForm} from 'utils/forms';

import {loginFormInitialValue, LoginFormValues, loginFormName} from '../../types/login-form-values';
import {validate} from './validate';

interface OwnProps {
    onLogin(): void;
}

export const LoginForm: FC<OwnProps> = props => {
    const {onLogin} = props;

    const {t} = useOurTranslation('user/login');

    const {submitting, valid, Fields, Form} = useForm<LoginFormValues, OwnProps>({
        form: loginFormName,
        initialValues: loginFormInitialValue,
        validate,
        onSubmit: onLogin,
    });

    return (
        <Panel>
            <Form>
                <div className="mt-2">
                    <Fields.Input
                        name="username"
                        type="text"
                        label={t('username')}
                    />
                </div>
                <div className="mt-2">
                    <Fields.Input
                        name="password"
                        type="password"
                        label={t('password')}
                    />
                </div>
                <div className="d-flex mt-4">
                    <Button
                        visuals="primary"
                        type="submit"
                        disabled={submitting || !valid}
                        block
                        testId="log-in"
                    >
                        {t('logIn')}
                    </Button>
                </div>
            </Form>
        </Panel>
    );
};
