// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { DistrictName, DistrictNameSchema } from 'api/gen/DistrictName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  id: PositiveLongSchema,
  name: DistrictNameSchema,
});

export const DistrictSchema = excess(requiredPart);

export interface District extends t.TypeOf<typeof DistrictSchema> {}
