// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A breeding number of a breeding.
// Example: "2104307721"

export const breedingNumberRegexGen = (): RegExp => new RegExp("^\\d{10}$");

export const BreedingNumberSchema = StringPatternSchema<BreedingNumber>(breedingNumberRegexGen());

export type BreedingNumber = string;
