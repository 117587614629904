export const importKkutTranslations = {
    CS: {
        'importKkut/list': {
            kkutManagement: 'Správa KKUT',
            importKkut: 'Import KKUT',
        },
        'importKkut/modal': {
            result: 'Výsledek importu KKUT',
            recordsInFile: 'Počet záznamů v souboru:',
            importedRecords: 'Počet importovaných záznamů:',
            duplicateRecords: 'Počet duplicitních záznamů:',
        },
        'importKkut/table': {
            fileName: 'Název souboru',
            importedBy: 'Importoval',
            imported: 'Datum nahrání',
            allRecords: 'Záznamy',
            importedRecords: 'Importováno',
            duplicateRecords: 'Duplicitní',
            noImportsFound: 'Nebyly nalezeny žádné importy',
        },
        'importKkut/messages': {
            title: 'Import KKUT',
            errorMessage: 'Importy KKUT',
            successMessage: 'Import KKUT proběhl úspěšně',
        },
    },
};
