import {putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {getAnimalIdsInPlan} from 'app/breeding/utils';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {DeletePlanAction, planningAction} from '../action';
import {simplePlanningSelector} from '../selector';

function* execute(_: DeletePlanAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('deletePlan', true));

    const title = t('planning/messages')('deleteTitle');
    const plan = yield* select(simplePlanningSelector.plan);
    const response = yield* call(Api.removeAnimalsFromPlan, {animalIds: getAnimalIdsInPlan(plan)});

    if (response.isSuccess) {
        yield* put(showSuccess(title, t('planning/messages')('deleteSuccess'), false, 'delete-plan'));

        yield* put(planningAction.resetPlanScreen());
        yield* put(planningAction.getPlan());
    } else {
        yield putAll(showBeError(response, title));
    }

    yield* put(layoutAction.setItemLoading('deletePlan', false));
}

export function* deletePlanSaga(): SagaIterator {
    yield takeLatestF('planning/DELETE_PLAN', execute);
}
