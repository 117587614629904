import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {breedingAction, GetBreedingNumbersAction} from '../action';

function* execute(_: GetBreedingNumbersAction): SagaIterator {
    const response = yield* call(Api.findBreedingNumbers);

    if (response.isSuccess) {
        yield* put(breedingAction.getBreedingNumbersResult(response.data));
    } else {
        yield putAll(showBeError(response, t('breeding/sagas')('loadingOfFarmNumber')));
    }
}

export function* getBreedingNumbers(): SagaIterator {
    yield takeLatestF('breeding/GET_BREEDING_NUMBERS', execute);
}
