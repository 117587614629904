import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, select} from 'typed-redux-saga';

import {loginPagePath} from 'app/consts';
import {simpleLayoutSelector} from 'common/layout/model/selector';

export function* goToLoginScreen(): SagaIterator {
    const {redirectTo} = (yield* select(simpleLayoutSelector.locationState)) ?? {};

    if (redirectTo !== loginPagePath) {
        yield* put(routerActions.push(loginPagePath, {redirectTo}));
    }
}
