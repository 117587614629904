// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


const requiredPart = t.interface({
  arealRating: t.boolean,
});

export const UpdateBreedingSchema = excess(requiredPart);

export interface UpdateBreeding extends t.TypeOf<typeof UpdateBreedingSchema> {}
