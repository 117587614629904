import {NullableKeys} from '@fl/cmsch-fe-library';

export const editRatingFormName = 'editRating';

export type EditRatingFormValues = NullableKeys<{
    lactation: number,
    ratingDate: string,
}>;

export const editRatingInitialValues: EditRatingFormValues = {
    lactation: 0,
    ratingDate: null,
};
