/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
// todo refactor, fix lint errors and remove disable comments

/* eslint-disable max-lines */
import {isStringArray, KeysOfType} from '@fl/cmsch-fe-library';
import {includes, find, forEach, isArray, isString, isEmpty} from 'lodash/fp';
import {opt} from 'ts-opt';

import {emailRegexGen} from 'api/gen/Email';
import {logger} from 'app/sentry-logger';
import {t} from 'app/translations';

const te = t('common/validator');

type MapOptionalFields<T, U> = { [K in keyof T]?: MapOptionalFieldsAndArrays<T[K], U> };
type MapArray<U, A> = Array<MapOptionalFieldsAndArrays<A, U>>;
type MapOptionalFieldsAndArrays<T, U> =
    T extends object ? MapOptionalFields<T, U> :
        T extends Array<infer A> ? MapArray<U, A> :
            U;
type ObjectErrors<T> = MapOptionalFields<T, string>;
// type ArrayErrors<T extends Array<A>, A> = MapArray<string, A>;
export type Errors<T> = MapOptionalFieldsAndArrays<T, string>;

const validationRegularExpressions = Object.freeze({
    floatNumber: /^-?(\d| )+([,.](\d| )+)?$/,
    oneDecimalPlaceNumber: /^\d+([,.]?\d{0,1})?$/,
    email: /^[\w%+-.]+@[\w-.]+\.[A-Za-z]{2,}$/,
});

type KeysOfStringType<Values> = KeysOfType<Values, string | null | undefined>;

/**
 * Validates form values.
 */
export class Validator<Values> {
    protected readonly errors: ObjectErrors<Values>;

    constructor(protected readonly values: Values) {
        this.errors = {};
        opt(values).orCrash('values are missing');
    }

    public static genIsRequiredError(label: string): string {
        return te('isRequired', {label});
    }

    /**
     * Characters word translation.
     * @param count
     */
    public static charsT(count: number): string {
        const ONE = 1;
        const FOUR = 4;
        if (count === ONE) {
            return te('oneChar');
        } else if (count <= FOUR) {
            return te('lessThanFiveChars');
        } else {
            return te('moreChars');
        }
    }

    public static genMinLenError(label: string, minLen: number): string {
        return te('minLength', {label, minLen, characters: this.charsT(minLen)});
    }

    public static genMaxLenError(label: string, maxLen: number): string {
        return te('maxLength', {label, maxLen, characters: this.charsT(maxLen)});
    }

    public static genPatternError(label: string): string {
        return te('wrongFormat', {label});
    }

    public static genPatternArrayItemsError(label: string): string {
        return `Jeden nebo více prvků pro pole ${label} má nesprávný formát`;
    }

    public static genIntegerNumberError(label: string): string {
        return te('isNotInteger', {label});
    }

    public static genFloatNumberError(label: string): string {
        return te('isNotFloatNumber', {label});
    }

    public static genMaxNumberError(label: string, max: number): string {
        return te('maxNumber', {label, max});
    }

    public static genMinNumberError(label: string, min: number): string {
        return te('minNumber', {label, min});
    }

    public static genNumberMaxOneDecimalPlaceError(label: string): string {
        return te('isNotFloatOneDecimal', {label});
    }

    public static genArrayLengthError(label: string, length: number): string {
        return te('arrayLength', {label, length});
    }

    public static genUniqueError(label: string): string {
        return te('uniqueError', {label});
    }

    /**
     * Is field empty?
     * String is considered also empty if it contains only whitespace characters.
     * @param fieldName
     */
    public checkIsEmpty(fieldName: keyof Values): boolean {
        const value = this.values[fieldName];
        if (value === undefined || value === null || Number.isNaN(value)) {
            return true;
        } else if (isString(value) && !value.trim()) {
            return true;
        } else if (isArray(value) && value.length === 0) {
            return true;
        }

        return false;
    }

    /**
     * Validates field to have a filled value.
     * @param fieldName
     * @param label
     */
    public nonEmpty(fieldName: keyof Values, label: string): void {
        const errStr = Validator.genIsRequiredError(label);
        if (this.checkIsEmpty(fieldName)) {
            this.setErrorForField(fieldName, errStr);
        }
    }

    /**
     * Validates fields to have a filled value one of them.
     * @param fieldNames
     * @param label
     */
    public oneIsFilled<K extends keyof Values>(fieldNames: Array<K>, label: string): void {
        const errStr = Validator.genIsRequiredError(label);
        const isFilled = find((x: keyof Values) => !this.checkIsEmpty(x), fieldNames);
        if (!isFilled) {
            forEach((x: keyof Values) => this.setErrorForField(x, errStr), fieldNames);
        }
    }

    /**
     * Validates string field to have at least {@param minLen} characters.
     * Does not fail when a field is empty. Use {@link nonEmpty} as well, if you don't want to allow empty strings.
     * @param fieldName
     * @param minLen
     * @param label
     */
    public minStringLength(fieldName: KeysOfStringType<Values>, minLen: number, label: string): void {
        if (minLen <= 0) {
            const err = new Error(`Invalid minLen = ${minLen}.`);
            logger.logError(err);
            throw err;
        }
        const value = this.values[fieldName];
        if (value) {
            if (!isString(value)) {
                const err = new Error(`Field ${String(fieldName)} is not a string. Cannot validate its length.`);
                logger.logError(err);
                throw err;
            }
            if (value.length < minLen) {
                this.setErrorForField(fieldName, Validator.genMinLenError(label, minLen));
            }
        }
    }

    /**
     * Validates string field to have at most {@param maxLen} characters.
     * @param fieldName
     * @param maxLen
     * @param label
     */
    public maxStringLength(fieldName: KeysOfStringType<Values>, maxLen: number, label: string): void {
        if (maxLen <= 0) {
            const err = new Error(`Invalid maxLen = ${maxLen}.`);
            logger.logError(err);
            throw err;
        }
        const value = this.values[fieldName];
        if (!value) {
            return;
        }
        if (!isString(value)) {
            const err = new Error(`Field ${String(fieldName)} is not a string. Cannot validate its length.`);
            logger.logError(err);
            throw err;
        }
        if (value.length > maxLen) {
            this.setErrorForField(fieldName, Validator.genMaxLenError(label, maxLen));
        }
    }

    /**
     * Validates string field to match a regular expression.
     * @param fieldName
     * @param regex
     * @param label
     */
    public pattern(fieldName: keyof Values, regex: RegExp, label: string): void {
        const value = this.values[fieldName];
        if (!value) {
            return;
        }
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate pattern.`);
        }
        if (!regex.test(value)) {
            this.setErrorForField(fieldName, Validator.genPatternError(label));
        }
    }

    /**
     * Validates string field to match a regular expression.
     * @param fieldName
     * @param regex
     * @param label
     */
    public patternArrayItems(fieldName: keyof Values, regex: RegExp, label: string): void {
        const values = this.values[fieldName];
        if (!values) {
            return;
        }
        if (!isStringArray(values)) {
            throw new Error(`Field ${String(fieldName)} is not a string array. Cannot validate pattern.`);
        }
        values.forEach(value => {
            if (!regex.test(value)) {
                this.setErrorForField(fieldName, Validator.genPatternArrayItemsError(label));
            }
        });
    }

    /**
     * Validates multiselect field to match all its items to email regular expression.
     * @param fieldName
     */
    public emailArray(fieldName: keyof Values): void {
        const values = this.values[fieldName];
        if (isEmpty(values)) {
            return;
        }
        if (!isStringArray(values)) {
            throw new Error(`Field ${String(fieldName)} is not a string array. Cannot validate emails.`);
        }
        values.forEach(value => {
            if (!emailRegexGen().test(value)) {
                this.setErrorForField(fieldName, te('emailsArrayPattern'));
            }
        });
    }

    /**
     * Validates field to be an actual number.
     * @param fieldName
     * @param label
     */
    public floatNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) {
            return;
        }
        if (typeof value !== 'number') {
            this.setErrorForField(fieldName, Validator.genFloatNumberError(label));
        }
    }

    /**
     * Validates field to be an actual whole number.
     * @param fieldName
     * @param label
     */
    public integerNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) {
            return;
        }
        if (typeof value !== 'number' || !Number.isInteger(value)) {
            this.setErrorForField(fieldName, Validator.genIntegerNumberError(label));
        }
    }

    /**
     * Validates string field to be a number (integer or float).
     * @param fieldName
     * @param label
     */
    public stringFloatNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) {
            return;
        }
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate float number.`);
        }
        if (!validationRegularExpressions.floatNumber.test(value)) {
            this.setErrorForField(fieldName, Validator.genFloatNumberError(label));
        }
    }

    /**
     * Validates number field to be a number lower than max
     * @param {string} fieldName
     * @param {number} max
     * @param label
     */
    public maxNumber(fieldName: keyof Values, label: string, max: number): void {
        const value = this.values[fieldName];
        if (value === null || value === undefined) {
            return;
        }

        if (typeof value !== 'number') {
            throw new Error(`Value ${value} is not number`);
        }

        if (value > max) {
            this.setErrorForField(fieldName, Validator.genMaxNumberError(label, max));
        }
    }

    /**
     * Validates number field to be a number higher than min
     * @param {string} fieldName
     * @param {number} min
     * @param label
     */
    public minNumber(fieldName: keyof Values, label: string, min: number): void {
        const value = this.values[fieldName];
        if (value === null || value === undefined) {
            return;
        }

        if (typeof value !== 'number') {
            throw new Error(`Value ${value} is not number`);
        }

        if (value < min) {
            this.setErrorForField(fieldName, Validator.genMinNumberError(label, min));
        }
    }

    /**
     * Validates string field to be a number rounded to one decimal place.
     * @param fieldName
     * @param label
     */
    public oneDecimalPlaceNumber(fieldName: keyof Values, label: string): void {
        const value = this.values[fieldName];
        if (!value) {
            return;
        }
        if (!isString(value)) {
            throw new Error(`Field ${String(fieldName)} is not a string. Cannot validate number.`);
        }
        if (!validationRegularExpressions.oneDecimalPlaceNumber.test(value)) {
            this.setErrorForField(fieldName, Validator.genNumberMaxOneDecimalPlaceError(label));
        }
    }

    /**
     * Validates array field to be of exact length.
     * @param fieldName
     * @param length
     * @param label
     */
    public arrayLength(fieldName: keyof Values, length: number, label: string): void {
        const value = this.values[fieldName];
        if (!value) {
            return;
        }
        if (!isArray(value)) {
            throw new Error(`Field ${String(fieldName)} is not an array. Cannot validate array length.`);
        }
        if (value.length !== length) {
            this.setErrorForField(fieldName, Validator.genArrayLengthError(label, length));
        }
    }

    /**
     * Validates field to have a unique value.
     * @param fieldName
     * @param collection
     * @param label
     */
    public unique(fieldName: keyof Values, collection: Array<Values[keyof Values]>, label: string): void {
        const value = this.values[fieldName];
        if (!value) {
            return;
        }
        const duplicity = includes(value, collection);
        if (duplicity) {
            this.setErrorForField(fieldName, Validator.genUniqueError(label));
        }
    }

    public email(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.email, label);
    }

    /**
     * Return accumulated errors.
     */
    public generateErrorsObject(): ObjectErrors<Values> {
        return this.errors;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    protected setErrorForField(fieldName: keyof Values, error: any): void {
        this.errors[fieldName] = error;
    }
}
