import {t} from 'app/translations';
import {RatingValidator} from 'app/utils/rating-validator';
import {Errors} from 'utils/validator';

import {LoginFormValues} from '../../types/login-form-values';

const tt = t('user/login');

export const validate = (values: LoginFormValues): Errors<LoginFormValues> => {
    const validator = new RatingValidator(values);

    validator.nonEmpty('username', tt('username'));
    validator.nonEmpty('password', tt('password'));

    return validator.generateErrorsObject();
};
