// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.76.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width: 992px){.src-app-planning-components-plan-animals-table-___styles__actionsRow___fA0ju .ant-row{min-width:620px}}", "",{"version":3,"sources":["webpack://./src/app/planning/components/plan-animals-table/styles.sass"],"names":[],"mappings":"AAEA,yBAGY,uFACI,eAAA,CAAA","sourcesContent":["@import '../../../../styles/variables'\n\n@media (min-width: $screen-lg)\n    .actionsRow\n        \\:global\n            .ant-row\n                min-width: 620px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsRow": "src-app-planning-components-plan-animals-table-___styles__actionsRow___fA0ju"
};
export default ___CSS_LOADER_EXPORT___;
