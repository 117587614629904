import {Validator} from 'utils/validator';

const minLactationValue = 1;
const maxLactationValue = 9;

const validationRegularExpressions = Object.freeze({
    codex: /^\d{3}$/,
    breedingNumber: /^\d{10}$/,
});

export class RatingValidator<Values> extends Validator<Values> {
    public codex(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.codex, label);
    }

    public breedingNumber(fieldName: keyof Values, label: string): void {
        this.pattern(fieldName, validationRegularExpressions.breedingNumber, label);
    }

    public lactation(fieldName: keyof Values, label: string): void {
        this.integerNumber(fieldName, label);
        this.minNumber(fieldName, label, minLactationValue);
        this.maxNumber(fieldName, label, maxLactationValue);
    }
}
