import {Nullable} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {BreedingDetails} from 'api/gen/BreedingDetails';
import {PagePlanningAnimal} from 'api/gen/PagePlanningAnimal';
import {PagePlanningBreeding} from 'api/gen/PagePlanningBreeding';
import {State} from 'app/setup';

export const simpleBreedingSelector = {
    animalsInBreedingPage: ({breeding}: State): Opt<PagePlanningAnimal> => breeding.animalsInBreedingPage,
    breedingPage: ({breeding}: State): Opt<PagePlanningBreeding> => breeding.breedingPage,
    breeding: ({breeding}: State): Nullable<BreedingDetails> => breeding.breeding,
    fetchingIds: ({breeding}: State): Array<number> => breeding.fetchingIds,
};
