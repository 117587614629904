import {Options} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';

import {earTagWithoutCountryMaxLength} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {FooterButtons} from 'common/buttons';
import {useForm} from 'utils/forms';

import {selectAnimalFormName, selectAnimalInitialValues} from '../../types/select-animal-form-values';
import {validate} from './validate';

interface Props {
    foundEarTags: Options<string>;
    onSubmit(): void;
    onCancel(): void;
    findEarTags(value: string): void;
}

const SelectAnimmalFormBase: FC<Props> = props => {
    const {onSubmit, onCancel, foundEarTags, findEarTags} = props;
    const {tCommon} = useOurTranslation('measurement/list');

    const {submitting, Fields, Form, valid} = useForm({
        form: selectAnimalFormName,
        initialValues: selectAnimalInitialValues,
        enableReinitialize: true,
        destroyOnUnmount: true,
        onSubmit,
        validate,
    });

    const onFindEarTags = useCallback((value: string) => {
        if (value.length <= earTagWithoutCountryMaxLength) findEarTags(value);
    }, [findEarTags]);

    return (
        <Form>
            <div className="row">
                <div className="col-12">
                    <Fields.StringSelect
                        name="earTag"
                        label={tCommon('earTag')}
                        disabled={submitting}
                        options={foundEarTags}
                        onSearch={onFindEarTags}
                        isRequired
                        includeSearchValueInOptions
                    />
                </div>
            </div>
            <div className="mt-3">
                <FooterButtons
                    onCancel={onCancel}
                    submitting={submitting}
                    disabledSubmit={!valid}
                />
            </div>
        </Form>
    );
};

export const SelectAnimalForm = memo(SelectAnimmalFormBase);
