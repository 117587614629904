import {excess} from '@fl/cmsch-fe-library';
import * as t from 'io-ts';

export const loginFormName = 'login';

const requiredPart = t.interface({
    username: t.string,
    password: t.string,
});

export const LoginFormValuesSchema = excess(requiredPart);

export type LoginFormValues = t.TypeOf<typeof LoginFormValuesSchema>;

export const loginFormInitialValue: LoginFormValues = {
    username: '',
    password: '',
};
