import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {FindAnimalsAction, repairAction} from '../action';

function* execute(action: FindAnimalsAction): SagaIterator {
    const {earTag} = action.payload;

    yield* put(layoutAction.setItemLoading('findAnimals', true));
    const response = yield* call(Api.searchAnimalsWithBreedings, {value: earTag});

    if (response.isSuccess) {
        yield* put(repairAction.findAnimalsSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('repair/messages')('findAnimals')));
    }

    yield* put(layoutAction.setItemLoading('findAnimals', false));
}

export function* findAnimalsSaga(): SagaIterator {
    yield takeLatestF('repair/FIND_ANIMALS', execute);
}
