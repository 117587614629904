export const breederFormName = 'breederForm';

export interface UpdateBreederFormValues {
    name: string;
    ico: string | null;
    dic: string | null;
    email: string | null;
    phone: string | null;
}

export const updateBreederInitialValues: UpdateBreederFormValues = {
    name: '',
    ico: null,
    dic: null,
    email: null,
    phone: null,
};
