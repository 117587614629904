import {earTagWithoutCountryRegexGen} from 'api/gen/EarTagWithoutCountry';
import {tCommon} from 'app/translations';
import {RatingValidator} from 'app/utils/rating-validator';
import {Errors} from 'utils/validator';

import {SelectAnimalFormValues} from '../../types/select-animal-form-values';

export const validate = (values: SelectAnimalFormValues): Errors<SelectAnimalFormValues> => {
    const validator = new RatingValidator(values);

    validator.nonEmpty('earTag', tCommon('earTag'));
    validator.pattern('earTag', earTagWithoutCountryRegexGen(), tCommon('earTag'));

    return validator.generateErrorsObject();
};
