import React, {FC, memo} from 'react';
import {useSelector} from 'react-redux';

import {useOurTranslation} from 'app/translations';
import {simpleUserSelector} from 'app/user';
import {Content, PageHeader} from 'common/layout';

import {DatabaseOfMeasuredTable} from '../components';

const ListBase: FC = () => {
    const {t} = useOurTranslation('databaseOfMeasured/list');

    const currentUser = useSelector(simpleUserSelector.currentUser);

    return (
        <Content>
            <PageHeader title={t('databaseOfMeasured')} />

            <DatabaseOfMeasuredTable
                currentUser={currentUser}
            />
        </Content>
    );
};

export const List = memo(ListBase);
