import {putAll, showBeError, downloadFileFromResponse, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {ExportBullMarkedDaughtersAction} from '../action';

const defaultPrintFilename = 'export_dcer_s_priznakem_T.csv';

function* execute({payload: {bullId}}: ExportBullMarkedDaughtersAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('exportBullDaughters', true));

    const response = yield* call(Api.getCsvWithMarkedBullDaughters, {bullId});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('bull/sagas')('exportBullMarkedDaughters')));
    } else {
        yield* call(downloadFileFromResponse, response, defaultPrintFilename);
    }

    yield* put(layoutAction.setItemLoading('exportBullDaughters', false));
}

export function* exportBullMarkedDaughtersSaga(): SagaIterator {
    yield takeLatestF('bull/EXPORT_BULL_MARKED_DAUGHTERS', execute);
}
