import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {BreederSuite} from 'api/gen/BreederSuite';
import {EmailTemplate} from 'api/gen/EmailTemplate';
import {PageBreederSuite} from 'api/gen/PageBreederSuite';

import {BreederSuiteRow} from '../components/breeder-suites-table/columns';
import {SendToBreedersFormValues} from '../components/send-to-breeders-form/form-values';

export const breederSuiteAction = {
    getBreederSuites: (tableDataRequest: Opt<TableDataRequest<BreederSuite>>) => ({
        type: 'breederSuite/GET_BREEDER_SUITES',
        payload: {tableDataRequest},
    }) as const,
    getBreederSuitesSuccess: (breederSuitePage: Opt<PageBreederSuite>) => ({
        type: 'breederSuite/GET_BREEDER_SUITES_SUCCESS',
        payload: {breederSuitePage},
    }) as const,
    toggleSelect: (ids: Array<string>, rows: Array<BreederSuiteRow>) => ({
        type: 'breederSuite/TOGGLE_SELECT',
        payload: {ids, rows},
    }) as const,
    exportBreederSuites: (ids: Array<string>) => ({
        type: 'breederSuite/EXPORT_BREEDER_SUITES',
        payload: {ids},
    }) as const,
    sendMailToBreeders: (data: SendToBreedersFormValues) => ({
        type: 'breederSuite/SEND_MAIL_TO_BREEDERS',
        payload: {data},
    }) as const,
    getEmailTemplate: () => ({
        type: 'breederSuite/GET_EMAIL_TEMPLATE',
    }) as const,
    getEmailTemplateSuccess: (emailTemplate: EmailTemplate) => ({
        type: 'breederSuite/GET_EMAIL_TEMPLATE_SUCCESS',
        payload: {emailTemplate},
    }) as const,
};

export type GetBreederSuitesAction = ReturnType<typeof breederSuiteAction.getBreederSuites>;
export type GetBreederSuitesSuccessAction = ReturnType<typeof breederSuiteAction.getBreederSuitesSuccess>;
export type ToggleSelectAction = ReturnType<typeof breederSuiteAction.toggleSelect>;
export type ExportBreederSuitesAction = ReturnType<typeof breederSuiteAction.exportBreederSuites>;
export type SendMailToBreedersAction = ReturnType<typeof breederSuiteAction.sendMailToBreeders>;
export type GetEmailTemplateAction = ReturnType<typeof breederSuiteAction.getEmailTemplate>;
export type GetEmailTemplateSuccessAction = ReturnType<typeof breederSuiteAction.getEmailTemplateSuccess>;

export type BreederSuiteAction =
    | GetBreederSuitesAction
    | GetBreederSuitesSuccessAction
    | ToggleSelectAction
    | ExportBreederSuitesAction
    | SendMailToBreedersAction
    | GetEmailTemplateAction
    | GetEmailTemplateSuccessAction
;
