import {NotificationState} from '@fl/cmsch-fe-library';
import {RouterState} from 'redux-first-history';

import {AdminState, initialAdminState} from 'app/admin';
import {BreederState, initialBreederState} from 'app/breeder';
import {BreederSuiteState, initialBreederSuiteState} from 'app/breeder-suite/model';
import {BreedingState, initialBreedingState} from 'app/breeding';
import {BullState, initialBullState} from 'app/bull';
import {DatabaseOfMeasuredState, initialDatabaseOfMeasuredState} from 'app/database-of-measured/model/state';
import {DistrictState, initialDistrictState} from 'app/district';
import {EmailState, initialEmailState} from 'app/email/model/state';
import {ImportKkutState, initialImportKkutState} from 'app/import-kkut';
import {MeasurementState, initialMeasurementState} from 'app/measurement';
import {PlanningState, initialPlanningState} from 'app/planning';
import {initialRepairState, RepairState} from 'app/repair';
import {UnknownAnimalState, initialUnknownAnimalState} from 'app/unknown-animal';
import {UserState, initialUserState} from 'app/user';
import {LayoutState, initialLayoutState} from 'common/layout/model/state';
import {TablesState, initialTablesState} from 'utils/tables';

import {FormStates} from './form-state';

export interface State {
    router: RouterState;
    toastr: NotificationState;
    form: FormStates;
    layout: LayoutState;
    user: UserState;
    district: DistrictState;
    breeding: BreedingState;
    importKkut: ImportKkutState;
    bull: BullState;
    planning: PlanningState;
    measurements: MeasurementState;
    breeder: BreederState;
    unknownAnimal: UnknownAnimalState;
    breederSuite: BreederSuiteState;
    databaseOfMeasured: DatabaseOfMeasuredState;
    repair: RepairState;
    tables: TablesState;
    admin: AdminState;
    email: EmailState;
}

export const defaultState: Partial<State> = {
    layout: initialLayoutState,
    user: initialUserState,
    district: initialDistrictState,
    importKkut: initialImportKkutState,
    breeding: initialBreedingState,
    bull: initialBullState,
    planning: initialPlanningState,
    measurements: initialMeasurementState,
    breeder: initialBreederState,
    unknownAnimal: initialUnknownAnimalState,
    breederSuite: initialBreederSuiteState,
    databaseOfMeasured: initialDatabaseOfMeasuredState,
    repair: initialRepairState,
    tables: initialTablesState,
    admin: initialAdminState,
    email: initialEmailState,
};
