import {usePrevious, useIdFromRoute} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import React, {FC, memo, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {opt} from 'ts-opt';

import {useOurTranslation} from 'app/translations';
import {Content, LoaderWrapper, PageHeader, simpleLayoutSelector} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {BreederStablesBreedingsTable} from '../components/breeder-stable-breedings-table';
import {StableForm} from '../components/stable-form';
import {breederAction, simpleBreederSelector} from '../model';
import {StableFormValues} from '../types/stable-form-values';

const StableDetailBase: FC = _ => {
    const breedings = useSelector(simpleBreederSelector.breedings);
    const stable = useSelector(simpleBreederSelector.stable);
    const alternativeEmails = useSelector(simpleBreederSelector.alternativeEmails);
    const loadingStable = useSelector(simpleLayoutSelector.isItemLoading('stable'));
    const loadingStableEmails = useSelector(simpleLayoutSelector.isItemLoading('stableEmails'));
    const loading = loadingStable || loadingStableEmails;

    const stableId = useIdFromRoute('id');
    const {t} = useOurTranslation('breeder');
    const dispatch = useDispatch();

    const updateBreedingArealRating = useCallback((breedingId: number, arealRating: boolean) => {
        dispatch(breederAction.updateBreedingArealRating(breedingId, arealRating));
    }, [dispatch]);

    const setStableFormValues = useCallback((stableValues: StableFormValues) => {
        dispatch(formHelpers.initialize('stableForm', stableValues));
    }, [dispatch]);

    const saveStable = useCallback(() => {
        if (stable !== null) {
            dispatch(breederAction.updateStable(stable.id, stable.breederId));
        }
    }, [dispatch, stable]);

    const onBack = useCallback(() => {
        if (stable) dispatch(routerActions.push(`/breeder-detail/${stable.breederId}`));
    }, [dispatch, stable]);

    useEffect(() => {
        dispatch(breederAction.clearShownBreedings());
    }, [dispatch]);

    useEffect(() => {
        dispatch(breederAction.getStable(stableId));
        dispatch(breederAction.getStablesAlternativeEmails([stableId]));
    }, [dispatch, stableId]);

    const prevStable = usePrevious(stable);
    useEffect(() => {
        if (!isNull(stable) && stable.id !== prevStable?.id) dispatch(breederAction.getStableBreedings(stable.id));
    }, [dispatch, prevStable?.id, stable]);

    const initialValues: StableFormValues = useMemo(() => ({
        alternativeEmails: alternativeEmails?.map(email => email.email) ?? [],
        email: opt(stable).prop('email').orNull(),
        note: opt(stable).prop('note').orNull(),
        phone: opt(stable).prop('phone').orNull(),
    }), [alternativeEmails, stable]);

    return (
        <div data-test-id="stable-detail">
            <Content>
                <PageHeader
                    title={stable?.name || ''}
                    onBackTooltip={t('stable')}
                    onBack={onBack}
                    smallerTitleOnPhone
                    loading={loading}
                />

                <LoaderWrapper loading={loading}>
                    <StableForm
                        initialValues={initialValues}
                        onSubmit={saveStable}
                        setStableFormValues={setStableFormValues}
                    />
                </LoaderWrapper>
            </Content>

            <Content>
                <LoaderWrapper loading={isNull(breedings) || isNull(stable)}>
                    <BreederStablesBreedingsTable
                        breedings={breedings}
                        updateBreedingArealRating={updateBreedingArealRating}
                    />
                </LoaderWrapper>
            </Content>
        </div>
    );
};

export const StableDetail = memo(StableDetailBase);
