import React, {FC, memo, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {useOurTranslation} from 'app/translations';
import {simpleUserSelector, userAction} from 'app/user';
import {Content, layoutAction, PageHeader, simpleLayoutSelector} from 'common/layout';

import {AdminPlansTable, ExportUserMeasurementsForm, MeasuredAnimalsModal} from '../components';
import {planningAction, simplePlanningSelector} from '../model';
import {ExportUserMeasurementsType} from '../types/export-user-measurements-type';

const AdminPlansBase: FC = _ => {
    const adminPlans = useSelector(simplePlanningSelector.adminPlans);
    const allUsers = useSelector(simpleUserSelector.allUsers);
    const exporting = useSelector(simpleLayoutSelector.isItemLoading('exportMeasurements'));
    const isAdmin = useSelector(simpleUserSelector.isAdmin);

    const {t} = useOurTranslation('planning/AdminPlans');
    const dispatch = useDispatch();

    const exportMeasurements = useCallback((exportType: ExportUserMeasurementsType) => {
        dispatch(planningAction.exportUserMeasurements(exportType));
    }, [dispatch]);

    const openMeasuredAnimalModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('measuredAnimals', true));
    }, [dispatch]);

    const closeMeasuredAnimalModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('measuredAnimals', false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(planningAction.resetAdminPlansScreen());
        dispatch(planningAction.getAdminPlans());
        if (isAdmin) dispatch(userAction.getAllUsers());
    }, [dispatch, isAdmin]);

    return (
        <Content>
            <PageHeader title={t('title')} />

            <ExportUserMeasurementsForm
                allUsers={allUsers}
                exporting={exporting}
                onExportMeasurements={exportMeasurements}
                openMeasuredAnimalModal={openMeasuredAnimalModal}
                isAdmin={isAdmin}
            />

            <PageHeader title={t('pdaManagement')} />

            <AdminPlansTable
                adminPlans={adminPlans}
                isAdmin={isAdmin}
            />
            <MeasuredAnimalsModal
                closeModal={closeMeasuredAnimalModal}
                exportMeasurements={exportMeasurements}
            />
        </Content>
    );
};

export const AdminPlans = memo(AdminPlansBase);
