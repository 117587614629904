// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractMeasurementUpdate, AbstractMeasurementUpdateSchema } from 'api/gen/AbstractMeasurementUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinMeasurementProperties, HolsteinMeasurementPropertiesSchema } from 'api/gen/HolsteinMeasurementProperties';

const requiredPart = t.interface({
  tag: t.literal('HolsteinMeasurementUpdate'),
  properties: HolsteinMeasurementPropertiesSchema,
});

export const HolsteinMeasurementUpdateSchema = excess(t.intersection([AbstractMeasurementUpdateSchema.type, requiredPart]));

export interface HolsteinMeasurementUpdate extends t.TypeOf<typeof HolsteinMeasurementUpdateSchema> {}
