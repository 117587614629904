// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederSuite, BreederSuiteSchema } from 'api/gen/BreederSuite';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageableObject, PageableObjectSchema } from 'api/gen/PageableObject';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Sort, SortSchema } from 'api/gen/Sort';

const requiredPart = t.interface({
  content: t.union([t.array(BreederSuiteSchema), t.null]),
  empty: t.union([t.boolean, t.null]),
  first: t.union([t.boolean, t.null]),
  last: t.union([t.boolean, t.null]),
  number: t.union([t.Integer, t.null]),
  numberOfElements: t.union([t.Integer, t.null]),
  pageable: t.union([PageableObjectSchema, t.null]),
  size: t.union([t.Integer, t.null]),
  sort: t.union([SortSchema, t.null]),
  totalElements: t.union([t.Integer, t.null]),
  totalPages: t.union([t.Integer, t.null]),
});

export const PageBreederSuiteSchema = excess(requiredPart);

export interface PageBreederSuite extends t.TypeOf<typeof PageBreederSuiteSchema> {}
