import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {adminAction, GetAppInfoAction} from '../action';

function* execute(_action: GetAppInfoAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('appInfo', true));

    const response = yield* call(Api.getApplicationInfo);

    if (response.isSuccess) {
        yield* put(adminAction.getAppInfoSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('admin/messages')('getAppInfo')));
    }

    yield* put(layoutAction.setItemLoading('appInfo', false));
}

export function* getAppInfoSaga(): SagaIterator {
    yield takeLatestF('admin/GET_APP_INFO', execute);
}
