export const planEmailFormName = 'planEmail';

export interface PlanEmailFormValues {
    emailsTo: Array<string>;
    content: string;
    emailsToSendCopyTo: Array<string>;
}

export const initialPlanEmailFormValues: PlanEmailFormValues = {
    content: '',
    emailsToSendCopyTo: [],
    emailsTo: [],
};
