import {earTagWithoutCountryRegexGen} from 'api/gen/EarTagWithoutCountry';
import {FleckviehMeasurementProperties} from 'api/gen/FleckviehMeasurementProperties';
import {HolsteinMeasurementProperties} from 'api/gen/HolsteinMeasurementProperties';
import {positiveIntegerMinimum} from 'api/gen/PositiveInteger';
import {tCommon} from 'app/translations';
import {RatingValidator} from 'app/utils/rating-validator';
import {Errors} from 'utils/validator';

import {RepairHolsteinMeasurementFormValues} from './values';

// eslint-disable-next-line max-lines-per-function
const validateProperties = (values: HolsteinMeasurementProperties): Errors<FleckviehMeasurementProperties> => {
    const validator = new RatingValidator(values);

    validator.nonEmpty('adjustedBodyStructure', tCommon('adjustedBodyStructure'));
    validator.nonEmpty('adjustedExtremities', tCommon('adjustedExtremities'));
    validator.nonEmpty('adjustedLactationPerformance', tCommon('adjustedLactationPerformance'));
    validator.nonEmpty('adjustedOverallCondition', tCommon('adjustedOverallCondition'));
    validator.nonEmpty('adjustedUdder', tCommon('adjustedUdder'));
    validator.nonEmpty('backAngle', tCommon('backAngle'));
    validator.nonEmpty('backWidth', tCommon('backWidth'));
    validator.nonEmpty('bodyDepth', tCommon('bodyDepth'));
    validator.nonEmpty('bonesQuality', tCommon('bonesQuality'));
    validator.nonEmpty('calculatedBodyStructure', tCommon('calculatedBodyStructure'));
    validator.nonEmpty('calculatedExtremities', tCommon('calculatedExtremities'));
    validator.nonEmpty('calculatedLactationPerformance', tCommon('calculatedLactationPerformance'));
    validator.nonEmpty('calculatedOverallCondition', tCommon('calculatedOverallCondition'));
    validator.nonEmpty('calculatedUdder', tCommon('calculatedUdder'));
    validator.nonEmpty('chestWidth', tCommon('chestWidth'));
    validator.nonEmpty('edginess', tCommon('edginess'));
    validator.nonEmpty('fitness', tCommon('fitness'));
    validator.nonEmpty('foreUdderAttachment', tCommon('foreUdderAttachment'));
    validator.nonEmpty('frame', tCommon('frame'));
    validator.nonEmpty('frontTeatsPlacement', tCommon('frontTeatsPlacement'));
    validator.nonEmpty('height', tCommon('height'));
    validator.nonEmpty('hooves', tCommon('hooves'));
    validator.nonEmpty('pasternDefect', tCommon('pasternDefect'));
    validator.nonEmpty('rearPosture', tCommon('rearPostureFleckvieh'));
    validator.nonEmpty('rearTeatsPlacement', tCommon('rearTeatsPlacement'));
    validator.nonEmpty('rearUdderHeight', tCommon('rearUdderHeight'));
    validator.nonEmpty('shortSacrum', tCommon('shortSacrum'));
    validator.nonEmpty('shouldersDefect', tCommon('shouldersDefect'));
    validator.nonEmpty('sidePosture', tCommon('sidePosture'));
    validator.nonEmpty('supernumeraryTeats', tCommon('supernumeraryTeats'));
    validator.nonEmpty('suspensoryLigament', tCommon('suspensoryLigament'));
    validator.nonEmpty('teatsDefect', tCommon('teatsDefect'));
    validator.nonEmpty('teatsLength', tCommon('teatsLength'));
    validator.nonEmpty('udderDefect', tCommon('udderDefect'));
    validator.nonEmpty('udderDepth', tCommon('udderDepth'));
    validator.nonEmpty('udderWidth', tCommon('udderWidth'));
    validator.nonEmpty('upperLineDefect', tCommon('upperLineDefect'));
    validator.nonEmpty('walkQuality', tCommon('walkQuality'));

    return validator.generateErrorsObject();
};

export const validate = (
    values: RepairHolsteinMeasurementFormValues,
): Errors<RepairHolsteinMeasurementFormValues> => {
    const validator = new RatingValidator(values);

    validator.nonEmpty('earTag', tCommon('earTag'));
    validator.pattern('earTag', earTagWithoutCountryRegexGen(), tCommon('earTag'));
    validator.nonEmpty('lactation', tCommon('lactation'));
    validator.minNumber('lactation', tCommon('lactation'), positiveIntegerMinimum);
    validator.nonEmpty('ratingDate', tCommon('ratingDate'));

    const errors = validator.generateErrorsObject();

    if (values.properties) {
        errors.properties = validateProperties(values.properties);
    }

    return errors;
};
