import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, memo, ReactNode} from 'react';

import {Ant} from 'common/ant';
import {Button} from 'common/buttons';

import styles from './styles.sass';

interface OuterProps {
    rowName: ReactNode;
    rowValue: string | undefined;
    onClick?(): void;
}

type Props = OuterProps;

const AppInfoRowBase: FC<Props> = ({rowName, rowValue, onClick}) => {
    const textClasses = classNames(onClick && styles.clickableText);
    const valueText = (
        <Ant.Typography.Text strong>
            <span className={textClasses}>
                {rowValue}
            </span>
        </Ant.Typography.Text>
    );

    const valuePart = onClick
        ? (
            <div>
                <Button
                    onClick={onClick}
                    visuals="link"
                    autoSize
                    noPadding
                >
                    {valueText}
                </Button>
            </div>
        )
        : valueText;

    return (
        <Ant.Row gutter={16}>
            <Ant.Col
                xs={13}
                sm={9}
                lg={5}
                xl={4}
            >
                {rowName}
            </Ant.Col>
            <Ant.Col
                xs={11}
                sm={15}
                lg={19}
                xl={20}
                className={styles.versionColumn}
            >
                {valuePart}
            </Ant.Col>
        </Ant.Row>
    );
};

export const AppInfoRow = memo(AppInfoRowBase);
