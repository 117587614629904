import {TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairHolsteinMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairHolsteinMeasurementFormValues, 'properties'>;

const SummaryFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="adjustedLactationPerformance"
                label={tCommon('adjustedLactationPerformance')}
                isRequired
            />
            <Fields.NumberInput
                name="adjustedBodyStructure"
                label={tCommon('adjustedBodyStructure')}
                isRequired
            />
            <Fields.NumberInput
                name="adjustedUdder"
                label={tCommon('adjustedUdder')}
                isRequired
            />
            <Fields.NumberInput
                name="adjustedExtremities"
                label={tCommon('adjustedExtremities')}
                isRequired
            />
            <Fields.NumberInput
                name="adjustedOverallCondition"
                label={tCommon('adjustedOverallCondition')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedLactationPerformance"
                label={tCommon('calculatedLactationPerformance')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedBodyStructure"
                label={tCommon('calculatedBodyStructure')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedUdder"
                label={tCommon('calculatedUdder')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedExtremities"
                label={tCommon('calculatedExtremities')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedOverallCondition"
                label={tCommon('calculatedOverallCondition')}
                isRequired
            />
        </Fragment>
    );
};

export const SummaryFormSection = memo(SummaryFormSectionBase);
