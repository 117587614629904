import {PlanningAnimal} from 'api/gen/PlanningAnimal';
import {PlanningBreeding} from 'api/gen/PlanningBreeding';
import {calveDaysWarningTreshold} from 'app/consts';

export const isAnimalOverCalveDaysWarningThreshold = (animal: PlanningAnimal, breedingIsAreal: boolean): boolean => {
    const daysSinceCalveDate = animal.daysSinceCalveDate ?? 0;

    return (breedingIsAreal || animal.marked) && daysSinceCalveDate > calveDaysWarningTreshold;
};

export const isBreedingOverCalveDaysWarningThreshold = (breedingView: PlanningBreeding): boolean => {
    const maxDaysSinceCalveDateOfMarked = breedingView.maxDaysSinceCalveDateOfMarked ?? 0;

    return (breedingView.arealRating && breedingView.maxDaysSinceCalveDate > calveDaysWarningTreshold)
        || maxDaysSinceCalveDateOfMarked > calveDaysWarningTreshold;
};
