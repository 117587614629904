import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {breedingAction} from 'app/breeding/model/action';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {AddAnimalsToPlanAction, planningAction} from '../action';

function* execute({payload: {animalIds}}: AddAnimalsToPlanAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('updatePlan', true));
    yield* put(breedingAction.addIdsToLoading(animalIds));

    const response = yield* call(Api.addAnimalsToPlan, {animalIds});

    yield* put(breedingAction.removeIdsFromLoading(animalIds));
    if (response.isSuccess) {
        yield* put(planningAction.getPlan());
    } else {
        yield putAll(showBeError(response, t('planning/messages')('updatePlanTitle')));
    }

    yield* put(layoutAction.setItemLoading('updatePlan', false));
}

export function* addAnimalsToPlanSaga(): SagaIterator {
    yield takeLatestF('planning/ADD_ANIMALS_TO_PLAN', execute);
}
