// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// Example: PLANNED

export const AnimalInPlanTypeSchema = t.keyof({
  PLANNED: null,
  FROM_PLANNED_STABLE: null,
  FROM_MOBILE: null,
  FROM_WEB: null,
});

export type AnimalInPlanType = t.TypeOf<typeof AnimalInPlanTypeSchema>;
