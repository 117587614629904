import {approveMeasurementsSaga} from './approve-measurements-saga';
import {assignRatingSaga} from './assign-rating-saga';
import {deleteMeasurementSaga} from './delete-measurement-saga';
import {findEarTagsSaga} from './find-eartags-saga';
import {getMeasurementsSaga} from './get-measurements-saga';
import {getMeasurementsStatsSaga} from './get-measurements-stats-saga';
import {getRatingsOfAnimalSaga} from './get-ratings-of-animal-saga';
import {mortifyMeasurementSaga} from './mortify-measurement-saga';
import {updateRatingSaga} from './update-rating-saga';

export const measurementSagas = [
    getMeasurementsSaga,
    approveMeasurementsSaga,
    deleteMeasurementSaga,
    mortifyMeasurementSaga,
    getRatingsOfAnimalSaga,
    assignRatingSaga,
    findEarTagsSaga,
    updateRatingSaga,
    getMeasurementsStatsSaga,
];
