import {TableDataRequest, Nullable, ToolbarButton} from '@fl/cmsch-fe-library';
import {isUndefined} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {MeasurementView} from 'api/gen/MeasurementView';
import {UserDetails} from 'api/gen/UserDetails';
import {useOurTranslation} from 'app/translations';
import {simpleLayoutSelector} from 'common/layout';
import {ConnectedTable} from 'utils/tables';

import {databaseOfMeasuredAction, simpleDatabaseOfMeasuredSelector} from '../../model';
import {getFleckviehColumns} from './columns-fleckvieh';
import {getHolsteinColumns} from './columns-holstein';

interface Props {
    currentUser: Nullable<UserDetails>;
}

const DatabaseOfMeasuredTableBase: FC<Props> = ({currentUser}) => {
    const dispatch = useDispatch();

    const getMeasurements = useCallback((tableDataRequest: Opt<TableDataRequest<MeasurementView>>) => {
        dispatch(databaseOfMeasuredAction.getMeasurements(tableDataRequest));
    }, [dispatch]);

    const exportMeasured = useCallback(() => {
        dispatch(databaseOfMeasuredAction.exportMeasured());
    }, [dispatch]);

    const {t, tCommon} = useOurTranslation('databaseOfMeasured/DatabaseOfMeasuredTable');
    const {tenant} = currentUser || {};
    const measuredExporting = useSelector(simpleLayoutSelector.isItemLoading('exportMeasured'));

    const headerButtons = useMemo((): Array<ToolbarButton> => [
        {
            title: t('exportMeasured'),
            icon: 'downloadOutlined',
            visuals: 'primary',
            loading: measuredExporting,
            onClick: exportMeasured,
            testId: 'export',
        },
    ], [exportMeasured, t, measuredExporting]);

    if (isUndefined(tenant)) return null;

    return tenant === 'HOLSTEIN' ? (
        <ConnectedTable
            tableId="databaseOfMeasuredHolstein"
            columns={getHolsteinColumns(tCommon)}
            rowDataPageSelector={simpleDatabaseOfMeasuredSelector.measurementsPage}
            onFetchTableData={getMeasurements}
            withoutVerticalScrollbar
            bordered
            headerButtons={headerButtons}
            showFulltextFilter
        />
    ) : (
        <ConnectedTable
            tableId="databaseOfMeasuredFleckvieh"
            columns={getFleckviehColumns(t, tCommon)}
            rowDataPageSelector={simpleDatabaseOfMeasuredSelector.measurementsPage}
            onFetchTableData={getMeasurements}
            withoutVerticalScrollbar
            bordered
            headerButtons={headerButtons}
            showFulltextFilter
        />
    );
};

export const DatabaseOfMeasuredTable = memo(DatabaseOfMeasuredTableBase);
