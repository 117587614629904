import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {none} from 'ts-opt';

import {FindAllApprovedByAnimalQueryParams} from 'api/gen/Api';
import {earTagWithoutCountryMaxLength} from 'app/consts';
import {foundEarTagsSelector, measurementAction} from 'app/measurement';
import {useOurTranslation} from 'app/translations';
import {pathNamesSelector} from 'app/user';
import {Content, PageHeader, Panel, simpleLayoutSelector} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {RepairMeasurementsTable, SearchMeasurementsForm} from '../components';
import {repairAction, simpleRepairSelector} from '../model';

const repairDetailPathRegex = /^\/repairs\/(\d+)$/;

const ListBase: FC = _ => {
    const measurementSearch = useSelector(simpleRepairSelector.measurementSearch);
    const foundEarTags = useSelector(foundEarTagsSelector);
    const {previous, current} = useSelector(pathNamesSelector);
    const loading = useSelector(simpleLayoutSelector.isItemLoading('getAllApprovedMeasurements'));
    const findEarTagsInProgress = useSelector(simpleLayoutSelector.isItemLoading('findEarTags'));
    const dispatch = useDispatch();
    const resetData = useCallback(() => {
        dispatch(repairAction.getAllApprovedMeasurementsSuccess(none));
        dispatch(formHelpers.reset('searchMeasurements'));
    }, [dispatch]);
    const getAllApprovedMeasurements = useCallback((params: FindAllApprovedByAnimalQueryParams) => {
        dispatch(repairAction.getAllApprovedMeasurements(params));
    }, [dispatch]);
    const findEarTags = useCallback((value: string) => {
        if (value.length <= earTagWithoutCountryMaxLength) dispatch(measurementAction.findEarTags(value));
    }, [dispatch]);

    const {t} = useOurTranslation('repair/List');

    useEffect(() => {
        if (current.contains('/repairs') && !previous.testReOrFalse(repairDetailPathRegex)) {
            resetData();
        }
    }, [current, previous, resetData]);

    return (
        <Content>
            <PageHeader title={t('title')} />

            <Panel>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <SearchMeasurementsForm
                            foundEarTags={foundEarTags}
                            findEarTagsInProgress={findEarTagsInProgress}
                            onSubmit={getAllApprovedMeasurements}
                            findEarTags={findEarTags}
                        />
                    </div>
                </div>
            </Panel>

            {(measurementSearch.nonEmpty || loading) && (
                <RepairMeasurementsTable
                    measurements={measurementSearch}
                    loading={loading}
                />
            )}
        </Content>
    );
};

export const List = memo(ListBase);
