import {putAll, showBeError, showSuccess, extractFormErrorsFromResponse, takeLatestF} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {opt, optEmptyString} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {StableEmail} from 'api/gen/StableEmail';
import {UpdateStable} from 'api/gen/UpdateStable';
import {FormStates} from 'app/setup';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';

import {breederAction, UpdateStableAction} from '../action';

const updateStableFormName: keyof FormStates = 'stableForm';
const tr = t('breeder');

// eslint-disable-next-line max-lines-per-function
function* execute({payload: {stableId, breederId}}: UpdateStableAction): SagaIterator {
    yield* put(formHelpers.startSubmit(updateStableFormName));

    const stableFormValues = (yield* select(formHelpers.formValues('stableForm'))).orCrash('Missing values');
    const values: UpdateStable = {
        email: optEmptyString(stableFormValues.email).orNull(),
        phone: optEmptyString(stableFormValues.phone).orNull(),
        note: optEmptyString(stableFormValues.note).orNull(),
    };
    const alternativeEmails: Array<StableEmail> = stableFormValues.alternativeEmails?.map(x => ({email: x})) || [];

    const response = yield* call(Api.updateStable, values, {stableId});
    const responseAlternativeEmails = yield* call(
        Api.updateEmailsOfStable,
        alternativeEmails,
        {stableId},
    );

    if (response.isSuccess && responseAlternativeEmails.isSuccess) {
        yield* put(showSuccess(tr('stablesAdministration'), tr('changesSaved'), false, 'update-stable'));

        yield* put(breederAction.setEditedStableId(opt(stableId)));
        yield* put(routerActions.push(`/breeder-detail/${breederId}`));
    } else {
        yield putAll(showBeError(response, tr('stableAdministration')));
        if (!response.isSuccess) {
            yield* put(formHelpers.stopSubmit(updateStableFormName, extractFormErrorsFromResponse(response)));
        } else {
            yield* put(formHelpers.stopSubmit(
                updateStableFormName,
                extractFormErrorsFromResponse(responseAlternativeEmails),
            ));
        }
    }
}

export function* updateStableSaga(): SagaIterator {
    yield takeLatestF('breeder/UPDATE_STABLE', execute);
}
