/* eslint-disable max-lines */
import {AnyTableColumn} from '@fl/cmsch-fe-library';

import {FleckviehMeasurementAnimal} from 'api/gen/FleckviehMeasurementAnimal';
import {formatFleckviehResult} from 'app/measurement/utils/format-result';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const getFleckviehColumns = (
    tCommon: TFunction<'common'>,
): Array<AnyTableColumn<FleckviehMeasurementAnimal>> => [
    {
        column: tCommon('tableGroupingHeaders.general'),
        type: 'grouping',
        subColumns: [
            {
                field: 'ratingDate',
                column: tCommon('ratingDate'),
                type: 'date',
                dateFormat: 'date',
                sortable: true,
                width: 98,
                verticalName: true,
            },
            {
                field: 'dead',
                column: tCommon('dead'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'approved',
                column: tCommon('approved'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'firstName',
                column: tCommon('firstName'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 150,
                verticalName: true,
            },
            {
                field: 'lastName',
                column: tCommon('lastName'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 150,
                verticalName: true,
            },
            {
                field: 'lastMilkingAt',
                column: tCommon('lastMilkingAt'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'lactation',
                column: tCommon('lactation'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.frameRating'),
        type: 'grouping',
        subColumns: [
            {
                field: 'backHeight',
                column: tCommon('backHeight'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'withersHeight',
                column: tCommon('withersHeightAbbr'),
                tooltip: tCommon('withersHeight'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'backLength',
                column: tCommon('backLength'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'backWidth',
                column: tCommon('backWidth'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'bodyDepth',
                column: tCommon('bodyDepth'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'chestCircumference',
                column: tCommon('chestCircumference'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'bodyLength',
                column: tCommon('bodyLengthAbbr'),
                tooltip: tCommon('bodyLength'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.frameDefects'),
        type: 'grouping',
        subColumns: [
            {
                field: 'weakBack',
                column: tCommon('weakBack'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'looseLoins',
                column: tCommon('looseLoins'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'roofedBack',
                column: tCommon('roofedBack'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.musculatureRating'),
        type: 'grouping',
        subColumns: [
            {
                field: 'backAngle',
                column: tCommon('backAngle'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'musculature',
                column: tCommon('musculature'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'fitness',
                column: tCommon('fitness'),
                type: 'decimal',
                align: 'right',
                filterTypes: ['null'],
                fixedDecimals: 1,
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.extremitiesRating'),
        type: 'grouping',
        subColumns: [
            {
                field: 'rearPosture',
                column: tCommon('rearPostureFleckvieh'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'pastern',
                column: tCommon('pastern'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'hoovesBackHeight',
                column: tCommon('hoovesBackHeightAbbr'),
                tooltip: tCommon('hoovesBackHeight'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'ankleJoint',
                column: tCommon('ankleJointAbbr'),
                tooltip: tCommon('ankleJoint'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.extremitiesDefects'),
        type: 'grouping',
        subColumns: [
            {
                field: 'looseShoulderBlade',
                column: tCommon('looseShoulderBlade'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontExtremitiesOpenPosture',
                column: tCommon('frontExtremitiesOpenPostureAbbr'),
                tooltip: tCommon('frontExtremitiesOpenPosture'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'openedHooves',
                column: tCommon('openedHoovesAbbr'),
                tooltip: tCommon('openedHooves'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'posture',
                column: tCommon('posture'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'hoovesTorsion',
                column: tCommon('hoovesTorsion'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.udderRating'),
        type: 'grouping',
        subColumns: [
            {
                field: 'frontUdderLength',
                column: tCommon('frontUdderLengthAbbr'),
                tooltip: tCommon('frontUdderLength'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontUdderAttachmentAngle',
                column: tCommon('frontUdderAttachmentAngleAbbr'),
                tooltip: tCommon('frontUdderAttachmentAngle'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearUdderLength',
                column: tCommon('rearUdderLengthAbbr'),
                tooltip: tCommon('rearUdderLength'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearUdderAttachment',
                column: tCommon('rearUdderAttachment'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'suspensoryLigament',
                column: tCommon('suspensoryLigament'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'udderDepth',
                column: tCommon('udderDepth'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontTeatsPlacement',
                column: tCommon('frontTeatsPlacementAbbr'),
                tooltip: tCommon('frontTeatsPlacement'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearTeatsPlacement',
                column: tCommon('rearTeatsPlacementAbbr'),
                tooltip: tCommon('rearTeatsPlacement'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearTeatsPosition',
                column: tCommon('rearTeatsPositionAbbr'),
                tooltip: tCommon('rearTeatsPosition'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'teatsLength',
                column: tCommon('teatsLength'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'teatsWidth',
                column: tCommon('teatsWidth'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.udderDefects'),
        type: 'grouping',
        subColumns: [
            {
                field: 'edematicUdder',
                column: tCommon('edematicUdderAbbr'),
                tooltip: tCommon('edematicUdder'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'gradualUdder',
                column: tCommon('gradualUdderAbbr'),
                tooltip: tCommon('gradualUdder'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontTeatsSideways',
                column: tCommon('frontTeatsSidewaysAbbr'),
                tooltip: tCommon('frontTeatsSideways'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'teatsForward',
                column: tCommon('teatsForwardAbbr'),
                tooltip: tCommon('teatsForward'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'shortRearTeats',
                column: tCommon('shortRearTeats'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'funnelTeats',
                column: tCommon('funnelTeats'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.other'),
        type: 'grouping',
        subColumns: [
            {
                field: 'udderPurity',
                column: tCommon('udderPurity'),
                type: 'string',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'colorType',
                column: tCommon('colorType'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'mottleType',
                column: tCommon('mottleType'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'headColor',
                column: tCommon('headColor'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'nervousness',
                column: tCommon('nervousness'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.summary'),
        type: 'grouping',
        subColumns: [
            {
                field: 'calculatedFrame',
                column: tCommon('calculatedFrame'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'calculatedMusculature',
                column: tCommon('calculatedMusculature'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'calculatedExtremities',
                column: tCommon('calculatedExtremities'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'calculatedUdder',
                column: tCommon('calculatedUdder'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'calculatedTotal',
                column: tCommon('calculatedTotal'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'adjustedMusculature',
                column: tCommon('adjustedMusculature'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                formatValue: formatFleckviehResult('calculatedMusculature'),
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedExtremities',
                column: tCommon('adjustedExtremities'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                formatValue: formatFleckviehResult('calculatedExtremities'),
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedUdder',
                column: tCommon('adjustedUdder'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                formatValue: formatFleckviehResult('calculatedUdder'),
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedTotal',
                column: tCommon('adjustedTotal'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                formatValue: formatFleckviehResult('calculatedTotal'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
        ],
    },
];
