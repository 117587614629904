// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractMeasurementView, AbstractMeasurementViewSchema } from 'api/gen/AbstractMeasurementView';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EncodedMeasurement, EncodedMeasurementSchema } from 'api/gen/EncodedMeasurement';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Flag, FlagSchema } from 'api/gen/Flag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UdderPurity, UdderPuritySchema } from 'api/gen/UdderPurity';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UnknownDate, UnknownDateSchema } from 'api/gen/UnknownDate';

const requiredPart = t.interface({
  tag: t.literal('FleckviehMeasurementView'),
  adjustedExtremities: t.Integer,
  adjustedMusculature: t.Integer,
  adjustedTotal: t.Integer,
  adjustedUdder: t.Integer,
  ankleJoint: t.Integer,
  backAngle: t.Integer,
  backHeight: t.Integer,
  backHeightPoints: t.union([t.Integer, t.null]),
  backLength: t.Integer,
  backLengthPoints: t.union([t.Integer, t.null]),
  backWidth: t.Integer,
  backWidthPoints: t.union([t.Integer, t.null]),
  bodyDepth: t.Integer,
  bodyDepthPoints: t.union([t.Integer, t.null]),
  bodyLength: t.union([t.Integer, t.null]),
  calculatedExtremities: t.union([t.Integer, t.null]),
  calculatedFrame: t.Integer,
  calculatedMusculature: t.union([t.Integer, t.null]),
  calculatedTotal: t.union([t.Integer, t.null]),
  calculatedUdder: t.union([t.Integer, t.null]),
  chestCircumference: t.Integer,
  colorType: t.Integer,
  edematicUdder: t.union([t.Integer, t.null]),
  encodedMeasurement: t.union([EncodedMeasurementSchema, t.null]),
  firstDefect: t.union([t.Integer, t.null]),
  fitness: t.union([t.number, t.null]),
  flag: t.union([FlagSchema, t.null]),
  fourthDefect: t.union([t.Integer, t.null]),
  frontExtremitiesOpenPosture: t.union([t.Integer, t.null]),
  frontTeatsPlacement: t.union([t.Integer, t.null]),
  frontTeatsSideways: t.union([t.Integer, t.null]),
  frontUdderAttachmentAngle: t.Integer,
  frontUdderLength: t.Integer,
  funnelTeats: t.union([t.Integer, t.null]),
  gradualUdder: t.union([t.Integer, t.null]),
  headColor: t.union([t.Integer, t.null]),
  hoovesBackHeight: t.Integer,
  hoovesTorsion: t.union([t.Integer, t.null]),
  lastMilkingAt: PositiveIntegerSchema,
  looseLoins: t.union([t.Integer, t.null]),
  looseShoulderBlade: t.union([t.Integer, t.null]),
  mottleType: t.Integer,
  musculature: t.Integer,
  narrowBack: t.union([t.Integer, t.null]),
  narrowChest: t.union([t.Integer, t.null]),
  nervousness: t.union([t.Integer, t.null]),
  openedHooves: t.union([t.Integer, t.null]),
  pastern: t.Integer,
  posture: t.union([t.Integer, t.null]),
  rearPosture: t.Integer,
  rearTeatsPlacement: t.union([t.Integer, t.null]),
  rearTeatsPosition: t.Integer,
  rearUdderAttachment: t.Integer,
  rearUdderLength: t.Integer,
  roofedBack: t.union([t.Integer, t.null]),
  secondDefect: t.union([t.Integer, t.null]),
  shortRearTeats: t.union([t.Integer, t.null]),
  suspensoryLigament: t.Integer,
  teatsCloseToEachOther: t.union([t.Integer, t.null]),
  teatsForward: t.union([t.Integer, t.null]),
  teatsLength: t.Integer,
  teatsWidth: t.Integer,
  thirdDefect: t.union([t.Integer, t.null]),
  udderDepth: t.Integer,
  udderPurity: t.union([UdderPuritySchema, t.null]),
  unknownDate: t.union([UnknownDateSchema, t.null]),
  unknownFlag: t.union([t.boolean, t.null]),
  weakBack: t.union([t.Integer, t.null]),
  withersHeight: t.union([t.Integer, t.null]),
});

export const FleckviehMeasurementViewSchema = excess(t.intersection([AbstractMeasurementViewSchema.type, requiredPart]));

export interface FleckviehMeasurementView extends t.TypeOf<typeof FleckviehMeasurementViewSchema> {}
