/* eslint-disable import/no-unused-modules */
import {ErrorsFromBE} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {UserDetails} from 'api/gen/UserDetails';

interface InitializeParams {
    needsLogin: boolean;
}

export const userAction = {
    initialize: (params: InitializeParams) => ({
        type: 'user/INITIALIZE',
        payload: {params},
    }) as const,
    initializeFinish: () => ({
        type: 'user/INITIALIZE_FINISH',
    }) as const,
    loadCurrentUser: () => ({
        type: 'user/LOAD_CURRENT_USER',
    }) as const,
    currentUserLoaded: (currentUser: UserDetails) => ({
        type: 'user/CURRENT_USER_LOADED',
        payload: {currentUser},
    }) as const,
    currentUserLoadFailed: (errors: ErrorsFromBE) => ({
        type: 'user/CURRENT_USER_LOAD_FAILED',
        payload: {errors},
    }) as const,
    logInUser: () => ({
        type: 'user/LOG_IN_USER',
    }) as const,
    logOutUser: () => ({
        type: 'user/LOG_OUT_USER',
    }) as const,
    logOutUserSuccess: () => ({
        type: 'user/LOG_OUT_USER_SUCCESS',
    }) as const,
    getAllUsers: () => ({
        type: 'user/GET_ALL_USERS',
    }) as const,
    getAllUsersSuccess: (allUsers: Array<UserDetails>) => ({
        type: 'user/GET_ALL_USERS_SUCCESS',
        payload: {allUsers},
    }) as const,
    setLoadingUser: (isUserLoading: boolean) => ({
        type: 'user/SET_LOADING_USER',
        payload: {isUserLoading},
    }) as const,
    setInitialLoadDone: (value: boolean) => ({
        type: 'user/SET_INITIAL_LOAD_DONE',
        payload: {value},
    }) as const,
    setPreviousAndCurrentPathname: (previous: Opt<string>, current: Opt<string>) => ({
        type: 'user/SET_PREVIOUS_AND_CURRENT_PATHNAME',
        payload: {previous, current},
    }) as const,
    setIsServerError: (isServerError: boolean) => ({
        type: 'user/SET_SERVER_ERROR',
        payload: {isServerError},
    }) as const,
};

export type InitializeAction = ReturnType<typeof userAction.initialize>;
export type InitializeFinishAction = ReturnType<typeof userAction.initializeFinish>;
export type LoadCurrentUserAction = ReturnType<typeof userAction.loadCurrentUser>;
export type CurrentUserLoadedAction = ReturnType<typeof userAction.currentUserLoaded>;
export type CurrentUserLoadFailedAction = ReturnType<typeof userAction.currentUserLoadFailed>;
export type LogInUserAction = ReturnType<typeof userAction.logInUser>;
export type LogOutUserAction = ReturnType<typeof userAction.logOutUser>;
export type LogOutUserSuccessAction = ReturnType<typeof userAction.logOutUserSuccess>;
export type GetAllUsersAction = ReturnType<typeof userAction.getAllUsers>;
export type GetAllUsersSuccessAction = ReturnType<typeof userAction.getAllUsersSuccess>;
export type SetLoadingUserAction = ReturnType<typeof userAction.setLoadingUser>;
export type SetInitialLoadDoneAction = ReturnType<typeof userAction.setInitialLoadDone>;
export type SetPreviousAndCurrentPathnameAction = ReturnType<typeof userAction.setPreviousAndCurrentPathname>;
export type SetisServerErrorAction = ReturnType<typeof userAction.setIsServerError>;

export type UserAction =
    | InitializeAction
    | InitializeFinishAction
    | LoadCurrentUserAction
    | CurrentUserLoadedAction
    | CurrentUserLoadFailedAction
    | LogInUserAction
    | LogOutUserAction
    | LogOutUserSuccessAction
    | GetAllUsersAction
    | GetAllUsersSuccessAction
    | SetLoadingUserAction
    | SetInitialLoadDoneAction
    | SetPreviousAndCurrentPathnameAction
    | SetisServerErrorAction
    ;
