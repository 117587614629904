import {BreederSuite} from 'api/gen/BreederSuite';

import {BreederSuiteRow} from './columns';

export const rowMapper = (row: BreederSuite): BreederSuiteRow => ({
    breederName: row.breederName,
    id: row.suiteId,
    breedingNumber: row.breedingNumber,
    ratingDate: row.ratingDate,
    stableName: row.stableName,
    sumOfAnimals: row.sumOfAnimals,
    suiteId: row.suiteId,
    userCode: row.userCode,
    stableEmail: row.stableEmail,
    stableId: row.stableId,
    lactation: row.lactation,
    emailSentDate: row.emailSentDate,
});
