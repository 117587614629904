import * as React from 'react';
import {FC} from 'react';
import {Redirect, Switch} from 'react-router-dom';

import {breederRoutes} from 'app/breeder';
import {breederSuiteRoutes} from 'app/breeder-suite';
import {breedingRoutes} from 'app/breeding';
import {bullRoutes} from 'app/bull';
import {databaseOfMeasuredRoutes} from 'app/database-of-measured';
import {districtRoutes} from 'app/district';
import {importKkutRoutes} from 'app/import-kkut';
import {measurementsRoutes} from 'app/measurement';
import {planningRoutes} from 'app/planning';
import {repairRoutes} from 'app/repair';
import {userRoutes} from 'app/user';

import {adminRoutes} from './admin';
import {emailRoutes} from './email';

const homePage = '/breedings';

export const Routes: FC = () => (
    <Switch>
        <Redirect
            exact
            from="/"
            to={homePage}
        />
        {breedingRoutes}
        {districtRoutes}
        {userRoutes}
        {bullRoutes}
        {planningRoutes}
        {measurementsRoutes}
        {breederRoutes}
        {importKkutRoutes}
        {breederSuiteRoutes}
        {databaseOfMeasuredRoutes}
        {repairRoutes}
        {adminRoutes}
        {emailRoutes}
        <Redirect to="/" />
    </Switch>
);
