import {ActionSettings, TableDataRequest, TableLegend, TableRowBackground} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {PlanningAnimal} from 'api/gen/PlanningAnimal';
import {simpleBreedingSelector} from 'app/breeding/model';
import {isAnimalOverCalveDaysWarningThreshold} from 'app/breeding/utils/is-over-calve-days-warning-threshold';
import {useOurTranslation} from 'app/translations';
import {simpleUserSelector} from 'app/user';
import {ConnectedTable} from 'utils/tables';

import {getColumns} from './columns';

interface Props {
    breedingIsAreal: boolean;
    animalsInPlan: Array<number>;
    getBreedingAnimals(tableDataRequest: Opt<TableDataRequest<PlanningAnimal>>): void;
    onToggleSelectedAnimals?(animalIds: Array<number>): void;
}

const AnimalsTableBase: FC<Props> = props => {
    const {
        animalsInPlan,
        breedingIsAreal,
        getBreedingAnimals,
        onToggleSelectedAnimals,
    } = props;

    const {t, tCommon} = useOurTranslation('breeding/AnimalsTable');
    const tenant = useSelector(simpleUserSelector.tenant);
    const disabledCheckboxes = useSelector(simpleBreedingSelector.fetchingIds);

    const calcRowBackgroundDesign = useCallback(
        (row: PlanningAnimal, _index: number): TableRowBackground | undefined =>
            isAnimalOverCalveDaysWarningThreshold(row, breedingIsAreal) ? 'dangerous' : undefined,
        [breedingIsAreal]);

    const isCheckboxDisabled = useCallback((rowId: number): boolean =>
        disabledCheckboxes.includes(Number(rowId)),
    [disabledCheckboxes]);

    const actionSettings = useMemo((): ActionSettings<PlanningAnimal> => ({
        selectedIds: animalsInPlan,
        isSelectDisabled: isCheckboxDisabled,
        onSelectedIdsChange: onToggleSelectedAnimals,
    }), [animalsInPlan, isCheckboxDisabled, onToggleSelectedAnimals]);

    return (
        <Fragment>
            <ConnectedTable
                tableId="animalsInBreeding"
                columns={getColumns(tenant, t, tCommon)}
                rowDataPageSelector={simpleBreedingSelector.animalsInBreedingPage}
                onFetchTableData={getBreedingAnimals}
                enableTableUserSettings
                actionSettings={actionSettings}
                calcRowBackgroundDesign={calcRowBackgroundDesign}
                dontResetSelectedOnUnmount
            />
            <div className="mt-2">
                <TableLegend annotations={[{color: 'dangerous', label: t('dangerousAnnotation')}]} />
            </div>
        </Fragment>
    );
};

export const AnimalsTable = memo(AnimalsTableBase);
