import React from 'react';
import {Route} from 'react-router-dom';

import * as Screens from './screens';

export const adminRoutes = [
    (
        <Route
            exact
            path="/app-info"
            component={Screens.AppInfo}
            key="app-info"
        />
    ),
];
