// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';


// The boniter's note of a bull
// Example: "The best bull in the world!"

export const bullNoteMinLength = 1;
export const bullNoteMaxLength = 255;

export const BullNoteSchema = t.refinement(t.string, n => n.length >= bullNoteMinLength && n.length <= bullNoteMaxLength, 'BullNote');

export type BullNote = string;
