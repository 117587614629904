import {getAnimalsInBreedingSaga} from './get-animals-in-breeding-saga';
import {getBreedingNumbers} from './get-breeding-numbers-saga';
import {getBreedingSaga} from './get-breeding-saga';
import {getBreedingsSaga} from './get-breedings-saga';

export const breedingSagas = [
    getBreedingsSaga,
    getBreedingSaga,
    getAnimalsInBreedingSaga,
    getBreedingNumbers,
];
