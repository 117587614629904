// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// An ear tag of an animal without country code.
// Example: "000222178951"

export const earTagWithoutCountryRegexGen = (): RegExp => new RegExp("^\\w{12}$");

export const EarTagWithoutCountrySchema = StringPatternSchema<EarTagWithoutCountry>(earTagWithoutCountryRegexGen());

export type EarTagWithoutCountry = string;
