import {ActionSettings, Table} from '@fl/cmsch-fe-library';
import React, {FC, memo, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {MeasurementSearch} from 'api/gen/MeasurementSearch';
import {useOurTranslation} from 'app/translations';

import {getColumns} from './columns';

const pathNamesForRefresh: Array<RegExp> = [/^\/repairs\/\d+$/];

interface Props {
    measurements: Opt<Array<MeasurementSearch>>;
    loading: boolean;
}

const RepairMeasurementsTableBase: FC<Props> = props => {
    const {measurements, loading} = props;

    const {t, tCommon} = useOurTranslation('repair/RepairMeasurementTable');

    const actionSettings: ActionSettings<MeasurementSearch> = useMemo(() => ({
        extraActions: [
            {
                id: 'measurementDetail',
                title: t('detail'),
                icon: 'searchOutlined',
                href: (_rowId: string | number, row: MeasurementSearch): string => `/repairs/${row.id}`,
            },
        ],
    }), [t]);

    return (
        <Table
            key="repairMeasurementsTable"
            tableId="repairMeasurementsTable"
            columns={getColumns(tCommon)}
            rows={measurements.orElse([])}
            actionSettings={actionSettings}
            rowActionsOnRight
            emptyTableMessages={{noRows: t('noMeasurements')}}
            scroll={{x: true}}
            pathnamesForRefresh={pathNamesForRefresh}
            loading={loading}
        />
    );
};

export const RepairMeasurementsTable = memo(RepairMeasurementsTableBase);
