import * as React from 'react';
import {FC} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {Login} from 'app/user/screens';

const homePage = '/breedings';

export const UnauthorizedRoutes: FC = () => (
    <Switch>
        <Route
            exact
            path="/login"
            component={Login}
            key="login"
        />
        <Redirect
            exact
            from="/"
            to={homePage}
        />
        <Redirect to="/" />
    </Switch>
);
