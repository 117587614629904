import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import * as routerActions from 'redux-first-history';

import {AppLayout} from 'app/app-layout';
import {AppLogo, CmschLogo} from 'common/layout';
import {SmallMenu} from 'common/layout/components/main-menu/small-menu';

import {LoginForm} from '../components/login-form';
import {userAction} from '../model/action';
import {simpleUserSelector} from '../model/selector';
import {LocationState} from '../types/location-state';

import styles from './styles.sass';

const LoginBase: FC = () => {
    const dispatch = useDispatch();
    const logInUser = useCallback(() => {
        dispatch(userAction.logInUser());
    }, [dispatch]);

    const user = useSelector(simpleUserSelector.currentUser);
    const {state} = useLocation<LocationState | undefined>();

    useEffect(() => {
        if (user && !state?.redirectTo) dispatch(routerActions.push('/'));
    }, [dispatch, state?.redirectTo, user]);

    return user ? null : (
        <AppLayout
            header={(
                <SmallMenu
                    actionIcons={[]}
                    appLogo={<AppLogo environment="development" />}
                    link="#"
                />
            )}
        >
            <div className="d-flex flex-column align-items-center m-auto">
                <div className={`d-flex flex-column justify-content-center ${styles.login}`}>
                    <CmschLogo />
                    <div>
                        <LoginForm onLogin={logInUser} />
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export const Login = memo(LoginBase);
