// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTagWithoutCountry, EarTagWithoutCountrySchema } from 'api/gen/EarTagWithoutCountry';

const requiredPart = t.interface({
  earTags: t.union([t.array(EarTagWithoutCountrySchema), t.null]),
});

export const EarTagsSchema = excess(requiredPart);

export interface EarTags extends t.TypeOf<typeof EarTagsSchema> {}
