import React, {FC, memo} from 'react';

import {Plan} from 'api/gen/Plan';
import {LoaderWrapper} from 'common/layout/components';
import {LoadingItemId} from 'common/layout/types/loading-item-id';

import {PlanBreederSection} from '../plan-breeder-section';

interface Props {
    plan: Plan | null;
    selectedAnimals: Array<number>;
    visibleStables: Array<number>;
    loadingItems: Array<LoadingItemId>;
    onToggleSelectAnimal(animalId: number, breederId: number, animalsInCompany: Array<number>): void;
    onOpenPlanStableEmailModal(stableId: number): void;
    onOpenPlanBreederEmailModal(breederId: number): void;
    onRemoveBreeder(animalIds: Array<number>): void;
    onPrintBreederPlan(breederId: number): void;
    onPrintBreederStablePlan(stableId: number): void;
    onToggleCollapse(id: number): void;
}

const PlanBreederSectionsBase: FC<Props> = props => {
    const {
        plan,
        selectedAnimals,
        visibleStables,
        loadingItems,
        onToggleSelectAnimal,
        onOpenPlanStableEmailModal,
        onOpenPlanBreederEmailModal,
        onRemoveBreeder,
        onPrintBreederPlan,
        onPrintBreederStablePlan,
        onToggleCollapse,
    } = props;

    return (
        <LoaderWrapper loading={loadingItems.includes('plan')}>
            {plan?.breeders?.map(breeder => (
                <PlanBreederSection
                    key={breeder.id}
                    breeder={breeder}
                    selectedAnimals={selectedAnimals}
                    planEditable
                    visibleStables={visibleStables}
                    loadingItems={loadingItems}
                    onToggleSelectAnimal={onToggleSelectAnimal}
                    onOpenPlanStableEmailModal={onOpenPlanStableEmailModal}
                    onOpenPlanBreederEmailModal={onOpenPlanBreederEmailModal}
                    onRemoveBreeder={onRemoveBreeder}
                    onPrintBreederPlan={onPrintBreederPlan}
                    onPrintBreederStablePlan={onPrintBreederStablePlan}
                    onToggleCollapse={onToggleCollapse}
                    testId="breeder"
                />
            ))}
        </LoaderWrapper>
    );
};

export const PlanBreederSections = memo(PlanBreederSectionsBase);
