import {TableDataParams} from '@fl/cmsch-fe-library';
import {includes} from 'lodash/fp';

import {TableRowMap} from 'types/table-row-map';

export type TableId = keyof TableRowMap;

const tableIds: Array<TableId> = [
    'ratingsOfHolsteinAnimal',
    'ratingsOfHolsteinAnimal',
    'breederSuitesFleckvieh',
    'breederSuitesHolstein',
    'databaseOfMeasuredFleckvieh',
    'databaseOfMeasuredHolstein',
    'animalsInBreeding',
    'breeding',
    'daughters',
    'invoicesDaughters',
    'measurements',
    'invoices',
    'sentEmails',
];

export const isValidTableName = (x: string): x is TableId => includes(x, tableIds);

interface TableUserSettingsServerIds {
    module: string;
    code: string;
}

interface TableConfig<TableRow> {
    defaultParams?: TableDataParams<TableRow>;
    serverUserSettingsIds?: TableUserSettingsServerIds;
}

type TableConfigs = {
    [TID in TableId]?: TableConfig<TableRowMap[TID]>;
};

const tableConfigs: TableConfigs = {
    ratingsOfHolsteinAnimal: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: undefined,
        },
    },
    ratingsOfFleckviehAnimal: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: undefined,
        },
    },
    databaseOfMeasuredFleckvieh: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            sorting: [{field: 'ratingDate', direction: 'DESC'}],
        },
    },
    databaseOfMeasuredHolstein: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            sorting: [{field: 'ratingDate', direction: 'DESC'}],
        },
    },
    breederSuitesFleckvieh: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: [{field: 'ratingDate', direction: 'DESC'}],
        },
        serverUserSettingsIds: {
            code: 'breederSuitesFleckvieh',
            module: 'breederSuitesFleckvieh',
        },
    },
    animalsInBreeding: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: undefined,
        },
        serverUserSettingsIds: {
            code: 'animalsInBreeding',
            module: 'animalsInBreeding',
        },
    },
    breeding: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: [{field: 'districtName', direction: 'ASC'}],
        },
        serverUserSettingsIds: {
            code: 'breeding',
            module: 'breeding',
        },
    },
    bull: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: [{field: 'lineRegistry', direction: 'ASC'}],
        },
    },
    daughters: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: [],
        },
    },
    invoicesDaughters: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: [],
        },
    },
    breeder: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: [{field: 'name', direction: 'ASC'}],
        },
    },
    measurements: {
        defaultParams: {
            pagination: {page: 0, size: 100},
            filters: {},
            fulltextFilter: undefined,
            sorting: [{field: 'ratingTs', direction: 'ASC'}],
        },
    },
    invoices: {
        defaultParams: {
            pagination: {page: 0, size: 20},
            fulltextFilter: undefined,
            filters: {areInvoicingDocumentsCreated: {type: 'boolean', value: false}},
            sorting: [{field: 'lineRegistry', direction: 'ASC'}],
        },
    },
    sentEmails: {
        defaultParams: {
            pagination: {page: 0, size: 20},
            filters: {},
            sorting: [{field: 'created', direction: 'DESC'}],
        },

    },
};

export const findTableCodeById = (tableId: TableId): string | undefined =>
    tableConfigs[tableId]?.serverUserSettingsIds?.code;

export function getTableConfig<TID extends TableId>(tableId: TID): TableConfig<TableRowMap[TID]> | undefined {
    return tableConfigs[tableId];
}

export const hasServerUserSettings = (tableId: TableId): boolean =>
    Boolean(tableConfigs[tableId]?.serverUserSettingsIds);
