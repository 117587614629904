import {Nullable} from '@fl/cmsch-fe-library';

export const searchMeasurementsFormName = 'searchMeasurements';

export interface SearchMeasurementsFormValues {
    earTag: Nullable<string>;
}

export const searchMeasurementsInitialValues: SearchMeasurementsFormValues = {
    earTag: null,
};
