import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {measurementAction, MortifyMeasurementAction} from '../action';

function* execute({payload: {ratingId}}: MortifyMeasurementAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('mortifyMeasurement', true));

    const response = yield* call(Api.mortifyAnimalsRating, {ratingId});

    if (response.isSuccess) {
        yield* put(measurementAction.getMeasurements(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, t('measurement/messages')('mortifyAnimalTitle')));
    }

    yield* put(layoutAction.setItemLoading('mortifyMeasurement', false));
}

export function* mortifyMeasurementSaga(): SagaIterator {
    yield takeLatestF('measurement/MORTIFY_MEASUREMENT', execute);
}
